import { EventFactory, ISplititError, ProcessorName, ThreeDsTwoTokenizer, env } from 'splitit-utils';
import { ApiErrorWrapper } from './api-error-wrapper';
import { FLEX_FIELDS_ENV } from './constants';
import { FORTER_COOKIE_NAME } from './constants-ts';
import { FieldValidationManager } from './fields/field-validation-manager';
import { FrameManagerRoles, MessageTypes, frameManager } from './frame-manager';
import {
	CollectCardDataPayload,
	CollectorCommitCompletePayload,
	CollectorIntroducePayload
} from './frame-manager-payloads';
import { localizer } from './localization-provider';
import { logger } from './logger';
import { IErrorModel, IFieldDescriptorInfo } from './models/common-contracts';
import { FieldType, PartialRecord } from './models/types';
import { splititApiService } from './services/splitit-api';
import { ISplititApiCreateInstallmentPlanRequest } from './services/splitit-api-models';
import { state } from './state';
import { splititTrackerDecorator } from './tracking/splitit-tracker-decorator';
import { utils } from './utils';

/**
 * Collector class
 * This class seems to be not in use and should be erased
 */
export class Collector {
	private _collectedValues: PartialRecord<FieldType, string>;
	private _forterToken: any;
	private _fieldValidationManagers: PartialRecord<FieldType, FieldValidationManager>;
	private _commitDictionary: any;
	private _3ds2Tokenizer: ThreeDsTwoTokenizer;

	constructor(guid: string, sessionId: string) {
		frameManager.init(FrameManagerRoles.COLLECTOR, guid);
		localizer.subscribeLocalizationLoaded();
		state.reset();

		splititTrackerDecorator.init({
			merchantUrl: '',
			sessionId: sessionId,
			calculateVisitorId: false
		});

		frameManager.notify<CollectorIntroducePayload>(MessageTypes.COLLECTOR_INIT, {});

		this._collectedValues = {};
		this._forterToken = null;
		this._commitDictionary = {};
		this._fieldValidationManagers = {};

		state.onChange((old) => {
			if (!this._3ds2Tokenizer && state.get().is3ds2Supported) {
				this._3ds2Tokenizer = new ThreeDsTwoTokenizer({
					Env: <env>((FLEX_FIELDS_ENV as string) === 'local' ? 'beta' : FLEX_FIELDS_ENV),
					Ipn: state.get().ipn,
					PublicToken: state.get().publicToken,
					ProcessorName: <ProcessorName>state.get().processorName
				});
			}
		});

		frameManager.subscribe(MessageTypes.COLLECT_CARD_DATA, this, (data: CollectCardDataPayload) => {
			if (data.commitId) {
				if (!this._commitDictionary[data.commitId]) {
					this._commitDictionary[data.commitId] = [];
				}

				this._commitDictionary[data.commitId].push(data);
			}

			this._collectedValues[data.fieldType] = data.value;

			const field = state.get().fields.filter((f) => f.type == data.fieldType)[0];
			if (!field) {
				return;
			}

			if (data.fieldType == 'number' && data.forter) {
				this._forterToken = data.forter;
			}

			const validationManager = new FieldValidationManager(
				field,
				data,
				Object.assign({}, this._collectedValues),
				this._3ds2Tokenizer
			);
			this._fieldValidationManagers[data.fieldType] = validationManager;

			validationManager.validate((validationResult: IFieldDescriptorInfo, serverErrors: Array<IErrorModel>) => {
				if (this._fieldValidationManagers[data.fieldType] === validationManager) {
					// Nothing changed in the meantime, ok to update state. If anything was changed in the meantime,
					// it will get picked up in next event loop. This can happen if sever side validation is triggered, but in the meantime
					// payment button is clicked for instance.
					state.updateField(validationResult);
					if (serverErrors) {
						state.setErrors(serverErrors);
					}

					let fieldErrors = new Array<ISplititError>();
					if (serverErrors) {
						fieldErrors = serverErrors.map((s) => <ISplititError>{ code: s.code, message: s.description });
					}
					if (validationResult.errors) {
						fieldErrors.push(...validationResult.errors.map((s) => <ISplititError>{ message: s }));
					}

					if (fieldErrors.length == 0 || validationResult.showValidationError) {
						splititTrackerDecorator.sendEvent(
							EventFactory.InputValidated(data.fieldType, fieldErrors.length == 0, fieldErrors)
						);
					}

					if (data.commitId && this._commitDictionary[data.commitId].length == state.get().fields.length) {
						// Everything is collected, process it...
						this.processPayment();
						delete this._commitDictionary[data.commitId];
						this._forterToken = null;
					}
				} else {
					// console.log(`Skipping field update for ${data.fieldType}`);
				}
			});
		});
	}

	private processPayment() {
		try {
			const allValid = state.get().fields.every((f) => f.isValid !== false);
			if (allValid) {
				this.commitData();
			} else {
				const fieldsToTriggerValidation = state.get().fields.filter((f) => f.isValid === false);
				fieldsToTriggerValidation.forEach((f) => {
					f.showValidationError = true;
					state.updateField(f);
				});
			}
		} catch (e) {
			logger.logException(e);
		}
	}

	private validateRequiredPlanData() {
		if (!state.get().ipn) {
			logger.logCustomError('Splitit plugin not configured correctly: installment plan number is never set.');
			return false;
		}

		if (!state.get().publicToken) {
			logger.logCustomError('Splitit plugin not configured correctly: public token is never set.');
			return false;
		}

		return true;
	}

	private commitData(maxAttempts = 3) {
		if (!this.validateRequiredPlanData() || state.get().isCommiting) {
			return;
		}

		state.setIsCommiting(true);
		state.setErrors([]);

		const cardData = utils.parseCardData(this._collectedValues);

		const requestData: ISplititApiCreateInstallmentPlanRequest = {
			CreditCardDetails: cardData,
			InstallmentPlanNumber: state.get().ipn,
			PlanData: {
				NumberOfInstallments: state.get().numInstallments
			}
		};

		if (this._forterToken && this._forterToken != '') {
			requestData.PlanData.ExtendedParams = {};
			requestData.PlanData.ExtendedParams[FORTER_COOKIE_NAME] = this._forterToken;
		}

		const tcApproved = state.get().termsAccepted;
		if (tcApproved === true || tcApproved === false) {
			requestData.PlanApprovalEvidence = {
				AreTermsAndConditionsApproved: tcApproved
			};
		}

		splititApiService
			.createPlan(requestData)
			.then((result) => {
				if (result?.InstallmentPlan?.Strategy) {
					state.setStrategy(result.InstallmentPlan.Strategy);
				}

				frameManager.notify<CollectorCommitCompletePayload>(MessageTypes.COLLECTOR_COMMIT_COMPLETE, {
					is3ds: false
				});

				state.setIsCommiting(false);
			})
			.catch((err) => {
				const error = ApiErrorWrapper.parse(err, 'payment_error').updateState();

				if (error.require3D) {
					this.perform3ds();
				} else if (error.possibleConcurrencyError && maxAttempts > 0) {
					state.setIsCommiting(false);
					this.commitData(maxAttempts - 1);
				} else {
					if (error.possibleConcurrencyError) {
						state.setErrors(error.errorList);
					}
					state.setIsCommiting(false);
					logger.logWarning(err);
				}
			});
	}

	private perform3ds() {
		frameManager.notify<CollectorCommitCompletePayload>(MessageTypes.COLLECTOR_COMMIT_COMPLETE, {
			is3ds: true
		});
		state.setIsCommiting(false);
	}
}
