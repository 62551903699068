import { InstallmentPickerRenderer } from "./base";
import { localizer } from "../../localization-provider";
import { template } from "./buttons-template";

export class InstallmentPickerButtonRenderer extends InstallmentPickerRenderer {
  public render(container: HTMLElement) {
    container.classList.add("splitit-installment-picker-buttons");

    const buttons = this.options.map((o) => {
      const widthPct = (100 / this.options.length).toFixed(2);

      return {
        numPayments: localizer.numPayments(o),
        amountPerMonth: this.getPaymentCalculationText(o),
        width: widthPct,
        isFirst: false,
        isLast: false,
      };
    });

    buttons[0].isFirst = true;
    buttons[buttons.length - 1].isLast = true;

    container.innerHTML = template({ buttons });

    const buttonElements = container.querySelectorAll("button");
    buttonElements.forEach((b, idx) =>
      b.addEventListener("click", (ev) => {
        this.setSelectedValue(this.options[idx]);
        buttonElements.forEach((b2) => b2.classList.remove("selected"));
        b.classList.add("selected");
      })
    );

    if (this.initialSelection) {
      const idx = this.options.findIndex((val) => val == this.initialSelection);
      if (idx > -1) {
        buttonElements[idx].click();
      }
    }

    setTimeout((_) => {
      const buttons = document.querySelectorAll<HTMLButtonElement>(
        ".splitit-ip-b button"
      );
      let maxHeight = 0;
      buttons.forEach((b) => {
        maxHeight = Math.max(b.offsetHeight, maxHeight);
      });

      if (maxHeight > 0) {
        buttons.forEach((b) => {
          if (b.offsetHeight < maxHeight) {
            b.style.setProperty("height", `${maxHeight}px`);
          }
        });
      }
    }, 100);
  }

  protected getPickerType(): string {
    return "buttons";
  }
}
