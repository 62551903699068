import { config, cultureDateFormatMap } from "./config";
import moment from "moment-mini";

import { FieldType, PartialRecord } from "./models/types";
import { ICardDataApiModel } from "./services/splitit-api-models";
import { logger } from "./logger";
import { splititTrackerDecorator } from "./tracking/splitit-tracker-decorator";
import { EventFactory } from "splitit-utils";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const urlParams = require("url-params");

class Utils {
  private _interactionElements: Array<HTMLElement>;
  private _hadFirstInteraction: boolean;
  public pickerErrorCodes: Array<string> = ["5041", "5801", "5421"];

  constructor() {
    this._interactionElements = new Array<HTMLElement>();
    this._hadFirstInteraction = false;
  }

  formatString(str: string, args: any): string {
    for (const key in args) {
      str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
    }

    return str;
  }

  public recordFirstInteraction(el: HTMLElement) {
    if (!this._hadFirstInteraction) {
      this._interactionElements.push(el);
      el.addEventListener("mousedown", this.mouseEvent);
      el.addEventListener("touchstart", this.mouseEvent);
      el.addEventListener("mouseenter", this.mouseEvent);
    }
  }

  private mouseEvent = () => {
    this._hadFirstInteraction = true;
    splititTrackerDecorator.sendEvent(EventFactory.FirstUserInteraction());

    this._interactionElements.forEach((el) => {
      try {
        el.removeEventListener("mousedown", this.mouseEvent);
        el.removeEventListener("touchstart", this.mouseEvent);
        el.removeEventListener("mouseenter", this.mouseEvent);
      } catch (e) {
        console.warn(e);
      }
    });
  };

  formatDate(date: string | Date): string {
    return moment(date).format(config.dateFormat);
  }

  public verifySupportedCulture(opts: { culture: string }) {
    if (
      opts.culture &&
      cultureDateFormatMap &&
      !cultureDateFormatMap[opts.culture]
    ) {
      logger.logCustomError("Unsupported FF culture.", {
        requestedCulture: opts.culture,
      });
    }
  }

  public formatCurrency(
    amount: number,
    currencySymbol: string,
    currencyDecimalPlaces: number
  ) {
    return `${currencySymbol}${utils.formatDecimalToLocale(
      amount,
      currencyDecimalPlaces
    )}`;
  }

  public formatDecimalToLocale(amount: number, decimalPlaces: number) {
    if (amount % 1 == 0) {
      decimalPlaces = 0;
    }

    return `${amount.toLocaleString(config.culture, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    })}`;
  }

  public guid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  public appendUrlParams(url: string, queryString: any): string {
    Object.keys(queryString).forEach((key) => {
      url = urlParams.set(url, key, queryString[key]);
    });

    return url;
  }

  /**
   * Returns true if all items in arr1 are in arr2 and both arrays are ordered the same
   * @param arr1
   * @param arr2
   * @returns true if all items in arr1 are in arr2 and both arrays are ordered the same
   */
  public areArraysEqual<T>(arr1: T[], arr2: T[]) {
    if (!arr1 && !arr2) {
      return true;
    }

    if (!arr1 || !arr2) {
      return false;
    }

    if (arr1.length != arr2.length) {
      return false;
    }

    const result = true;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] != arr2[i]) {
        return false;
      }
    }

    return result;
  }

  public parseCardData(
    data: PartialRecord<FieldType, string>
  ): ICardDataApiModel {
    let expMonth = data["expiration-month"];
    let expYear = data["expiration-year"];

    if (!expMonth) {
      const expDate = data["expiration-date"];
      if (expDate && expDate.indexOf("/") > 0) {
        expMonth = data["expiration-date"].split("/")[0];
        expYear = data["expiration-date"].split("/")[1];
      }
    }

    return {
      CardNumber: data.number,
      CardHolderFullName: data["cardholder-name"],
      CardExpMonth: expMonth,
      CardExpYear: expYear,
      CardCvv: data.cvv,
    } as ICardDataApiModel;
  }

  public isShitholeBrowser() {
    const ua = window.navigator.userAgent;
    return /MSIE|Trident/.test(ua);
  }

  public getCookie(name: string) {
    const cookieKey = `${name}=`;
    const baseCookies = decodeURIComponent(document.cookie);
    const cookieArray = baseCookies.split(";");

    for (let i = 0; i < cookieArray.length; i += 1) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieKey) > -1) {
        return cookie.substring(cookieKey.length, cookie.length);
      }
    }
    return "-1";
  }
}

const utils = new Utils();
export { utils };
