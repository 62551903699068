import { state } from "../state";
import { ISplititApiInitiatePlanRequest } from "./splitit-api-models";
import { splititApiService } from "./splitit-api";
import { logger } from "../logger";
import { ApiErrorWrapper } from "../api-error-wrapper";
import { IUpdateDetailsData } from "../models/common-contracts";

class ShopperDetailsStore {
  private _data?: IUpdateDetailsData;
  private _onSuccess: () => void;

  public init() {
    this._data = null;

    state.onChange((old) => {
      if (old.ipn != state.get().ipn) {
        this.save();
      }
    });
  }

  public update(data: IUpdateDetailsData, onSuccessCallback?: () => void) {
    if (!this._data) {
      this._data = {};
    }

    this._onSuccess = onSuccessCallback;

    Object.assign(this._data, data);

    const ipn = state.get().ipn;
    if (ipn) {
      this.save();
    }
  }

  private save() {
    if (this._data && state.get().ipn) {
      const requestData: ISplititApiInitiatePlanRequest = {
        InstallmentPlanNumber: state.get().ipn,
      };

      if (this._data.consumerData) {
        requestData.ConsumerData = this._data.consumerData;
      }

      if (this._data.billingAddress) {
        requestData.BillingAddress = this._data.billingAddress;
      }

      if (this._data.refOrderNumber) {
        requestData.PlanData = {
          RefOrderNumber: this._data.refOrderNumber,
        };
      }

      splititApiService
        .initiatePlan(requestData)
        .then((serverResult) => {
          this._data = null;
          if (serverResult.InstallmentPlan.Strategy) {
            state.setStrategy(serverResult.InstallmentPlan.Strategy);
          }

          if (this._onSuccess) {
            this._onSuccess();
          }
        })
        .catch((err) => {
          ApiErrorWrapper.parse(err, "update_details").updateState();
          logger.logException(err, false);
        });
    }
  }
}

const shopperDetailsStore = new ShopperDetailsStore();
export { shopperDetailsStore };
