import axios, { AxiosRequestConfig } from "axios";
import { SplititClientInfo } from "splitit-utils";
import { state } from "../state";
import { API_URL, MAJOR_VERSION, TRANSLATION_URL } from "../constants";
import {
  IGetResourcesRequest,
  ISplititApiCreateInstallmentPlanRequest,
  ISplititApiCreatePlanResponse,
  ISplititApiGetInitiatedPlanResponse,
  ISplititApiGetSchedulesResponse,
  ISplititApiHasResponseHeader,
  ISplititApiInitiatePlanRequest,
  ISplititApiInitiatePlanResponse,
  ISplititApiResourcesResponse,
} from "./splitit-api-models";

export class SplititApiService {
  private _axiosConfig: AxiosRequestConfig;
  private _translationsAxiosConfig: AxiosRequestConfig;

  constructor() {
    const clientHeaders = new SplititClientInfo().header;

    this._axiosConfig = <AxiosRequestConfig>{
      headers: {
        "Content-Type": "application/json",
        "touch-point": "FlexFields",
        "touch-point-version": MAJOR_VERSION,
        ...clientHeaders,
      },
    };

    this._translationsAxiosConfig = <AxiosRequestConfig>{
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  private adjustRequestConfig(): void {
    this._axiosConfig.baseURL = API_URL;
    this._translationsAxiosConfig.baseURL = TRANSLATION_URL;

    if (state.get().publicToken) {
      this._axiosConfig.headers["Authorization"] = `Bearer ${
        state.get().publicToken
      }`;
    } else if (this._axiosConfig.headers["Authorization"]) {
      delete this._axiosConfig.headers["Authorization"];
    }

    if (state.get().culture) {
      this._axiosConfig.headers["Accept-Language"] = state.get().culture;
    }
  }

  public getInitiatedPlan(): Promise<ISplititApiGetInitiatedPlanResponse> {
    return this.performPost(
      "/api/InstallmentPlan/GetInitiatedInstallmentPlanRequest"
    );
  }

  public getSchedules(): Promise<ISplititApiGetSchedulesResponse> {
    return this.performPost("/api/InstallmentPlan/GetSchedules");
  }

  public createPlan(
    request: ISplititApiCreateInstallmentPlanRequest
  ): Promise<ISplititApiCreatePlanResponse> {
    return this.performPost("/api/InstallmentPlan/Create", request);
  }

  public initiatePlan(
    request: ISplititApiInitiatePlanRequest
  ): Promise<ISplititApiInitiatePlanResponse> {
    return this.performPost("/api/InstallmentPlan/Initiate", request);
  }

  public async getResources(
    requestData: IGetResourcesRequest
  ): Promise<ISplititApiResourcesResponse> {
    this.adjustRequestConfig();

    const categoriesQs = requestData.SystemTextCategories.join(",");
    try {
      const axiosResponse = await axios.get(
        `/api/v1/translations/${requestData.CultureName}/multiple?categories=${categoriesQs}`,
        this._translationsAxiosConfig
      );
      if (axiosResponse.status >= 400) {
        console.error(`Error getting translations: ${axiosResponse.status}, `);
        throw { response: axiosResponse, url: axiosResponse.config.url };
      }

      const response = axiosResponse.data as ISplititApiResourcesResponse;

      return response;
    } catch (error) {
      console.log("Error getting translations", error?.config?.url);
      throw error;
    }
  }

  private async performPost<
    TRequest,
    TResponse extends ISplititApiHasResponseHeader
  >(url: string, request?: TRequest): Promise<TResponse> {
    this.adjustRequestConfig();

    const axiosResponse = await axios.post(
      url,
      request ?? {},
      this._axiosConfig
    );
    const response = axiosResponse.data as TResponse;
    if (!response?.ResponseHeader.Succeeded) {
      throw response;
    }

    return response;
  }
}

const splititApiService = new SplititApiService();
export { splititApiService };
