import { ApiErrorWrapper } from '../api-error-wrapper';
import { logger } from '../logger';
import { state } from '../state';
import { splititApiService } from './splitit-api';
import { ISplititApiGetInitiatedPlanResponse, ISplititApiGetSchedulesResponse } from './splitit-api-models';
export type InstallmentsUiType = 'dropdown' | 'radio' | 'slider' | 'buttons' | 'radio' | 'signle' | 'pie';
export interface IPlanData {
	options: number[];
	ui: InstallmentsUiType;
	amount: number;
	currencyCode: string;
	strategy: string;
	ipn: string;
	privacyPolicyUrl: string;
	learnMoreUrl: string;
	termsAndConditionsUrl: string;
	firstChargeDate?: Date;
	firstInstallmentAmount?: number;
	selectedNumInstallments?: number;
	currencyDecimalPlaces?: number;
	is3ds2Supported: boolean;
	processorName?: string;
	refOrderNumber?: string;
	errorRedirectUrl?: string;
	successRedirectUrl?: string;
	isBiWeekly?: boolean;
}

class PlanStore {
	private _planData?: IPlanData;
	private _listeners: Array<(data: IPlanData) => void>;

	private _scheduleData?: ISplititApiGetSchedulesResponse;
	private _scheduleListeners: Array<(data: ISplititApiGetSchedulesResponse) => void> = [];

	public init() {
		this._listeners = [];
		this._planData = null;

		this._scheduleListeners = [];
		this._scheduleData = null;
	}

	public onLoaded(callback: (data: IPlanData) => void): void {
		this._listeners.push(callback);

		if (this._planData != null) {
			callback(this._planData);
		}
	}

	public getIsBiweekly = () => this._planData?.isBiWeekly;

	public onScheduleLoaded(callback: (data: ISplititApiGetSchedulesResponse) => void) {
		this._scheduleListeners.push(callback);

		if (this._scheduleData != null) {
			callback(this._scheduleData);
		}
	}
	public getLearnMoreLink = () => this._planData?.learnMoreUrl;
	public load() {
		Promise.all([splititApiService.getInitiatedPlan(), splititApiService.getSchedules()])
			.then((results) => {
				const installmentPlanResult = results[0] as ISplititApiGetInitiatedPlanResponse;
				const schedulesResult = results[1] as ISplititApiGetSchedulesResponse;
				const middles = schedulesResult.Schedules[Math.round((schedulesResult.Schedules.length - 1) / 2)];

				const defaultInstalmentOption =
					installmentPlanResult.PlanData.NumberOfInstallments == 0
						? schedulesResult.Schedules[0].NumberOfInstallments
						: installmentPlanResult.PlanData.NumberOfInstallments;
				this._planData = {
					options: installmentPlanResult.PaymentWizardDataResponse.RequestedNumberOfInstallments.split(',').map(
						(p) => +p
					),
					ui: installmentPlanResult.PaymentWizardDataResponse.NumberOfInstallmentsSelectionsOption,
					is3ds2Supported: installmentPlanResult.PaymentWizardDataResponse.Is3ds2Supported,
					processorName: installmentPlanResult.PaymentWizardDataResponse.ProcessorName,
					privacyPolicyUrl: installmentPlanResult.PaymentWizardDataResponse.PrivacyPolicyUrl,
					termsAndConditionsUrl: installmentPlanResult.PaymentWizardDataResponse.TermsAndConditionsUrl,
					successRedirectUrl: installmentPlanResult.PaymentWizardDataResponse.SuccessExitURL,
					learnMoreUrl: installmentPlanResult.PaymentWizardDataResponse.LearnMoreUrl,
					errorRedirectUrl: installmentPlanResult.PaymentWizardDataResponse.CancelExitURL,
					currencyDecimalPlaces: installmentPlanResult.PaymentWizardDataResponse.CurrencyDisplay?.DecimalPlaces,

					amount: installmentPlanResult.PlanData.Amount.Value,
					currencyCode: installmentPlanResult.PlanData.Amount.CurrencyCode,
					strategy: installmentPlanResult.PlanData.Strategy,
					firstChargeDate: installmentPlanResult.PlanData.FirstChargeDate,
					firstInstallmentAmount: installmentPlanResult.PlanData.FirstInstallmentAmount
						? installmentPlanResult.PlanData.FirstInstallmentAmount.Value
						: null,
					selectedNumInstallments: defaultInstalmentOption,
					refOrderNumber: installmentPlanResult.PlanData.RefOrderNumber,

					ipn: installmentPlanResult.InstallmentPlanNumber,
					isBiWeekly: !!installmentPlanResult.PaymentWizardDataResponse.InstallmentsScheduleDaysInterval
				};

				this._scheduleData = schedulesResult;

				state.setPartial({
					amount: this._planData.amount,
					is3ds2Supported: this._planData.is3ds2Supported,
					processorName: this._planData.processorName,
					currencyCode: this._planData.currencyCode,
					strategy: this._planData.strategy,
					privacyPolicyUrl: this._planData.privacyPolicyUrl,
					termsConditionsUrl: this._planData.termsAndConditionsUrl,
					ipn: this._planData.ipn,
					firstChargeDate: this._planData.firstChargeDate,
					firstInstallmentAmount: this._planData.firstInstallmentAmount,
					currencyDecimalPlaces: this._planData.currencyDecimalPlaces ?? 2,
					refOrderNumber: this._planData.refOrderNumber,
					errorRedirectUrl: this._planData.errorRedirectUrl,
					successRedirectUrl: this._planData.successRedirectUrl
				});

				this._listeners.forEach((f) => f(this._planData));
				this._scheduleListeners.forEach((f) => f(this._scheduleData));
			})
			.catch((err) => {
				ApiErrorWrapper.parse(err, 'other').updateState();
				logger.logException(err, false);
			});
	}
}

export const planStore = new PlanStore();
