import { EventFactory } from 'splitit-utils';
import { localizer } from '../../localization-provider';
import { planStore } from '../../services/plan-store';
import { state } from '../../state';
import { splititTrackerDecorator } from '../../tracking/splitit-tracker-decorator';

export interface IPaymentCalculation {
	amountNow: number;
	monthly: number;
}

export abstract class InstallmentPickerRenderer {
	constructor(protected amount: number, protected options: number[], protected initialSelection?: number) {}

	public abstract render(container: HTMLElement);

	protected getPaymentCalculation(numInstallments: number) {
		let schedule;
		planStore.onScheduleLoaded((res) => {
			schedule = res.Schedules.find((v) => v.NumberOfInstallments == numInstallments);
		});

		if (schedule) {
			const isDeposit = schedule.Deposit;
			return <IPaymentCalculation>{
				amountNow: isDeposit ? schedule.Elements[0].ChargeAmount : 0,
				monthly: isDeposit ? schedule.Elements[1].ChargeAmount : schedule.Elements[0].ChargeAmount
			};
		}
		const firstAmount = state.get().firstInstallmentAmount ?? 0;
		const monthlyValue =
			firstAmount > 0 && numInstallments > 1
				? (this.amount - firstAmount) / (numInstallments - 1)
				: this.amount / numInstallments;

		return <IPaymentCalculation>{
			amountNow: numInstallments > 1 ? firstAmount : 0,
			monthly: monthlyValue
		};
	}

	protected getPaymentCalculationText(numInstallments: number) {
		const paymentCalc = this.getPaymentCalculation(numInstallments);

		const amountNowText = paymentCalc.amountNow
			? localizer.amountNow(state.getCurrencySymbol(), paymentCalc.amountNow, state.getCurrencyDecimalPlaces())
			: '';
		return (
			amountNowText +
			localizer.amountPerMonth(state.getCurrencySymbol(), paymentCalc.monthly, state.getCurrencyDecimalPlaces())
		);
	}

	protected setSelectedValue(newValue) {
		const oldValue = state.get().numInstallments;
		state.setNumInstallments(newValue);

		if (oldValue != newValue) {
			splititTrackerDecorator.sendEvent(EventFactory.PickerChange(oldValue, newValue, this.getPickerType()));
		}
	}

	protected getPickerType(): string {
		return 'unknown';
	}
}
