import { FlexField } from "./field-base";
import Cleave from 'cleave.js';
import { localizer } from "../localization-provider";

export class CvvField extends FlexField {
    private _cleaveWrapper: Cleave;

    public constructor(guid, uiMode){
        super(guid, uiMode);
       
        this._type = 'cvv';
    }

    public updateChange(newValue: string){
        // Override default change, listen to cleave's
    }

    public updateKeypress(newValue: string){
        // Override default change, listen to cleave's
    }

    protected validate(){
        if (!this._value || this._value.length < 3 || this._value.length > 4){
            this._isValid = false;
            this._clientError = localizer.invalidcvv;
        }
    }

    protected injectValue(rawValue: string){
        this._cleaveWrapper.setRawValue(rawValue);
    }

    public init(fieldSelector: string){
        super.init(fieldSelector);

        this._cleaveWrapper = new Cleave(this._inputElement, {
            numeral: true,
            stripLeadingZeroes: false,
            numeralIntegerScale: 4,
            numeralDecimalMark: '',
            numeralDecimalScale: 0,
            numericOnly: true,
            numeralThousandsGroupStyle: 'none',
            onValueChanged: (ev) => {
                if (this._value != ev.target.rawValue){
                    this._value = ev.target.rawValue;
                    if (this._value && this._value.length > 0){
                        this._everNonEmpty = true;
                    }
                    this._isValid = null;
                    this._clientError = null;
                    this.sendToCollector();
                }
            }
        });
    }
}