import { IErrorModel, IFieldDescriptorInfo } from "./models/common-contracts";
import { FieldType, PartialRecord } from "./models/types";

export const ErrorCodes = {
    TERMS_NOT_SELECTED: '01T',
    INSTALLMENTS_NOT_SELECTED: '01I'
};

export class ErrorMapper {
    private static _fieldTypeErrorCodeMap: PartialRecord<FieldType, Array<string>> = ErrorMapper.initFieldTypeErrorCodeMap();
    private static _errorCodeFieldTypeMap: PartialRecord<string, Array<FieldType>> = ErrorMapper.initErrorCodeFieldTypeMap();

    public static combine(errors: Array<IErrorModel>, fields: Array<IFieldDescriptorInfo>) : Array<IErrorModel> {
        let result = new Array<IErrorModel>();

        errors.forEach(e => {
            if (e.code && ErrorMapper._errorCodeFieldTypeMap[e.code]){
                e.fieldTypes = ErrorMapper._errorCodeFieldTypeMap[e.code];
            }

            result.push(e);
        });

        fields.forEach(f => {
            if (f.errors && f.errors.length > 0){
                f.errors.forEach(fe => {
                    result.push({
                        code: 'C-F',
                        source: 'client_validation',
                        description: fe,
                        showError: f.showValidationError,
                        fieldTypes: [<FieldType>f.type]
                    });
                });
            }
        });

        return result;
    }

    public static getErrorCodesForField(fieldType: FieldType) : Array<string> {
        if (!ErrorMapper._fieldTypeErrorCodeMap[fieldType])
            ErrorMapper._fieldTypeErrorCodeMap[fieldType]  = [];

        return ErrorMapper._fieldTypeErrorCodeMap[fieldType];
    }

    private static initErrorCodeFieldTypeMap() : PartialRecord<string, Array<FieldType>> {
        var errorCodeMap = ErrorMapper.initFieldTypeErrorCodeMap();
        var result : PartialRecord<string, Array<FieldType>> = {};
        
        var fieldTypes : Array<FieldType> = ['number', 'cvv', 'cardholder-name', 'expiration-date', 'expiration-month', 'expiration-year'];

        fieldTypes.forEach(ft => {
            errorCodeMap[ft].forEach(err => {
                if (!result[err])
                    result[err] = [];
    
                result[err].push(ft);
            });
        })
        
        return result;
    }

    private static initFieldTypeErrorCodeMap() : PartialRecord<FieldType, Array<string>> {
        let obj: PartialRecord<FieldType, Array<string>> = {
            number: [
                '541', '521', '542', '504', '5041', '5042', 
                '528', '564', '568', '580', '5801', '5802', 
                '594', '607', '609', '610'],
            cvv : ['520', '603'],
            "cardholder-name": ['510'],
            "expiration-date": ['522', '540', '569', '570', '581', '604'],
            "expiration-year": ['522', '540', '570', '604', '581'],
            "expiration-month": ['522', '540', '569', '604', '581']
        };

        return obj;
    }
    
}
