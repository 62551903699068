export function Component(props: { tagName: string }) {
	return function (target: CustomElementConstructor) {
		try {
			const { tagName } = props;
			customElements.define(tagName, target);
		} catch (error) {
			console.log('Component is already defined');
		}
	};
}
