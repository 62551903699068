import { FlexField } from "./field-base";
import { CardholderNameField } from "./cardholder";
import { CardNumberField } from "./cardnumber";
import { ExpirationDateField } from "./expiration-date";
import { CvvField } from "./cvv";
import { ExpirationMonthField } from "./expiration-month";
import { ExpirationYearField } from "./expiration-year";
import { FieldType } from "../models/types";
import { splititTrackerDecorator } from "../tracking/splitit-tracker-decorator";

export class FlexFieldFactory {
    public static create(type: FieldType, sessionId: string, guid: string, uiMode?: string) : FlexField {
        splititTrackerDecorator.init({
            merchantUrl: 'field-iframe',
            sessionId: sessionId,
            calculateVisitorId: false
        });

        switch(type){
            case 'cardholder-name':
                return new CardholderNameField(guid, uiMode);
            case 'number':
                return new CardNumberField(guid, uiMode);
            case 'expiration-date':
                return new ExpirationDateField(guid, uiMode);
            case 'expiration-month':
                return new ExpirationMonthField(guid);
            case 'expiration-year':
                return new ExpirationYearField(guid);
            case 'cvv':
                return new CvvField(guid, uiMode);
        }
    }
}