import { ISplititState } from "./state";
import { FieldStyleSection } from "./config";
import { FieldType } from "./models/types";

export class PayloadBase {
    public _originId?: string;
    public _messageType?: string;
}

export class CollectCardDataPayload extends PayloadBase {
    public fieldType: FieldType;
    public value: string;
    public isValid: boolean;
    public showValidationError: boolean;
    public isFocused: boolean;
    public cardType: string;
    public clientError: string;
    public requireServerSideValidation: boolean;
    public commitId?: string;
    public forter?: string;
    public isDisabled: boolean;
}

export class FieldInitPayload extends PayloadBase {
    public fieldType: FieldType;
}

export class TriggerCollectPayload extends PayloadBase {
    public collectorId: string;
    public commitId: string;
}

export class LocalizationPayload extends PayloadBase {
    resources: { [key: string]: string; };
}

export class CollectorCommitCompletePayload extends PayloadBase {
    is3ds: boolean;
}

export class ServerSideValidationPayload extends PayloadBase {
    public originalValue: string;
    public isValid: boolean;
    public error: string;
}

export class CollectorIntroducePayload extends PayloadBase {
}

export class OverrideFieldStylePayload extends PayloadBase {
    public style: FieldStyleSection;
}

export class SplititStateSyncPayload extends PayloadBase {
    public state: ISplititState;
    public changedProps?: Array<string>;
}

export class AutofillPayload extends PayloadBase {
    public fieldType: FieldType;
    public value: string;
}