import { parse } from 'error-stack-parser';
import { config } from './config';
import { FLEX_FIELDS_ENV, VERSION } from './constants';

type logLevel = 'info' | 'warn' | 'error' | 'fatal';

class DatadogClient {
	constructor(
		private config: any = {
			forwardConsoleLogs: [],
			forwardErrorsToLogs: false,

			service: 'flexfieldsv2',
			replica: {
				applicationId: '60901116-2354-485a-9ff0-8b655887f7f3',
				clientToken: 'pub05b5b92cb2c0338551bc0f85debdc15c'
			},
			clientToken: 'pub05b5b92cb2c0338551bc0f85debdc15c',
			version: VERSION
		}
	) {
		this.init(this.config);
	}

	private generateId(): string {
		return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (character) {
			const random = (Math.random() * 16) | 0,
				value = character == 'x' ? random : (random & 0x3) | 0x8;
			return value.toString(16);
		});
	}

	init(config: any) {
		return null;
	}

	levelToFunctionDict: {
		[key in logLevel]: (message: string, data?: any) => void;
	};

	send(logMessage: { level: 'info' | 'warn' | 'error' | 'fatal'; message: string; data?: any }) {
		return null;
	}

	private buildErrorMessage(data: any) {
		const err = data?.stack == null ? new Error() : data;

		return {
			values: [
				{
					type: 'Error',
					value: err.message,
					stacktrace: {
						frames: this.parseErr(err)
					},
					mechanism: {
						handled: true,
						type: 'generic'
					}
				}
			]
		};
	}
	parseErr = (err: any) => {
		try {
			return parse(err).map((f) => {
				return {
					filename: f.fileName,
					colno: f.columnNumber,
					function: f.functionName,
					in_app: f.isNative,
					lineno: f.lineNumber
				};
			});
		} catch (error) {
			return [];
		}
	};
	private constructPayload(message: string, level: string, data: any, exception?: any) {
		return {
			message,
			level: level,
			exception,
			platform: 'javascript',
			event_id: this.generateId(),
			timestamp: new Date().getTime() / 1000,
			environment: FLEX_FIELDS_ENV,
			release: `flex-fields-${VERSION}`,
			extra: {
				'merchant-url': logger.hostUrl(),
				__serialized__: data
			}
		};
	}
}

class Logger {
	private _logClient: DatadogClient;

	constructor() {
		this._logClient = new DatadogClient();
	}

	replaceClient(client: DatadogClient) {
		if (process.env.NODE_ENV !== 'test') {
			return;
		}
		this._logClient = client;
	}

	public trace(msg: string) {
		this.logClient.send({
			level: 'info',
			message: msg
		});
	}

	public logWarning(msg: string) {
		if (config.isDebugMode()) {
			console.warn('[splitit] ' + msg);
		}

		this.logClient.send({
			level: 'warn',
			message: msg
		});
	}

	public logException(ex: any, outputToConsole = true) {
		outputToConsole && console.error(ex);

		this.logClient.send({
			level: 'fatal',
			message: ex.message,
			data: ex
		});
	}

	get logClient() {
		return this._logClient;
	}

	logError(err: Error) {
		this.logClient.send({
			level: 'error',
			message: err.message,
			data: err
		});
	}

	public logCustomError(msg: string, data?: any) {
		console.error(msg);
		if (data) {
			console.error(data);
		}

		this.logClient.send({
			level: 'error',
			message: msg,
			data: data
		});
	}

	public hostUrl() {
		const isInIframe = parent !== window;

		if (isInIframe) {
			return document.referrer;
		}

		return document.location.hostname;
	}
}

const logger = new Logger();
export { logger };
