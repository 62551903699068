export const template = (props) => {
	const { addCardholderName, whiteLabel, cardId, cvvId, expId, nameId, installId, termsId, errId, payId } = props;
	const holderId = nameId ? nameId : 'splitit-cardholder-name';
	const cardNumberId = cardId ? cardId : 'splitit-card-number';
	const expirationId = expId ? expId : 'splitit-expiration-date';
	const cvv = cvvId ? cvvId : 'splitit-cvv';
	const installmentsPickerId = installId ? installId : 'splitit-installment-picker';
	const errorBoxId = errId ? errId : 'splitit-error-box';
	const termsConditionsId = termsId ? termsId : 'splitit-terms-conditions';
	const paymentId = payId ? payId : 'splitit-btn-pay';

	return `<div class="splitit-cc-group">
    ${(addCardholderName && ` <div id="${holderId}"></div>`) || ''}
    <div class="splitit-horizontal ${whiteLabel ? 'splitit-flex-field-generic' : ''}">
        <div class="${whiteLabel ? 'splitit-flex-field-generic' : ''}" id="${cardNumberId}"></div>
        <div class="${whiteLabel ? 'splitit-flex-field-generic' : ''}" id="${expirationId}"></div>
        <div class="${whiteLabel ? 'splitit-flex-field-generic' : ''}" id="${cvv}"></div>
    </div>
    <div class="splitit-cc-group-separator"></div>
    <div class="splitit-horizontal-cc-error-box"></div>
</div>

<div id="${installmentsPickerId}"></div>
<div id="${errorBoxId}"></div>
<div id="${termsConditionsId}"></div>
<button id="${paymentId}"></button>`;
};
