import { InstallmentIconElementName } from '../../components/installments-icon';
import { localizer } from '../../localization-provider';
import { planStore } from '../../services/plan-store';

const installmentLineElementClassName = 'splitit-installments-line';
export const installmentLineElementSelector = `.${installmentLineElementClassName}`;

const setAmountPerMonthHtml = (amountString: string) => {
	const [num] = amountString.split('/');
	const duration = planStore.getIsBiweekly() ? localizer.biweekly : localizer.monthly;
	return `<span class="font-bold">${num}</span>&nbsp;/&nbsp;<span class="month">${duration}</span>`;
};

const setNumberOfPaymtsHTML = (numPayments: string) => {
	const [numberOf, payments] = numPayments.split(' ');
	return `<span class="font-bold">${numberOf}</span> <span class="payments">${payments}</span>`;
};

const mapInstallmentElements = (installment: { numberOfPayments: number; amountPerMonth: string }) => {
	return `<a class="${installmentLineElementClassName}" href="javascript:void(0)" data-splitit-number-of-installments="${
		installment.numberOfPayments
	}">
  <div class="row-amount-per-month">${setAmountPerMonthHtml(installment.amountPerMonth)}</div>
  <div class="row-number-of-payments">${setNumberOfPaymtsHTML(
		localizer.numPayments(installment.numberOfPayments)
	)}   </div>
  </a>`;
};

export const template = (props) => {
	const { suggestedInstallments, additionalInstallments, amount, showAdditionalPlans, hidePieGraph } = props;
	return `
    <div class="picker-header">
    <div>
    <${InstallmentIconElementName}></${InstallmentIconElementName}>
    </div>
    <div>${localizer['choose-installment-plan']}
    </div>
    </div>

    <div class="installments-container">
    <div class="list-container" tabindex="-1">
    ${suggestedInstallments.map((suggestedInstallment) => mapInstallmentElements(suggestedInstallment)).join('')}
     ${
				showAdditionalPlans
					? `<div class="additional-plans-header">
      <div>
      <a href="javascript:void(0)">Additional payment plans
      <span class="material-symbols-outlined">
expand_more
</span>
</a>
</div>
      </a></div>
      <div class="additional-plans">
      ${additionalInstallments.map((suggestedInstallment) => mapInstallmentElements(suggestedInstallment)).join('')}
        </div>`
					: ''
			}
    </div>
    <div class="pie-part ${hidePieGraph ? 'no-pie-chart' : ''}">
    <div class="payment-statement">${localizer.youWillPayTotalOf}
    <div  class="statement-amount">${amount}</div>
    </div>
    <div id="wl-learn-more" class="wl-learn-more"></div>
     ${
				(!hidePieGraph &&
					`<div class="pie-container">
         <div class="pie-background"></div>
         <div class="pie-slice hold">
            <div class="pie"></div>
        </div>  
        </div>`) ||
				''
			}
    </div>
    </div>`;
};

export default { template };
