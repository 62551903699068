import { state } from "../state";
import { FLEX_FIELDS_ENV, DATA_TRACKING_ENDPOINT, VERSION } from "../constants";
import {
  EventFactory,
  IAdditionalInfo,
  ISplititSessionInfo,
  ITrackingEvent,
  IUIStateInfo,
  SplititTracker,
} from "splitit-utils";
import { FieldType, PartialRecord } from "../models/types";
import { EmptyEventTracker } from "./empty-event-tracker";
import { config } from "../config";

export interface ITrackerInitPayload {
  merchantUrl: string;
  sessionId: string;
  calculateVisitorId: boolean;
}

export class SplititTrackerDecorator {
  private _firstInteractionRecorded: boolean;
  private _isPlanInitialized: boolean;
  private _tracker: EmptyEventTracker | SplititTracker;
  private _userInteractionEvent: ITrackingEvent;
  private _prevEvent?: ITrackingEvent;

  constructor() {
    this._firstInteractionRecorded = false;
    this._isPlanInitialized = false;
    this._userInteractionEvent = EventFactory.FirstUserInteraction(null);
  }

  init(payload: ITrackerInitPayload) {
    try {
      const args: [
        string,
        string,
        "splitit" | "rivery" | "amazon-gw",
        boolean
      ] = [
        DATA_TRACKING_ENDPOINT,
        payload.sessionId,
        "amazon-gw",
        payload.calculateVisitorId,
      ];
      this._tracker = config.useEventTracker
        ? new SplititTracker(...args)
        : new EmptyEventTracker(...args);

      state.onChange(() => {
        if (!this._isPlanInitialized) {
          if (state.get().ipn && state.get().publicToken) {
            this._isPlanInitialized = true;
            this._tracker.updateSessionInfo(<ISplititSessionInfo>{
              planNumber: state.get().ipn,
              publicToken: state.get().publicToken,
            });

            this.sendEvent(EventFactory.PlanLoaded());
          }
        }
      });

      this._tracker.updateSessionInfo(<ISplititSessionInfo>{
        env: FLEX_FIELDS_ENV,
        version: VERSION,
        product: "FlexFields",
        pageUrl: payload.merchantUrl,
      });
    } catch (ex) {
      console.error(ex);
    }
  }

  sendEvent(evData: ITrackingEvent) {
    if (this.isUserInteractionEvent(evData) && this._firstInteractionRecorded) {
      return;
    }

    evData.state = SplititTrackerDecorator.getUiStateInfo();

    if (
      this.isUserInteractionEvent(evData) &&
      !this._firstInteractionRecorded
    ) {
      this._firstInteractionRecorded = true;
    }

    evData = this.extendAdditionalInfo(evData);
    evData = this.mapTriggerName(evData);

    try {
      if (!this.areEventsEqual(this._prevEvent, evData)) {
        this._prevEvent = evData;
        if (!this._tracker) {
          throw new Error(
            "Tracker is not initialized, you must call init() before sending events"
          );
        }
        this._tracker.recordEvent(evData);
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  private isUserInteractionEvent(evData: ITrackingEvent): boolean {
    return (
      evData.name == this._userInteractionEvent.name &&
      evData.trigger?.name == this._userInteractionEvent.trigger?.name
    );
  }

  private static triggerNameMap: PartialRecord<FieldType, string> = {
    "cardholder-name": "Card holder name",
    cvv: "CVV",
    "expiration-date": "EXP Date",
    "expiration-month": "EXP Date (month)",
    "expiration-year": "EXP Year",
    number: "CC number",
  };

  private mapTriggerName(ev: ITrackingEvent): ITrackingEvent {
    if (!ev.trigger || !ev.trigger.name || ev.trigger.name.length == 0) {
      return ev;
    }

    if (SplititTrackerDecorator.triggerNameMap[ev.trigger.name]) {
      ev.trigger.name = SplititTrackerDecorator.triggerNameMap[ev.trigger.name];
    }

    return ev;
  }

  private extendAdditionalInfo(ev: ITrackingEvent): ITrackingEvent {
    const _state = state.getPublicState();

    const nonValidFields = _state.validationStatus.invalidFields.concat(
      _state.validationStatus.pendingValidation
    );

    const valid_cc =
      nonValidFields.length == 0 ||
      nonValidFields.filter((f) => f.type == "number").length == 0;
    const valid_cvv =
      nonValidFields.length == 0 ||
      nonValidFields.filter((f) => f.type == "cvv").length == 0;
    const valid_exp =
      nonValidFields.length == 0 ||
      nonValidFields.filter(
        (f) =>
          f.type == "expiration-date" ||
          f.type == "expiration-month" ||
          f.type == "expiration-year"
      ).length == 0;

    const additionalInfo = <IAdditionalInfo>{
      rtp:
        _state.validationStatus.isValid && valid_cc && valid_cvv && valid_exp,
      valid_cc,
      valid_cvv,
      valid_exp,
      checkbox: _state.isTermsAccepted,
    };

    ev.additionalInfo = Object.assign(additionalInfo, ev.additionalInfo);
    return ev;
  }

  private areEventsEqual(ev1: ITrackingEvent, ev2: ITrackingEvent): boolean {
    if (!ev1 && !ev2) return true;
    if (!ev1 || !ev2) return false;

    if (ev1.name != ev2.name) return false;
    return JSON.stringify(ev1) == JSON.stringify(ev2);
  }

  private static getUiStateInfo(): IUIStateInfo {
    const _state = state.get();

    return <IUIStateInfo>{
      amount: _state.amount,
      currencyCode: _state.currencyCode,
      selectedNumOfInstallments: _state.numInstallments,
      installmentOptions: _state.installmentOptions,
    };
  }
}

const splititTrackerDecorator = new SplititTrackerDecorator();
export { splititTrackerDecorator };
