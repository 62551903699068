export const template = (props) => {
	const { options } = props;
	return `<div class="splitit-ip-d-mobile
<div class="splitit-ip-d-mobile">
    <select>
        ${options
					.map(
						(option) => `<option 
        value="${option.numPayments}}">
            ${option.numPaymentsStr}, ${option.amountPerMonth}</option>`
					)
					.join('')}
        
    </select>
</div>`;
};
