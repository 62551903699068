import { config } from "../config";
import { IStateActiveErrors, state } from "../state";

export class ErrorBox {
  _container: HTMLElement;

  constructor() {
    if (config.errorBox && config.errorBox.selector) {
      this._container = document.querySelector(
        config.errorBox.selector
      ) as HTMLElement;
      this._container.classList.add("splitit-error-box");
      this._container.style.display = "none";

      state.onChange((old) => {
        const errorState = state.getActiveErrors(old);
        if (
          errorState.isChanged &&
          (errorState.hasGeneralErrors ||
            errorState.errors
              .filter((e) => e.fieldTypes == null || e.fieldTypes.length == 0)
              .some((e) => e.showError))
        ) {
          this._container.style.display = "block";
          this.render(errorState);
        } else if (!errorState.hasGeneralErrors) {
          this._container.style.display = "none";
        }
      });
    }
  }

  public destroy() {
    if (this._container) {
      this._container.innerHTML = "";
    }
  }

  render(currentErrorState: IStateActiveErrors) {
    if (!this._container) {
      return;
    }

    while (this._container.firstChild) {
      this._container.removeChild(this._container.firstChild);
    }

    const ul = document.createElement("ul");
    this._container.appendChild(ul);

    currentErrorState.errors
      .filter((e) => e.fieldTypes == null || e.fieldTypes.length == 0)
      .forEach((e) => {
        const li = document.createElement("li");
        li.innerText = e.description;
        ul.appendChild(li);
      });
  }
}
