import { config } from '../../config';
import { localizer } from '../../localization-provider';
import { state } from '../../state';
import { HowSplititWorksLink } from '../../ui-components/how-splitit-works-link';
import { InstallmentPickerRenderer } from './base';
import { installmentLineElementSelector, template } from './pie-picker.template';
export class PiePickerRenderer extends InstallmentPickerRenderer {
	container: HTMLElement;
	private _howSplititWorksLink: HowSplititWorksLink;
	protected getPaymentCalculationText(numInstallments: number) {
		const paymentCalc = this.getPaymentCalculation(numInstallments);

		const amountNowText = paymentCalc.amountNow
			? localizer.amountNow(state.getCurrencySymbol(), paymentCalc.amountNow, state.getCurrencyDecimalPlaces())
			: '';
		return (
			amountNowText +
			localizer.amountPerMonth(state.getCurrencySymbol(), paymentCalc.monthly, state.getCurrencyDecimalPlaces(), true)
		);
	}

	public render(container: HTMLElement) {
		const middleIndex = Math.ceil((this.options.length - 1) / 2);
		const suggestedInstallments =
			this.options.length <= 4
				? this.options
				: [this.options[0], this.options[middleIndex], this.options[this.options.length - 1]];

		const mappedSuggestedInstallments = suggestedInstallments.map((o) => ({
			numberOfPayments: o,
			amountPerMonth: this.getPaymentCalculationText(o)
		}));

		const additionalInstallments = this.options.filter((o) => !suggestedInstallments.includes(o));
		const mappedAdditionalInstallments = additionalInstallments.map((o) => ({
			numberOfPayments: o,
			amountPerMonth: this.getPaymentCalculationText(o)
		}));
		const props = {
			suggestedInstallments: mappedSuggestedInstallments,
			additionalInstallments: mappedAdditionalInstallments,
			amount: this.amount,
			showAdditionalPlans: config.installmentPicker.showAdditionalPlans,
			hidePieGraph: config.installmentPicker?.hidePieGraph
		};
		const html = template(props);
		container.innerHTML = html;
		this.container = container;
		this.setEvents(container);
		this.container.querySelector<HTMLElement>('.payment-statement .statement-amount')!.innerText =
			localizer.amountTotal(state.getCurrencySymbol(), this.amount, 2);

		this.setSelectedInstallment(this.container.querySelector('.splitit-installments-line'));

		this.setAdditionalInstallmentsEvents();

		this._howSplititWorksLink = new HowSplititWorksLink();

		const learnMore = document.getElementById('wl-learn-more');
		if (learnMore) this._howSplititWorksLink.addTo(learnMore, 0);
	}

	private setEvents(container: HTMLElement) {
		const listContainer = container.querySelector('.list-container');
		if (!listContainer) {
			throw new Error('list container not found, please check template for .list-container');
		}
		listContainer.addEventListener('click', this.installmentElementClick);
		listContainer.childNodes.forEach((installmentElement) => {
			installmentElement.addEventListener('focus', this.installmentElementClick);
		});
		listContainer.querySelector('.additional-plans')?.childNodes.forEach((installmentElement) => {
			installmentElement.addEventListener('focus', this.installmentElementClick);
		});
	}

	setAdditionalInstallmentsEvents() {
		const selector = '.additional-plans-header a';
		const additionalAnchor = this.container.querySelector<HTMLAnchorElement>(selector);
		if (!additionalAnchor) {
			console.warn('additional plans anchor not found, please check selector', selector);
			return;
		}
		additionalAnchor.addEventListener('click', () => {
			const arrow = additionalAnchor.querySelector<HTMLElement>('.material-symbols-outlined');
			const rotate = arrow.style.getPropertyValue('--rotate');
			arrow.style.setProperty('--rotate', rotate !== '180deg' ? '180deg' : '0deg');
			const additionalPlans = this.container.querySelector<HTMLElement>('.additional-plans');
			const maxHeight = additionalPlans.style.getPropertyValue('--max-height');
			additionalPlans.style.setProperty('--max-height', maxHeight !== '518px' ? '518px' : '0px');

			const containerHeight = this.container
				.querySelector<HTMLElement>('.installments-container')
				.style.getPropertyValue('--container-height');

			this.container
				.querySelector<HTMLElement>('.installments-container')!
				.style.setProperty('--container-height', containerHeight !== '905px' ? '905px' : '370px');
		});
	}

	private setSelectedInstallment(targetElement: HTMLElement) {
		if (!targetElement) {
			return;
		}
		this.container.querySelectorAll(installmentLineElementSelector).forEach((installmentElement) => {
			installmentElement.classList.remove('selected');
		});
		targetElement.classList.add('selected');
		const numberOfPayments = targetElement.getAttribute('data-splitit-number-of-installments');
		const pieContainer = this.container.querySelector<HTMLElement>('.pie-container');
		if (pieContainer) {
			if (isNaN(+numberOfPayments)) {
				throw new Error(`number of payments '${numberOfPayments}' is not a number`);
			}
			pieContainer.style.setProperty('--bar-size', `${360 / +numberOfPayments}deg`);
		}

		this.setSelectedValue(numberOfPayments);
	}

	installmentElementClick = (evt) => {
		if (evt.target.classList?.contains('splitit-installments-line')) this.setSelectedInstallment(evt.target);
	};
}
