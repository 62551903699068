import { FLEX_FIELDS_ENV } from './constants';
import { UI_MODE_HORIZONTAL } from './constants-ts';
import { IBillingAddress, IConsumerData } from './models/api-contracts';
import {
	ApiEnvironmentType,
	IFieldConfigSection,
	IFieldStyleSection,
	IFlexFieldsConfigSection,
	IFlexFieldsConfiguration,
	IInstallmentPickerConfigSection,
	IPaymentButtonConfigSection,
	ISelectorOnlyConfigSection
} from './models/config-contracts';
import { IPaymentButton } from './models/core-contracts';
import { InstallmentsUiType } from './services/plan-store';

// GET https://api.production.splitit.com/api/Infrastructure/SupportedCultures, manually refresh if ever added.
export const cultureDateFormatMap = {
	'da-DK': 'DD-MM-YYYY',
	'de-DE': 'DD.MM.YYYY',
	'en-AU': 'D/MM/YYYY',
	'en-GB': 'DD/MM/YYYY',
	'en-IE': 'DD/MM/YYYY',
	'en-US': 'M/D/YYYY',
	'es-ES': 'DD/MM/YYYY',
	'fr-FR': 'DD/MM/YYYY',
	'it-IT': 'DD/MM/YYYY',
	'ja-JP': 'YYYY/MM/DD',
	'nl-NL': 'D-M-YYYY',
	'pt-PT': 'DD/MM/YYYY',
	'pt-BR': 'DD/MM/YYYY',
	'ko-KR': 'YYYY-MM-DD',
	'ru-RU': 'DD.MM.YYYY',
	'sv-SE': 'YYYY-MM-DD',
	'tr-TR': 'D.MM.YYYY',
	'zh-CN': 'YYYY/M/D',
	'zh-HK': 'D/M/YYYY'
};

class FieldConfigSection implements IFieldConfigSection {
	public selector: string;
	public style: FieldStyleSection;

	overrideOptions(opt: any) {
		this.selector = opt.selector;
		this.style = new FieldStyleSection();
		this.style.overrideOptions(opt.style);
	}
}

export class FieldStyleSection implements IFieldStyleSection {
	placeholderTextColor?: string;
	fontFamily?: string;
	fontSize?: string;
	textColor?: string;

	overrideOptions(opt: any) {
		opt = opt || {};

		if (opt.placeholderTextColor && opt.placeholderTextColor != '') {
			this.placeholderTextColor = opt.placeholderTextColor;
		}

		if (opt.fontFamily && opt.fontFamily != '') {
			this.fontFamily = opt.fontFamily;
		}

		if (opt.fontSize && opt.fontSize != '') {
			this.fontSize = opt.fontSize;
		}

		if (opt.textColor && opt.textColor != '') {
			this.textColor = opt.textColor;
		}
	}

	hasOverrides() {
		return (
			(this.placeholderTextColor && this.placeholderTextColor != '') ||
			(this.fontFamily && this.fontFamily != '') ||
			(this.fontSize && this.fontSize != '') ||
			(this.textColor && this.textColor != '')
		);
	}
}

class FlexFieldsConfigSection implements IFlexFieldsConfigSection {
	public number: FieldConfigSection;
	public cvv: FieldConfigSection;
	public expirationDate: FieldConfigSection;
	public expirationMonth: FieldConfigSection;
	public expirationYear: FieldConfigSection;
	public cardholderName: FieldConfigSection;
	public style: FieldStyleSection;

	overrideOptions(opt: any) {
		const fields = ['number', 'cvv', 'expirationDate', 'expirationMonth', 'expirationYear', 'cardholderName'];
		fields.forEach((f) => {
			if (opt[f]) {
				this[f] = new FieldConfigSection();
				this[f].overrideOptions(opt[f]);
			}
		});

		this.style = new FieldStyleSection();
		this.style.overrideOptions(opt.style);
	}
}

class InstallmentPickerConfigSection implements IInstallmentPickerConfigSection {
	public selector: string;
	public ui?: InstallmentsUiType;
	public showPaymentSchedule = true;
	hidePieGraph = true;
	showAdditionalPlans: boolean;

	public overrideOptions(opt: any) {
		if (!opt.selector) {
			throw new Error('Installment picker selector is required, please state installmentPicker.selector');
		}
		this.selector = opt.selector;
		this.ui = opt.whiteLabel && !opt.ui ? 'pie' : opt.ui;

		this.hidePieGraph = opt.hidePieGraph === false ? false : true;
		this.showAdditionalPlans = !!opt.showAdditionalPlans;

		if (opt.showPaymentSchedule !== null && opt.showPaymentSchedule !== undefined) {
			this.showPaymentSchedule = opt.showPaymentSchedule;
		}
	}
}

class TermsConditionsConfigSection implements ISelectorOnlyConfigSection {
	public selector: string;
	public isCustomImplementation: boolean;

	constructor() {
		this.isCustomImplementation = false;
	}

	public overrideOptions(opt: any) {
		this.selector = opt.selector;

		if (opt.isCustomImplementation) {
			this.isCustomImplementation = opt.isCustomImplementation;
		}
	}
}

class PaymentScheduleConfigSection implements ISelectorOnlyConfigSection {
	public selector: string;

	public overrideOptions(opt: { selector: string }) {
		this.selector = opt.selector;
	}
}

class ErrorBoxConfigSection implements ISelectorOnlyConfigSection {
	public selector: string;

	public overrideOptions(opt: { selector: string }) {
		this.selector = opt.selector;
	}
}

class PaymentButtonConfigSection implements IPaymentButtonConfigSection {
	public selector: string;
	public onClick: (btn: IPaymentButton) => void;

	public overrideOptions(opt: IPaymentButtonConfigSection) {
		if (!opt) {
			return;
		}
		if (!opt.selector) {
			throw new Error('Payment button selector is required, please state your paymentButton.selector ');
		}

		this.selector = opt.selector;
		this.onClick = opt.onClick as (btn: IPaymentButton) => void;
	}
}

class SplititFlexFieldsConfig implements IFlexFieldsConfiguration {
	apiEnvironment?: ApiEnvironmentType;
	debug: boolean;
	whiteLabel: boolean;
	currencySymbol: string;
	container: string;
	culture: string;
	publicToken?: string;
	dateFormat: string;
	fields: FlexFieldsConfigSection;
	installmentPicker: InstallmentPickerConfigSection;
	termsConditions: TermsConditionsConfigSection;
	paymentSchedule: PaymentScheduleConfigSection;
	errorBox: ErrorBoxConfigSection;
	paymentButton: PaymentButtonConfigSection;
	consumerData?: IConsumerData;
	billingAddress?: IBillingAddress;
	loader?: string | ((arg: HTMLElement) => void);
	custom3dsLogoUrl?: string;
	private uiMode?: string;
	useEventTracker: any;

	constructor() {
		this.fields = new FlexFieldsConfigSection();
		this.termsConditions = new TermsConditionsConfigSection();
		this.culture = 'en-US';
		this.dateFormat = cultureDateFormatMap['en-US'];
		this.debug = false;
		this.whiteLabel = false;
		this.uiMode = undefined;
	}

	public getUiMode(): string {
		if (this.uiMode !== undefined) {
			return this.uiMode;
		}

		if (!this.container) {
			this.uiMode = null;
		} else {
			const rootContainer = document.querySelector(this.container);
			if (rootContainer && rootContainer.classList.contains('splitit-design-horizontal')) {
				this.uiMode = UI_MODE_HORIZONTAL;
			} else {
				this.uiMode = null;
			}
		}

		return this.uiMode;
	}

	public overrideOptions(opt: any) {
		if (!opt) {
			return;
		}
		this.consumerData = opt.consumerData;
		this.billingAddress = opt.billingAddress;
		this.publicToken = opt.publicToken;
		this.container = opt.container;
		this.loader = opt.loader;
		this.custom3dsLogoUrl = opt.custom3dsLogoUrl;
		if (opt.fields) {
			this.fields.overrideOptions(opt.fields);
		}

		if (opt.termsConditions) {
			this.termsConditions.overrideOptions(opt.termsConditions);
		}

		if (opt.paymentSchedule && !opt.whiteLabel) {
			this.paymentSchedule = new PaymentScheduleConfigSection();
			this.paymentSchedule.overrideOptions(opt.paymentSchedule);
		}

		if (opt.errorBox) {
			this.errorBox = new ErrorBoxConfigSection();
			this.errorBox.overrideOptions(opt.errorBox);
		}

		if (opt.paymentButton) {
			this.paymentButton = new PaymentButtonConfigSection();
			this.paymentButton.overrideOptions(opt.paymentButton);
		}

		if (opt.installmentPicker) {
			this.installmentPicker = new InstallmentPickerConfigSection();
			opt.installmentPicker = {
				...opt.installmentPicker,
				whiteLabel: opt.whiteLabel
			};
			this.installmentPicker.overrideOptions(opt.installmentPicker);
		}

		this.apiEnvironment = FLEX_FIELDS_ENV;

		if (opt.useSandboxApi === true) {
			// BW compatibility
			this.apiEnvironment = 'sandbox';
		}

		if (opt.apiEnvironment !== null && opt.apiEnvironment !== undefined) {
			this.apiEnvironment = opt.apiEnvironment;
		}

		if (opt.debug !== null && opt.debug !== undefined) {
			this.debug = opt.debug;
		}

		if (opt.whiteLabel !== null && opt.whiteLabel !== undefined) {
			this.whiteLabel = opt.whiteLabel;
			if (!this.uiMode) this.uiMode = 'whiteLabel';
		}

		if (opt.currencySymbol) {
			this.currencySymbol = opt.currencySymbol;
		}

		if (opt.culture && cultureDateFormatMap[opt.culture]) {
			this.culture = opt.culture;
			this.dateFormat = cultureDateFormatMap[this.culture];
		}

		if (opt.dateFormat) {
			this.dateFormat = opt.dateFormat;
		}
	}

	public validate() {
		if (!this.container) {
			console.error('Container is obligatory.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		} else if (!document.querySelector(this.container)) {
			console.error('Container selector did not return any valid element.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		}

		if (!this.fields.number) {
			console.error('Credit card number is obligatory flex field.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		} else if (!document.querySelector(this.fields.number.selector)) {
			console.error('Credit card number selector did not return any valid element.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		}

		if (
			this.fields.cardholderName &&
			this.fields.cardholderName.selector &&
			!document.querySelector(this.fields.cardholderName.selector)
		) {
			console.error('Cardholder name selector did not return any valid element.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		}

		if (!this.fields.cvv) {
			console.error('CVV is obligatory flex field.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		} else if (!document.querySelector(this.fields.cvv.selector)) {
			console.error('CVV selector did not return any valid element.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		}

		if (this.fields.expirationDate && (this.fields.expirationMonth || this.fields.expirationYear)) {
			console.error(
				'Cannot use both expiration date and expiration month/year. Either use expiration date, or expirationMonth and expirationYear separately.'
			);
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		}

		if (
			(this.fields.expirationMonth && !this.fields.expirationYear) ||
			(!this.fields.expirationMonth && this.fields.expirationYear)
		) {
			console.error('Please provide both expirationMonth and expirationYear if not using expirationDate.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		}

		if (this.fields.expirationDate && !document.querySelector(this.fields.expirationDate.selector)) {
			console.error('Expiration date selector did not return any valid element.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		} else if (this.fields.expirationMonth && !document.querySelector(this.fields.expirationMonth.selector)) {
			console.error('Expiration month selector did not return any valid element.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		} else if (this.fields.expirationYear && !document.querySelector(this.fields.expirationYear.selector)) {
			console.error('Expiration year selector did not return any valid element.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		}

		if (this.installmentPicker && this.installmentPicker.selector) {
			if (document.querySelector(this.installmentPicker.selector) == null) {
				console.error('Make sure installment picker selector is existing HTML element.');
				alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
				return false;
			}
		}

		if (
			!this.termsConditions.isCustomImplementation &&
			(!this.termsConditions.selector || document.querySelector(this.termsConditions.selector) == null)
		) {
			console.error('It is obligatory to specify valid element selector for terms & conditions link.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		}

		if (this.errorBox && (!this.errorBox.selector || !document.querySelector(this.errorBox.selector))) {
			console.error('Make sure error box selector is existing HTML element.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		}

		if (
			this.paymentButton &&
			(!this.paymentButton.selector || !(document.querySelector(this.paymentButton.selector) as HTMLButtonElement))
		) {
			console.error('Make sure error box selector is existing HTML element, and that it is a <button> element.');
			alert('Error initializing Splitit Flex Fields. Check developer console error for more details.');
			return false;
		}

		return true;
	}

	public isDebugMode() {
		const debugEnvs = ['beta', 'sandbox', 'local', 'automation'];

		return this.debug || debugEnvs.includes(FLEX_FIELDS_ENV);
	}
}

const config = new SplititFlexFieldsConfig();
config.apiEnvironment = FLEX_FIELDS_ENV;
export { config };
