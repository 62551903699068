import { config } from "../config";
import { localizer } from "../localization-provider";
import { IPaymentButton } from "../models/core-contracts";
import { state } from "../state";
import { utils } from "../utils";
import { template } from "./payment-button-content-template";

export class PaymentButton implements IPaymentButton {
  _container: HTMLButtonElement;
  _isLoading: boolean;
  _isDisabled: boolean;
  _callback: () => void;

  constructor() {
    if (config.paymentButton && config.paymentButton.selector) {
      this._container = document.querySelector<HTMLButtonElement>(
        config.paymentButton.selector
      );
      if (!this._container) {
        throw new Error(
          `No element with the selector of '${config.paymentButton.selector}' was found`
        );
      }
      this._container.classList.add("splitit-payment-button");
      if (config.whiteLabel) {
        this._container.classList.add("splitit-flex-field-generic");
      }
      this._container.onclick = () => {
        if (this._callback && !this._isDisabled && !this._isLoading) {
          this._callback();
        }
      };
      utils.recordFirstInteraction(this._container);

      this._isDisabled = true;
      this._isLoading = false;

      state.onChange((old) => {
        const newState = state.get();
        if (
          newState.isCommiting != old.isCommiting ||
          newState.termsAccepted != old.termsAccepted
        ) {
          this._isDisabled = !newState.termsAccepted || newState.isCommiting;
          this._isLoading = newState.isCommiting;

          this.render();
        }
      });

      localizer.register(this.render);
    }
  }

  public destroy() {
    if (this._container) {
      this._container.innerHTML = "";
    }
    localizer.unregister(this.render);
  }

  public onClick(callback: () => void) {
    this._callback = callback;
    this._callback.bind(this);
  }

  public setIsLoading(isLoading: boolean): void {
    if (this._isLoading != isLoading) {
      this._isLoading = isLoading;
      if (!this._isDisabled && isLoading) {
        this._isDisabled = true;
      }
      this.render();
    }
  }

  public disable(): void {
    if (!this._isDisabled) {
      this._isDisabled = true;
      this.render();
    }
  }

  public enable(): void {
    if (!this._isDisabled && !this._isLoading) {
      this._isDisabled = false;
      this.render();
    }
  }

  public render = () => {
    if (!this._container) {
      return;
    }

    this._container.disabled = this._isDisabled;

    if (this._isLoading) {
      this._container.innerHTML = template({
        text: localizer.pay,
      });
    } else {
      this._container.innerHTML = localizer.pay;
    }
  };
}
