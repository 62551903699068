import { config } from '../config';
import { PaymentScheduleRenderer } from '../payment-schedule/ui';
import { renderLoader } from '../render-utils';
import { InstallmentsUiType, planStore } from '../services/plan-store';
import { state } from '../state';
import { HowSplititWorksLink } from '../ui-components/how-splitit-works-link';
import { utils } from '../utils';
import { InstallmentPickerRenderer } from './renderers/base';
import { InstallmentPickerButtonRenderer } from './renderers/buttons';
import { InstallmentPickerDropdownRenderer } from './renderers/dropdown';
import { InstallmentPickerDropdownRendererMobile } from './renderers/dropdown-mobile';
import { PiePickerRenderer } from './renderers/pie-picker';
import { InstallmentPickerSliderRenderer } from './renderers/slider';
import { InstallmentPickerTextRenderer } from './renderers/text';

export class InstallmentPicker {
	private _renderer: InstallmentPickerRenderer;
	private _container: HTMLElement;
	private _paymentSchedule: PaymentScheduleRenderer;
	private _pickerContainer: HTMLDivElement;

	private _scheduleLoaded: boolean;
	private _planLoaded: boolean;

	private ui: InstallmentsUiType;
	private _amount: number;
	private _options: Array<number>;
	private _initialNumInstallments?: number;
	private _howSplititWorksLink: HowSplititWorksLink;

	constructor() {
		this.ui = config?.installmentPicker?.ui;
	}

	public init() {
		if (config.paymentSchedule && config.paymentSchedule.selector) {
			const paymentScheduleContainer = document.querySelector(config.paymentSchedule.selector) as HTMLElement;
			this._paymentSchedule = new PaymentScheduleRenderer();
			this._paymentSchedule.container = paymentScheduleContainer;
		}

		if (config.installmentPicker && config.installmentPicker.selector) {
			this._container = document.querySelector(config.installmentPicker.selector) as HTMLElement;
			this._container.classList.add('splitit-installment-picker');

			this._pickerContainer = document.createElement('div');
			this._container.appendChild(this._pickerContainer);

			renderLoader(this._pickerContainer);

			utils.recordFirstInteraction(this._container);

			if (!this._paymentSchedule) {
				this._paymentSchedule = new PaymentScheduleRenderer();
			}

			if (!config.whiteLabel && !this._paymentSchedule.container) {
				this._paymentSchedule.container = document.createElement('div');
				this._container.appendChild(this._paymentSchedule.container);
			}
		}

		planStore.onLoaded((data) => {
			this._planLoaded = true;
			this.ui = this.ui ?? data?.ui;
			this._amount = data?.amount;
			this._initialNumInstallments = data?.selectedNumInstallments;

			this.render();
		});

		planStore.onScheduleLoaded((schedule) => {
			this._scheduleLoaded = true;
			this._options = schedule.Schedules.map((s) => s.NumberOfInstallments);
			this.render();
		});

		state.onChange((old) => {
			const newState = state.get();
			if (config.installmentPicker && config.installmentPicker.selector) {
				if (!utils.areArraysEqual(newState.errors, old.errors)) {
					const markInvalid = newState.errors.filter((e) => utils.pickerErrorCodes.includes(e.code)).length > 0;
					if (markInvalid) {
						this._container.classList.add('invalid');
					} else {
						this._container.classList.remove('invalid');
					}
				}
			}

			if (newState.demoMode && JSON.stringify(old.demoMode) != JSON.stringify(newState.demoMode)) {
				this.renderDemoMode();
			}
		});

		if (state.get().demoMode != null) {
			this.renderDemoMode();
		}
	}

	public resetForPlanSync() {
		this._planLoaded = false;
		this._scheduleLoaded = false;
		renderLoader(this._pickerContainer);
	}

	public destroy() {
		if (this._paymentSchedule) {
			this._paymentSchedule.destroy();
		}

		if (this._container) {
			this._container.innerHTML = '';
		}
	}

	public renderDemoMode() {
		this._options = state.get().demoMode.installments;
		this.ui = state.get().demoMode.pickerMode;
		this._amount = state.get().demoMode.amount;
		this._scheduleLoaded = true;
		this._planLoaded = true;
		this.render();
	}

	public render() {
		if (!this._container || !this._planLoaded || !this._scheduleLoaded) {
			console.warn(
				'returning from render because of !this._container || !this._planLoaded || !this._scheduleLoaded',
				this._container,
				this._planLoaded,
				this._scheduleLoaded
			);
			return;
		}

		state.setNumInstallments(null, this._options);

		this.clearContainer();
		this.initRenderer();
		this._renderer.render(this._pickerContainer);

		this._howSplititWorksLink = new HowSplititWorksLink('splitit-link-how-works-new');
		this._howSplititWorksLink.addTo(this._container, 0);
	}

	private createRenderer<T extends InstallmentPickerRenderer>(renderer: {
		new (amunt: number, options: number[]): T;
	}): T {
		return new renderer(this._amount, this._options);
	}

	private initRenderer() {
		const typeToRendererDict: ToRendererDict = {
			pie: PiePickerRenderer,
			single: InstallmentPickerTextRenderer,
			buttons: {
				desktop: InstallmentPickerButtonRenderer,
				mobile: InstallmentPickerDropdownRendererMobile
			},
			slider: {
				desktop: InstallmentPickerSliderRenderer,
				mobile: InstallmentPickerDropdownRendererMobile
			},
			dropdown: {
				desktop: InstallmentPickerDropdownRenderer,
				mobile: InstallmentPickerDropdownRendererMobile
			}
		};
		const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		const rendererType = config.whiteLabel
			? !this.ui
				? PiePickerRenderer
				: typeToRendererDict[this.ui.toLowerCase()]
			: typeToRendererDict[this.ui.toLowerCase()] || InstallmentPickerDropdownRenderer;
		if (typeof rendererType === 'function') {
			this._renderer = this.createRenderer(
				rendererType as new (amount: number, options: number[]) => InstallmentPickerRenderer
			);
		} else {
			this._renderer = this.createRenderer(
				rendererType[isMobile ? 'mobile' : 'desktop'] as new (
					amount: number,
					options: number[]
				) => InstallmentPickerRenderer
			);
		}
	}

	private clearContainer() {
		while (this._pickerContainer.firstChild) {
			this._pickerContainer.removeChild(this._pickerContainer.firstChild);
		}

		this._howSplititWorksLink && this._howSplititWorksLink.destroy();

		const classesToRemove = Array.from(this._container.classList).filter((c) => c.startsWith('picker-type'));

		this._container.classList.remove(...classesToRemove);
	}
}

type ToRendererDict = {
	[key: string]:
		| typeof InstallmentPickerRenderer
		| { mobile: typeof InstallmentPickerRenderer; desktop: typeof InstallmentPickerRenderer };
};
