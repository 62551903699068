export const template = (props) => {
  const { text } = props;
  return `<div class="splitit-payment-button-content">
    <div class="splitit-loader-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    ${text}
</div>`;
};
