import { config } from "./config";
import { FLEX_FIELDS_URL } from "./constants";
import { FIELD_TYPES } from "./fields/field-types";
import { subscribe, Subscribers, unsubscribe } from "./pub-sub-manager";

export const MessageTypes = {
  COLLECTOR_INIT: "COLLECTOR_INIT",
  FIELD_INIT: "FIELD_INIT",
  LOCALIZATION_LOADED: "LOCALIZATION_LOADED",
  TRIGGER_COLLECT: "TRIGGER_COLLECT",
  INTRODUCE_COLLECTOR: "INTRODUCE_COLLECTOR",
  COLLECT_CARD_DATA: "COLLECT_CARD_DATA",
  COLLECTOR_COMMIT_COMPLETE: "COLLECTOR_COMMIT_COMPLETE",
  STATE_SYNC: "STATE_SYNC",
  FIELD_STYLE_OVERRIDE: "FIELD_STYLE_OVERRIDE",
  FIELD_AUTOFILL: "FIELD_AUTOFILL",
};

export const FrameManagerRoles = {
  HOST: "host",
  COLLECTOR: "collector",
  FIELD: "field",
};

const frameNamePrefix = "splitit-hf-";
export const FrameNames = {
  FIELDS: [
    `${frameNamePrefix}${FIELD_TYPES.NUMBER}`,
    `${frameNamePrefix}${FIELD_TYPES.CARDHOLDER_NAME}`,
    `${frameNamePrefix}${FIELD_TYPES.CVV}`,
    `${frameNamePrefix}${FIELD_TYPES.EXP_DATE}`,
    `${frameNamePrefix}${FIELD_TYPES.EXP_MONTH}`,
    `${frameNamePrefix}${FIELD_TYPES.EXP_YEAR}`,
  ],
};

class FrameManager {
  private _debug: boolean;
  private _trace: boolean;
  private _role: string;
  private _guid?: string;

  constructor() {
    this._subscribers = {};

    window.addEventListener("message", (event) => {
      try {
        this._trace && console.log("Received message");
        this._trace && console.log(event);

        let dataJson = null;

        if (event.data && event.data.startsWith && event.data.startsWith("{")) {
          dataJson = JSON.parse(event.data);
        } else {
          dataJson = event.data;
        }

        if (!dataJson) {
          this._trace && console.log("Wrong data...");
          return;
        }

        this.processData(dataJson);
      } catch (err) {
        // Do nothing
        this._debug && console.error(err);
      }
    });
  }

  private _subscribers: Subscribers<HTMLElement>;

  public init(role: string, guid?: string) {
    this._debug = config.isDebugMode();
    this._trace = false;

    this._role = role;
    this._guid = guid;
    this._subscribers = {};
  }

  subscribe<TData>(msg: string, owner: any, callback: (data: TData) => void) {
    subscribe(this._subscribers, msg, owner, callback);
  }

  unsubscribe<TData>(msg: string, owner: any, callback: (data: TData) => void) {
    unsubscribe(this._subscribers, msg, owner, callback);
  }

  notify<TData>(msg: string, data?: TData, destinationId?: string) {
    this._notifyInternal(msg, data, "*", destinationId);
  }

  /**
   * Notifies only splitit frames
   * @param {string} msg Message type
   * @param {any} data Object data
   * @param {string} destinationId Destination id (optional)
   */
  notifySecure<TData>(msg: string, data: TData, destinationId?: string) {
    this._notifyInternal(msg, data, FLEX_FIELDS_URL, destinationId);
  }

  _notifyInternal<TData>(
    msg: string,
    data: TData,
    targetOrigin: string,
    destinationId: string
  ) {
    if (this._trace) {
      console.log("Sending message from " + this._role);
      console.log(msg);
      console.log(data);
    }
    const payload = Object.assign(data || {}, {
      _messageType: msg,
      _destination: destinationId,
      _originId: this._guid,
    });

    let hostWindow = null;

    if (
      this._role == FrameManagerRoles.COLLECTOR ||
      this._role == FrameManagerRoles.FIELD
    ) {
      hostWindow = window.parent;
    } else {
      hostWindow = window;
    }

    const destinations = [];
    if (targetOrigin == "*") {
      destinations.push(hostWindow);

      for (let i = 0; i < hostWindow.frames.length; i++) {
        destinations.push(hostWindow.frames[i]);
      }
    } else {
      for (let i = 0; i < FrameNames.FIELDS.length; i++) {
        try {
          const d = hostWindow.frames[FrameNames.FIELDS[i]];
          d && destinations.push(d);
        } catch (_) {
          // do nothing
        }
      }
    }

    for (let i = 0; i < destinations.length; i++) {
      if (destinations[i] == window) {
        try {
          if (this._trace) {
            console.log("Self loop in " + this._role);
            console.log(payload);
          }
          this.processData(payload);
        } catch (err) {
          this._trace && console.error(err);
        }
      } else {
        try {
          destinations[i].postMessage(JSON.stringify(payload), targetOrigin);
        } catch (err) {
          this._trace && console.error(err);
        }
      }
    }
  }

  private processData(data: any): void {
    if (data._messageType && this._subscribers[data._messageType]) {
      let shouldReadMessage = true;

      if (data._destination) {
        shouldReadMessage = this._guid == data._destination;
      }

      if (shouldReadMessage) {
        this._trace &&
          console.log("FrameManager[" + this._role + "] Message received:");

        for (let i = 0; i < this._subscribers[data._messageType].length; i++) {
          const subscriber = this._subscribers[data._messageType][i];
          this._trace && console.log("Invoking subscriber:");
          this._trace && console.log(subscriber.owner);
          subscriber.callback.call(subscriber.owner, data);
        }
      }
    }
  }
}

const frameManager = new FrameManager();
export { frameManager };
