import { VERSION, FLEX_FIELDS_ENV, getStylesUrl } from "./constants";
import { state } from "./state";
import { ApiErrorWrapper } from "./api-error-wrapper";
import {
  appendStyles,
  hide3dModal,
  IModal3dsIframeParams,
  render3dModalIframe,
} from "./render-utils";
import { planStore } from "./services/plan-store";
import { IFlexCheckoutConfig } from "./models/config-contracts";
import { IFlexCheckout } from "./models/core-contracts";
import {
  IErrorCallbackObject,
  IEventCallbackObject,
  IModal3dsIframeResult,
  ISuccessCallbackObject,
} from "./models/callback-contracts";
import { splititApiService } from "./services/splitit-api";
import { ISplititApiCreateInstallmentPlanRequest } from "./services/splitit-api-models";
import { logger } from "./logger";
import { CallbackFactory } from "./services/callback-factory";

export class SplititFlexCheckout implements IFlexCheckout {
  public version: string = VERSION;

  private _onSuccessCallback: (data: ISuccessCallbackObject) => void;
  private _onErrorCallback: (data: IErrorCallbackObject) => void;
  private _on3ds1CompleteCallback: (data: IModal3dsIframeResult) => void;
  private _onEventCallback: (data: IEventCallbackObject) => void;
  private _current3dModal: HTMLElement;
  private _isPlanDataLoaded: boolean;
  private _config: IFlexCheckoutConfig;
  private _sessionId: string;

  constructor(sessionId: string) {
    this._sessionId = sessionId;
  }

  init(config: IFlexCheckoutConfig) {
    this._config = config;

    appendStyles(document.getElementsByTagName("body")[0], getStylesUrl());

    state.reset(false);
    planStore.init();
    this._isPlanDataLoaded = false;

    state.setIsCommiting(true);
    state.setIPN(config.planNumber);
    state.setPublicToken(config.publicToken);
    state.setApiEnvironment(FLEX_FIELDS_ENV);

    planStore.onLoaded((planData) => {
      this._isPlanDataLoaded = true;

      if (config.skipCallingCreate) {
        this.perform3ds();
      } else {
        this.performCreate(config);
      }
    });
    planStore.load();
  }

  private performCreate(config: IFlexCheckoutConfig) {
    const requestData: ISplititApiCreateInstallmentPlanRequest = {
      InstallmentPlanNumber: config.planNumber,
      PlanData: {
        NumberOfInstallments: config.numOfInstallments,
      },
      PlanApprovalEvidence: { AreTermsAndConditionsApproved: true },
    };

    splititApiService
      .createPlan(requestData)
      .then((result) => {
        this._onSuccessCallback(
          CallbackFactory.success(false, result.ResponseHeader.TraceId)
        );
        state.setIsCommiting(false);
      })
      .catch((err) => {
        const error = ApiErrorWrapper.parse(err, "payment_error");

        if (error.require3D) {
          this.perform3ds();
        } else {
          if (this._onErrorCallback) {
            this._onErrorCallback(
              CallbackFactory.error(error.errorList, false)
            );
          }

          state.setIsCommiting(false);
          logger.logWarning(err);
        }
      });
  }

  destroy() {
    this._onSuccessCallback = null;
    this._onErrorCallback = null;
  }

  onSuccess(callback: (data: ISuccessCallbackObject) => void): IFlexCheckout {
    this._onSuccessCallback = callback;
    this._onSuccessCallback.bind(this);
    return this;
  }

  onError(callback: (data: IErrorCallbackObject) => void): IFlexCheckout {
    this._onErrorCallback = callback;
    this._onErrorCallback.bind(this);
    return this;
  }

  onEvent(callback: (data: IEventCallbackObject) => void): IFlexCheckout {
    this._onEventCallback = callback;
    this._onEventCallback.bind(this);
    return this;
  }

  private perform3ds() {
    try {
      this._current3dModal = render3dModalIframe(<IModal3dsIframeParams>{
        publicToken: state.get().publicToken,
        logoUrl: this._config.custom3dsLogoUrl,
        onComplete: (result) => this.process3dIframeResult(result),
        onUserClosed: () => {
          if (this._onEventCallback) {
            this._onEventCallback(<IEventCallbackObject>{
              evType: "change",
              component: "modal3ds",
              newValue: "closed",
              oldValue: "open",
            });
          }
        },
      });
    } catch (e) {
      logger.logException(e);
    }
  }

  private process3dIframeResult(result3d: IModal3dsIframeResult) {
    if (!this._onSuccessCallback) {
      logger.logCustomError(
        "3D flow not implemented correctly. When using 3D in iframe, having an onSuccess callback is required."
      );
      return;
    }

    if (this._on3ds1CompleteCallback) {
      this._on3ds1CompleteCallback(result3d);
    }

    if (result3d.isSuccess) {
      if (this._config.skipCallingCreate) {
        if (this._isPlanDataLoaded) {
          this._onSuccessCallback(CallbackFactory.success(true));
        } else {
          logger.logCustomError(
            "FlexCheckout.process3dIframeResult: Plan not yet loaded. Weird.",
            { state: state.getPublicState() }
          );
          planStore.onLoaded((planData) => {
            logger.logCustomError(
              "FlexCheckout.process3dIframeResult: Recovered.",
              { state: state.getPublicState() }
            );
            this._onSuccessCallback(CallbackFactory.success(true));
          });
        }
      } else {
        this._onSuccessCallback(CallbackFactory.success(true));
      }
    } else {
      if (this._onErrorCallback) {
        this._onErrorCallback(
          CallbackFactory.error(
            [
              {
                description:
                  "3D secure verification was not completed successfully.",
                source: "3ds",
                code: "641-F",
                fieldTypes: [],
                showError: true,
              },
            ],
            true
          )
        );
      }
    }

    hide3dModal(this._current3dModal);
  }
}
