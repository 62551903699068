import { FlexField } from "./field-base";
import Cleave from 'cleave.js';
import { localizer } from "../localization-provider";

export class ExpirationYearField extends FlexField {
    private _cleaveWrapper: any;
    public constructor(guid){
        super(guid);
       
        this._type = 'expiration-year';
    }

    public updateChange(newValue: string){
        // Override default change, listen to cleave's
    }

    public updateKeypress(newValue: string){
        // Override default change, listen to cleave's
    }

    protected validate(){
        if (!this._value || this._value.length != 2){
            this._isValid = false;
            this._clientError = localizer.invalidExpirationDate;
        } else {
            let y = parseInt(this._value);
            let curY = new Date().getFullYear() % 100;
            if (y < curY){
                this._isValid = false;
                this._clientError = localizer.invalidExpirationDate;
            }
        }
    }

    protected injectValue(rawValue: string){
        this._cleaveWrapper.setRawValue(rawValue);
    }

    public init(fieldSelector: string){
        super.init(fieldSelector);

        this._cleaveWrapper = new Cleave(this._inputElement, {
            date: true,
            datePattern: ['y'],
            onValueChanged: (ev) => {
                if (this._value != ev.target.value){
                    this._value = ev.target.value;
                    if (this._value && this._value.length > 0){
                        this._everNonEmpty = true;
                    }
                    this._isValid = null;
                    this._clientError = null;
                    this.sendToCollector();
                }
            }
        });
    }
}