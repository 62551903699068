import Cleave from "cleave.js";
import { luhnValidate } from "splitit-utils";
import { CollectCardDataPayload } from "../frame-manager-payloads";
import { localizer } from "../localization-provider";
import { FlexField } from "./field-base";

export class CardNumberField extends FlexField {
  private _cardType: string;
  private _forter: string;
  private _cleaveWrapper: Cleave;

  public constructor(guid, uiMode) {
    super(guid, uiMode, false);

    this._type = "number";
  }

  protected formatCollectPayload(
    payload: CollectCardDataPayload
  ): CollectCardDataPayload {
    payload = super.formatCollectPayload(payload);
    payload.cardType = this._cardType;
    payload.forter = this._forter;
    return payload;
  }

  public setForterToken(value: string) {
    this._forter = value;
  }

  public updateChange(newValue: string) {
    // Override default change, listen to cleave's
  }

  public updateKeypress(newValue: string) {
    // Override default change, listen to cleave's
  }

  protected validate() {
    if (!this._value || this._value.length < 6) {
      this._isValid = false;
      this._clientError = localizer.invalidCardNumber;
    } else {
      if (!luhnValidate(this._value)) {
        this._isValid = false;
        this._clientError = localizer.invalidCardNumber;
      }
    }
  }

  protected injectValue(rawValue: string) {
    this._cleaveWrapper.setRawValue(rawValue);
  }

  protected getExposableValue() {
    if (this._value && this._value.length > 6) {
      return this._value.substr(0, 6);
    }

    return this._value;
  }

  public init(fieldSelector: string) {
    super.init(fieldSelector);

    this._cleaveWrapper = new Cleave(this._inputElement, {
      creditCard: true,
      creditCardStrictMode: true,
      onValueChanged: (ev) => {
        if (this._value != ev.target.rawValue) {
          this._value = ev.target.rawValue;
          if (this._value && this._value.length > 0) {
            this._everNonEmpty = true;
          }
          this._isValid = null;
          this._clientError = null;
          this.sendToCollector();
        }
      },
      onCreditCardTypeChanged: (cardType) => {
        if (this._cardType && this._cardType != "") {
          this._inputElement.classList.remove(this._cardType);
        }
        if (cardType) {
          this._inputElement.classList.add(cardType);
        }
        this._cardType = cardType.toString();
        this.sendToCollector();
      },
    });
  }
}
