import { VERSION, FLEX_FIELDS_ENV } from "./constants";
import { config } from "./config";
import { localizer } from "./localization-provider";
import { env, ThreeDsIframe } from "splitit-utils";
import { IModal3dsIframeResult } from "./models/callback-contracts";
import { template } from "./ui-components/loader-template"; // require("./ui-components/loader.t.html");
import { template as modal3dsIframe } from "./ui-components/modal-3ds1-iframe-template"; // require("./ui-components/modal-3ds2.t.html");
export function appendStyles(container: HTMLElement, url: string): void {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = url;
  container.parentElement.insertBefore(link, container);
}

export function renderLoader(container: HTMLElement) {
  if (config.loader) {
    if (config.loader instanceof Function) {
      config.loader(container);
    } else {
      const loaderStr = `${config.loader}`;
      try {
        if (document.querySelector(loaderStr)) {
          container.append(document.querySelector(loaderStr));
        } else {
          container.innerHTML = `<div class="splitit-loader-container">${loaderStr}</div>`;
        }
      } catch (e) {
        container.innerHTML = `<div class="splitit-loader-container">${loaderStr}</div>`;
      }
    }
  } else {
    container.innerHTML = template();
  }
}

export interface IModalParams {
  title: string;
  content: string;
  cancel?: string;
  ok: string;

  okCallback?: () => void;
  cancelCallback?: () => void;
}

export interface IModal3dsIframeParams {
  publicToken: string;
  logoUrl?: string;
  onComplete: (result: IModal3dsIframeResult) => void;
  onUserClosed: () => void;
}

export function hide3dModal(wrapper: HTMLElement) {
  wrapper.remove();
}

export function render3dModalIframe(
  modalParams: IModal3dsIframeParams
): HTMLElement {
  const div = document.createElement("div");
  div.innerHTML = modal3dsIframe({
    localizer,
    customHeaderStyle: modalParams.logoUrl
      ? `background-image: url(${modalParams.logoUrl});`
      : "",
  });

  const modalWrapper = document.body.appendChild(div.firstChild) as HTMLElement;
  setTimeout(() => modalWrapper.classList.add("splitit-modal-open"), 100);

  const iframeContainer = modalWrapper.querySelector(".iframe-container");
  iframeContainer.id = `splitit-3ds2-${new Date().getTime()}`;

  const ffEnv = <env>(FLEX_FIELDS_ENV == "local" ? "beta" : FLEX_FIELDS_ENV);
  const iframe3ds = new ThreeDsIframe({
    Identifier: iframeContainer.id,
    Env: ffEnv,
    PublicToken: modalParams.publicToken,
    OnSuccessCb: (event) =>
      modalParams.onComplete({
        isSuccess: event.isSuccess,
        redirectUrl: event.redirectUrl,
      }),
    OnFailureCb: (err?) =>
      modalParams.onComplete({
        isSuccess: false,
      }),
  });

  const closeBtn = modalWrapper.querySelector(
    ".splitit-btn-close"
  ) as HTMLAnchorElement;
  closeBtn.onclick = () => {
    iframe3ds.dispose();
    modalWrapper.classList.remove("splitit-modal-open");
    setTimeout(() => {
      modalWrapper.remove();
      if (modalParams.onUserClosed) {
        modalParams.onUserClosed();
      }
    }, 500);
  };

  return modalWrapper;
}
