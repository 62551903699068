import { SplititFlexFields } from "./splitit-flex-fields";
import { AutoArrangeManager } from "./auto-arrange-manager";
import { SplititFlexCheckout } from "./splitit-flex-checkout";
import { Collector } from "./collector";
import { FlexFieldFactory } from "./fields/factory";
import { FLEX_FIELDS_URL, FLEX_FIELDS_ENV } from "./constants";
import { config } from "./config";
import { utils } from "./utils";
import "promise-polyfill/src/polyfill";
import "whatwg-fetch";
import "url-search-params-polyfill";

import "./polyfills";

import "../css/splitit.flex-fields.less";
import "../css/splitit.flex-fields.field.less";

import "../index.html";
import "../formfield.html";

var _sessionId = `${new Date().getTime()}.${uuidv4()}`;

export function setup(options) {
  if (window["splitit-ff-instance"]) {
    window["splitit-ff-instance"].destroy();
  }

  AutoArrangeManager.apply(options);

  utils.verifySupportedCulture(options);
  config.overrideOptions(options);

  window["splitit-ff-instance"] = new SplititFlexFields(_sessionId);
  return window["splitit-ff-instance"];
}

export function completeCheckout(options) {
  utils.verifySupportedCulture(options);

  if (window["splitit-ff-checkout-instance"]) {
    window["splitit-ff-checkout-instance"].destroy();
  }

  window["splitit-ff-checkout-instance"] = new SplititFlexCheckout(_sessionId);
  window["splitit-ff-checkout-instance"].init(options);
  return window["splitit-ff-checkout-instance"];
}

export function field(type, sessionId, guid, uiMode) {
  return FlexFieldFactory.create(type, sessionId, guid, uiMode);
}

export function collector(guid, sessionId) {
  return new Collector(guid, sessionId);
}

export function uuidv4() {
  return utils.guid();
}
