export const template = (props) => {
  const { type, url, labelText, isWhiteLabel } = props;

  return ` 
  <div class="splitit-flex-field splitit-flex-field-${type} ${
    isWhiteLabel ? "splitit-flex-field-generic" : ""
  }">
      <label class="splitit-flex-field-label ${
        isWhiteLabel ? "splitit-flex-field-generic" : ""
      }">${labelText}</label>
      <iframe src="${url}" frameborder="0" name="splitit-hf-${type}" width="100%" height="100%" allowTransparency="true"></iframe>
    <div class="tooltip-icon-container"></div>
  </div>
  <div class="splitit-flex-field-error splitit-flex-field-error-${type} splitit-hidden ${
    isWhiteLabel ? "splitit-flex-field-generic" : ""
  }"></div>
`;
};
