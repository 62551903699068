import { Component } from "./component-registration";

export const InstallmentIconElementName = "installments-icon";
const fillAttributeName = "data-icon-fill";
const defaultFill = "#39BCA7";
const svgContent = (
  fill = defaultFill
) => `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 20A8 8 0 1 0 7.628 5.3l4.929 6.023.468.573-.41.615-4.37 6.555A7.965 7.965 0 0 0 12 20zm-5.39-2.088 3.93-5.895-4.437-5.423A7.971 7.971 0 0 0 4 12a7.98 7.98 0 0 0 2.61 5.912z" fill="${
  fill || defaultFill
}"/>
</svg>`;

@Component({ tagName: InstallmentIconElementName })
export class IconPlaceHolder extends HTMLElement {
  get customFill() {
    return this.getAttribute(fillAttributeName) || undefined;
  }

  set customFill(value: string) {
    this.setAttribute(fillAttributeName, value);
  }

  attributeChangedCallback(
    name: string,
    oldValue: string,
    newValue: string
  ): void {
    if (oldValue === newValue) {
      return;
    }
    if (name === fillAttributeName) {
      this.changeIconFill(newValue);
    } else {
      this.render();
    }
  }

  private changeIconFill(newValue: string) {
    if (!newValue) {
      this.render();
    } else {
      this.querySelector("path")?.setAttribute("fill", newValue);
    }
  }

  static get observedAttributes() {
    return [fillAttributeName];
  }
  constructor() {
    super();
  }
  connectedCallback(): void {
    this.render();
  }

  render() {
    this.innerHTML = `${svgContent(this.customFill || undefined)}`;
  }
}
