export const template = (props) => {
  const { buttons } = props;
  return `${buttons
    .map(
      (button) =>
        `<div class="splitit-ip-b ${button.isFirst ? "first" : ""} ${
          button.isLast ? "last" : ""
        }"
     style="width: ${button.width}%;">
        <button type="button">
            <span class="splitit-ip-b-top-row">${button.numPayments}</span>
            <br />
            <span class="splitit-ip-b-bottom-row">${
              button.amountPerMonth
            }</span>
        </button>
    </div>`
    )
    .join("")}
<div style="clear: both;"></div>`;
};
