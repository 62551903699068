import { IErrorCallbackObject, ISuccessCallbackObject } from "../models/callback-contracts";
import { IErrorModel } from "../models/common-contracts";
import { state } from "../state";

export class CallbackFactory
{
    public static success(is3d: boolean, traceId?: string) : ISuccessCallbackObject {
        const publicState = state.getPublicState();
        return {
            is3d: is3d,
            redirectUrl: publicState.successRedirectUrl,
            traceId: traceId,
            installmentPlan: {
                installmentPlanNumber: publicState.planNumber,
                refOrderNumber: publicState.refOrderNumber
            }
        };
    }

    public static error(errors: Array<IErrorModel>, is3d?: boolean) : IErrorCallbackObject {
        const publicState = state.getPublicState();
        let traceId = undefined;

        if (errors && errors.length > 0){
            traceId = errors.filter(e => e.traceId).join(',');
        }

        return {
            errors: errors,
            is3d: is3d,
            redirectUrl: publicState.successRedirectUrl,
            traceId: traceId,
            installmentPlan: {
                installmentPlanNumber: publicState.planNumber,
                refOrderNumber: publicState.refOrderNumber
            }
        };
    }
}