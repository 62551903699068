import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import { config, FieldStyleSection } from "../config";
import { VERSION } from "../constants";
import { DEFAULT_FLAG, UI_MODE_HORIZONTAL } from "../constants-ts";
import { getFieldUrlForType } from "../fields/field-types";
import { frameManager, MessageTypes } from "../frame-manager";
import {
  FieldInitPayload,
  OverrideFieldStylePayload,
} from "../frame-manager-payloads";
import { localizer } from "../localization-provider";
import { IFieldDescriptorInfo } from "../models/common-contracts";
import { IFieldConfigSection } from "../models/config-contracts";
import { FieldType } from "../models/types";
import { state } from "../state";
import { utils } from "../utils";
import { template } from "./flex-field-template";

export class FieldWrapper {
  public frameId?: string;
  public isInitialized: boolean;
  public wrapperContainer?: HTMLElement;
  public fieldContainer?: HTMLElement;
  public errorContainer?: HTMLElement;
  private horizontalModeWrapper?: HTMLElement;
  public selector: string;
  public type: FieldType;
  private _isDestroyed: boolean;
  private fieldConfig: IFieldConfigSection;

  constructor(
    selector: string,
    type: FieldType,
    fieldConfig: IFieldConfigSection,
    private isWhiteLabel: boolean
  ) {
    this._isDestroyed = false;
    this.selector = selector;
    this.type = type;
    this.isInitialized = false;
    this.fieldConfig = fieldConfig;
  }

  public init(data: FieldInitPayload) {
    this.frameId = data._originId;
    this.wrapperContainer = document.querySelector(this.selector);
    if (this.wrapperContainer) {
      const parent1 = this.wrapperContainer.parentElement;
      if (parent1.classList.contains("splitit-horizontal")) {
        this.horizontalModeWrapper = parent1;
      } else if (
        parent1.parentElement.classList.contains("splitit-horizontal")
      ) {
        this.horizontalModeWrapper = parent1.parentElement;
      }
    }

    this.fieldContainer = document.querySelector(
      ".splitit-flex-field.splitit-flex-field-" + this.type
    );
    this.errorContainer = document.querySelector(
      ".splitit-flex-field-error.splitit-flex-field-error-" + this.type
    );
    this.isInitialized = true;

    utils.recordFirstInteraction(this.wrapperContainer);

    state.addField(<IFieldDescriptorInfo>{
      errors: [],
      showValidationError: false,
      type: this.type,
    });

    const fieldStyle = new FieldStyleSection();
    fieldStyle.overrideOptions(config.fields.style); // override with global options

    // Override with field-specific settings
    if (this.fieldConfig.style) {
      fieldStyle.overrideOptions(this.fieldConfig.style);
    }

    if (fieldStyle.hasOverrides()) {
      frameManager.notify<OverrideFieldStylePayload>(
        MessageTypes.FIELD_STYLE_OVERRIDE,
        { style: fieldStyle },
        this.frameId
      );
    }

    state.onChange((old) => {
      const newState = state.get();
      const fieldErrors = state
        .getActiveErrors(old)
        .errors.filter(
          (e) =>
            e.fieldTypes && e.fieldTypes.indexOf(this.type) >= 0 && e.showError
        );

      const oldField = old.fields.filter((f) => f.type == this.type)[0];
      const newField = newState.fields.filter((f) => f.type == this.type)[0];

      if (newField && oldField && newField.cardType != oldField.cardType) {
        this.fieldContainer.setAttribute("data-card", newField.cardType);
      }

      if (!newField) {
        console.error("Why is this null???");
      }

      let hzWrapperClassListBefore = null;
      if (this.horizontalModeWrapper) {
        hzWrapperClassListBefore = this.horizontalModeWrapper.classList.value;
      }

      if (newField && newField.isValid === true) {
        this.horizontalModeWrapper &&
          this.horizontalModeWrapper.classList.remove(`invalid-${this.type}`);
        this.horizontalModeWrapper &&
          this.horizontalModeWrapper.classList.add(`valid-${this.type}`);
        this.fieldContainer.classList.remove("invalid");
        this.fieldContainer.classList.add("valid");
      } else if (
        newField &&
        newField.isValid === false &&
        newField.showValidationError
      ) {
        this.fieldContainer.classList.remove("valid");
        this.fieldContainer.classList.add("invalid");
        this.horizontalModeWrapper &&
          this.horizontalModeWrapper.classList.remove(`valid-${this.type}`);
        this.horizontalModeWrapper &&
          this.horizontalModeWrapper.classList.add(`invalid-${this.type}`);
      } else {
        this.horizontalModeWrapper &&
          this.horizontalModeWrapper.classList.remove(`valid-${this.type}`);
        this.horizontalModeWrapper &&
          this.horizontalModeWrapper.classList.remove(`invalid-${this.type}`);
        this.fieldContainer.classList.remove("valid");
        this.fieldContainer.classList.remove("invalid");
      }

      if (
        this.horizontalModeWrapper &&
        this.horizontalModeWrapper.classList.value != hzWrapperClassListBefore
      ) {
        const stylePayload = new OverrideFieldStylePayload();
        stylePayload.style = new FieldStyleSection();

        if (
          this.horizontalModeWrapper.classList.contains(`invalid-${this.type}`)
        ) {
          stylePayload.style.textColor = "red";
          frameManager.notify<OverrideFieldStylePayload>(
            MessageTypes.FIELD_STYLE_OVERRIDE,
            stylePayload,
            this.frameId
          );
        } else if (fieldStyle.textColor) {
          stylePayload.style.textColor = fieldStyle.textColor;
          frameManager.notify<OverrideFieldStylePayload>(
            MessageTypes.FIELD_STYLE_OVERRIDE,
            stylePayload,
            this.frameId
          );
        } else {
          stylePayload.style.textColor = DEFAULT_FLAG;
          frameManager.notify<OverrideFieldStylePayload>(
            MessageTypes.FIELD_STYLE_OVERRIDE,
            stylePayload,
            this.frameId
          );
        }
      }

      if (newField && newField.isFocused) {
        this.fieldContainer.classList.add("focused");
      } else {
        this.fieldContainer.classList.remove("focused");
      }

      if (newField && newField.hasContent) {
        this.fieldContainer.classList.add("has-content");
      } else {
        this.fieldContainer.classList.remove("has-content");
      }

      if (fieldErrors.length > 0) {
        this.errorContainer.innerHTML = "* " + fieldErrors[0].description;
        this.errorContainer.classList.remove("splitit-hidden");
      } else {
        this.errorContainer.classList.add("splitit-hidden");
      }
    });
  }

  render(sessionId: string) {
    this.wrapperContainer = document.querySelector(this.selector);
    this.wrapperContainer.classList.add("splitit-flex-field-wrapper");
    this.wrapperContainer.classList.add(
      "splitit-flex-field-wrapper-" + this.type
    );

    let url = getFieldUrlForType(this.type);
    if (url.indexOf("?") > 0) {
      url += "&v=" + VERSION;
    } else {
      url += "?v=" + VERSION;
    }

    if (config.getUiMode()) {
      url += "&ui-mode=" + config.getUiMode();
    }

    url += "&s=" + sessionId;

    this.wrapperContainer.innerHTML = template({
      url: url,
      type: this.type,
      labelText: localizer.getLabelForField(this.type),
      isWhiteLabel: this.isWhiteLabel,
    });

    if (
      this.type == "number" ||
      this.type == "expiration-date" ||
      this.type == "cvv"
    ) {
      const tooltipInstance = tippy(
        `${this.selector} .tooltip-icon-container`,
        {
          appendTo: () => this.wrapperContainer,
          placement: "bottom-end",
          theme: "light-border",
          maxWidth: 180,
          offset: this.isWhiteLabel ? [0, 10] : [0, -1],
        }
      )[0];

      localizer.register(() => {
        if (!this._isDestroyed) {
          tooltipInstance.setContent(localizer.getTooltipForField(this.type));
        }
      });
    }

    if (
      this.type == "number" ||
      this.type == "expiration-date" ||
      this.type == "cvv"
    ) {
      if (config.getUiMode() == UI_MODE_HORIZONTAL) {
        const errorBox = document.querySelector(
          ".splitit-horizontal-cc-error-box"
        );
        if (errorBox) {
          errorBox.appendChild(
            this.wrapperContainer.querySelector(".splitit-flex-field-error")
          );
        }
      }
    }

    localizer.register(this.localizeChangeCallback);
  }

  localizeChangeCallback = () => {
    if (!this._isDestroyed) {
      const label = this.wrapperContainer.querySelector("label");
      if (label) {
        label.innerText = localizer.getLabelForField(this.type);
      }
    }
  };

  destroy() {
    this._isDestroyed = true;
    localizer.unregister(this.localizeChangeCallback);
    const container = document.querySelector(this.selector);
    if (container) {
      container.innerHTML = "";
    }

    const errorBox = document.querySelector(".splitit-horizontal-cc-error-box");
    if (errorBox) {
      errorBox.innerHTML = "";
    }
  }
}
