export const template = (props) => {
  const { customHeaderStyle } = props;
  return `<div class="splitit-modal-wrapper splitit-modal-wrapper-3ds1 splitit-modal-wrapper-3ds1-iframe">
    <div class="splitit-modal">
        <div class="splitit-modal-header" style="${customHeaderStyle || ""}" >
            <a href="javascript:void(0)" class="splitit-btn-close">x</a>
        </div>
        <div class="splitit-modal-content">
            <div class="iframe-container"></div>
        </div>
    </div>
</div>`;
};

// For test mocking purposes
export default { template };
