import { EventFactory } from 'splitit-utils';
import { config } from '../config';
import { Localization, localizer } from '../localization-provider';
import { logger } from '../logger';
import { planStore } from '../services/plan-store';
import { ISplititApiGetSchedulesResponse } from '../services/splitit-api-models';
import { state } from '../state';
import { splititTrackerDecorator } from '../tracking/splitit-tracker-decorator';
import { HowSplititWorksLink } from '../ui-components/how-splitit-works-link';
import { utils } from '../utils';
import { template } from './ui-template';

interface InstallmentUiModel {
	paymentOrd: string;
	date: string;
	amount: string;
	requiredCredit: string;
}

interface PaymentScheduleUiModel {
	localizer: Localization;
	installments: Array<InstallmentUiModel>;
}

export class PaymentScheduleRenderer {
	private _expanded: boolean;
	public container: HTMLElement;

	private _schedule: ISplititApiGetSchedulesResponse;

	constructor() {
		state.onChange((old) => {
			if (
				old.numInstallments != state.get().numInstallments ||
				old.amount != state.get().amount ||
				old.isSecure != state.get().isSecure ||
				old.firstChargeDate != state.get().firstChargeDate ||
				old.firstInstallmentAmount != state.get().firstInstallmentAmount
			) {
				if (config.isDebugMode() && old.isSecure != state.get().isSecure) {
					console.log(`Plan changed to ${state.get().isSecure ? 'secure' : 'nonsecure'}`);
				}

				this.render();
			}
		});

		localizer.register(() => {
			this.render();
		});

		//TODO: For better testability, break this coupling.
		planStore.onScheduleLoaded((schedule) => {
			this._schedule = schedule;
			this.render();
		});
	}

	public destroy() {
		if (this.container) {
			this.container.innerHTML = '';
		}
	}

	public async render() {
		if (!this.container || !state.get().amount || !this._schedule || !state.get().numInstallments) {
			return;
		}

		utils.recordFirstInteraction(this.container);

		const numInstallments = state.get().numInstallments;
		const schedule = this._schedule.Schedules!.find((s) => s.NumberOfInstallments == numInstallments);

		if (!schedule || !schedule.Elements || schedule.Elements.length != numInstallments) {
			logger.logCustomError('Cannot create payment schedule, missing data.', {
				numInstallments,
				schedule: this._schedule
			});
			this.container.innerHTML = '';
			return;
		}

		this.container.innerHTML = template(<PaymentScheduleUiModel>{
			localizer,
			installments: schedule.Elements.map((s) => {
				const itemModel = {
					amount: utils.formatCurrency(s.ChargeAmount, state.getCurrencySymbol(), state.getCurrencyDecimalPlaces()),
					date: utils.formatDate(s.ChargeDate),
					paymentOrd: localizer.paymentOrd(s.InstallmentNumber, numInstallments),
					requiredCredit: utils.formatCurrency(
						s.RequiredCredit,
						state.getCurrencySymbol(),
						state.getCurrencyDecimalPlaces()
					)
				} as InstallmentUiModel;
				return itemModel;
			})
		});

		const trigger = this.container.querySelector('.splitit-ps-h-link-toggle-schedule') as HTMLElement;

		const wrapper = this.container.querySelector('.splitit-payment-schedule') as HTMLElement;
		const headerContainer = this.container.querySelector('.splitit-ps-h') as HTMLElement;

		if (numInstallments) {
			const howSplititWorksLink = new HowSplititWorksLink();
			howSplititWorksLink.addTo(headerContainer, 0);
		}

		if (this._expanded) {
			wrapper.classList.add('expanded');
		}

		trigger.onclick = () => {
			if (this._expanded) {
				wrapper.classList.remove('expanded');
				this._expanded = false;
				splititTrackerDecorator.sendEvent(EventFactory.CloseDropdownPaymentSchedule());
			} else {
				wrapper.classList.add('expanded');
				this._expanded = true;
				splititTrackerDecorator.sendEvent(EventFactory.OpenDropdownPaymentSchedule());
			}
		};
	}
}
