import { ISplititSessionInfo, ITrackingEvent } from "splitit-utils";

export class EmptyEventTracker {
  constructor(
    endpoint: string,
    sessionId?: string,
    endpointConfig?: "splitit" | "rivery" | "amazon-gw",
    calculateVisitorId?: boolean
  ) {
    // Do nothing
  }

  updateSessionInfo(sessionInfo: ISplititSessionInfo) {
    // Do nothing
  }

  recordEvent(eventData: ITrackingEvent) {
    // Do nothing
  }
}
