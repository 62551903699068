import { config } from './config';
import { UI_MODE_HORIZONTAL } from './constants-ts';
import { FIELD_TYPES } from './fields/field-types';
import { frameManager, MessageTypes } from './frame-manager';
import { LocalizationPayload } from './frame-manager-payloads';
import { logger } from './logger';
import { splititApiService } from './services/splitit-api';
import { IGetResourcesRequest } from './services/splitit-api-models';
import { utils } from './utils';

class FormattedLocalization {
	paymentOrd = 'Payment {cur}/{total}';
	numPayments = '{num} payments';
	amountPerMonth = '{currencySymbol}{amount} per month';
	amountPerMonthWithSlash = '{currencySymbol}{amount}/month';
	amountNow = '{currencySymbol}{amount} + ';
	amountTotal = '{currencySymbol}{amount}';
	month = '{month}';

	_keys = ['paymentOrd', 'numPayments', 'amountPerMonth'];
}

class ApiResources {
	_keys = ['invalidExpirationDate', 'invalidcvv', 'invalidCardNumber'];
}

export class Localization {
	biweekly = 'biweekly';
	monthly = 'monthly';
	paymentSchedule = 'Payment schedule';
	expirationDatePlaceholder = 'Exp. MM/YY';
	expirationYearPlaceholder = 'YY';
	expirationMonthPlaceholder = 'MM';
	pay = 'Place Order';
	charge = 'Charge';
	held = 'Held';
	howSplititWorks = 'How Splitit Works';
	howSplititWorksWhiteLabel = 'Learn More';
	termsConditionsHtml =
		"I accept the <a class='splitit-tc-t'>Terms & Conditions</a> and <a class='splitit-tc-p'>Privacy Policy</a>";
	termsConditionsHtmlWhiteLabel =
		"I accept the <a class='splitit-tc-t'>Terms & Conditions</a> and <a class='splitit-tc-p'>Privacy Policy</a>";
	modal3dTitle = '3D redirection required';
	modal3dContent =
		'To complete the payment, you will be redirected to your account provider for additional 3D security.';
	ok = 'OK';
	cancel = 'Cancel';
	requiredCredit = 'Required Credit';
	secure3ds1_title = 'Your card issuer needs to authenticate your payment request for added security';
	secure3ds1_subtitle = 'Once verified your order will be complete';
	secure3ds1_youWillBeRedirectedIn = 'You will be redirected in';
	seconds = 'seconds';
	redirect = 'Redirect';
	formatted: FormattedLocalization;
	apiResources: ApiResources;
	secure3d_userCancelled = '3D secure verification cancelled by user.';
	secure3d_failed = '3D secure verification was not completed successfully.';
	onePayment = '1 payment';
	termsNotAccepted = 'Please read and accept terms and conditions before proceeding.';
	numInstallmentsNotSelected = 'Please choose number of installments.';
	invalidExpirationDate = 'Invalid expiration date.';
	invalidcvv = 'Invalid CVV.';
	invalidCardNumber = 'Invalid credit card number.';
	invalidCardholderName = 'Name on card cannot be empty.';
	youWillPayTotalOf = `You'll pay total of`;
	'choose-installment-plan' = 'Select payment plan';
	credit_card_terms = '* Credit card terms and conditions apply';

	private _onChangeCallbacks: (() => void)[];

	_keys: string[] = [
		'paymentSchedule',
		'expirationDatePlaceholder',
		'expirationYearPlaceholder',
		'expirationMonthPlaceholder',
		'pay',
		'termsConditionsHtml',
		'termsConditionsHtmlWhiteLabel',
		'charge',
		'held',
		'howSplititWorks',
		'howSplititWorksWhiteLabel',
		'modal3dTitle',
		'modal3dContent',
		'ok',
		'cancel',
		'requiredCredit',
		'secure3ds1_title',
		'secure3ds1_subtitle',
		'secure3ds1_youWillBeRedirectedIn',
		'seconds',
		'redirect',
		'formatted',
		'secure3d_userCancelled',
		'secure3d_failed',
		'onePayment',
		'termsNotAccepted',
		'numInstallmentsNotSelected',
		'invalidExpirationDate',
		'invalidcvv',
		'invalidCardNumber',
		'invalidCardholderName',
		'youWillPayTotalOf',
		'credit_card_terms'
	];

	public remoteResources?: { [key: string]: string };

	constructor() {
		this._onChangeCallbacks = [];
		this.formatted = new FormattedLocalization();
		this.apiResources = new ApiResources();
		this.remoteResources = null;

		this['lbl-' + FIELD_TYPES.CVV] = 'CVV';
		this['lbl-' + FIELD_TYPES.CARDHOLDER_NAME] = 'Name on card';
		this['lbl-' + FIELD_TYPES.EXP_DATE] = 'MM/YY';
		this['lbl-' + FIELD_TYPES.EXP_MONTH] = 'MM';
		this['lbl-' + FIELD_TYPES.EXP_YEAR] = 'YY';
		this['lbl-' + FIELD_TYPES.NUMBER] = 'Card Number';

		this['tooltip-' + FIELD_TYPES.CVV] = 'CVV';
		this['tooltip-' + FIELD_TYPES.CARDHOLDER_NAME] = 'Name on card';
		this['tooltip-' + FIELD_TYPES.EXP_DATE] = 'MM/YY';
		this['tooltip-' + FIELD_TYPES.EXP_MONTH] = 'MM';
		this['tooltip-' + FIELD_TYPES.EXP_YEAR] = 'YY';
		this['tooltip-' + FIELD_TYPES.NUMBER] = 'Card Number';

		this['placeholder-' + FIELD_TYPES.CVV] = 'CVV';
		this['placeholder-' + FIELD_TYPES.CARDHOLDER_NAME] = 'Name on card';
		this['placeholder-' + FIELD_TYPES.EXP_DATE] = 'MM/YY';
		this['placeholder-' + FIELD_TYPES.EXP_MONTH] = 'MM';
		this['placeholder-' + FIELD_TYPES.EXP_YEAR] = 'YY';
		this['placeholder-' + FIELD_TYPES.NUMBER] = 'Card Number';

		this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.CVV] = 'CVV';
		this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.CARDHOLDER_NAME] = 'Name on card';
		this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_DATE] = 'MM/YY';
		this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_MONTH] = 'MM';
		this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_YEAR] = 'YY';
		this[`placeholder-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.NUMBER] = 'Card Number';

		this['placeholder-focused-' + FIELD_TYPES.CVV] = 'xxx or xxxx';
		this['placeholder-focused-' + FIELD_TYPES.CARDHOLDER_NAME] = 'Ex: John Doe';
		this['placeholder-focused-' + FIELD_TYPES.EXP_DATE] = 'Ex: 03/24';
		this['placeholder-focused-' + FIELD_TYPES.EXP_MONTH] = 'Ex: 02';
		this['placeholder-focused-' + FIELD_TYPES.EXP_YEAR] = 'Ex: 24';
		this['placeholder-focused-' + FIELD_TYPES.NUMBER] = 'xxxx xxxx xxxx xxxx';

		this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.CVV] = 'xxxx';
		this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.CARDHOLDER_NAME] = 'Ex: John Doe';
		this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_DATE] = 'MM/YY';
		this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_MONTH] = 'MM';
		this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.EXP_YEAR] = 'YY';
		this[`placeholder-focused-${UI_MODE_HORIZONTAL}-` + FIELD_TYPES.NUMBER] = 'xxxx xxxx xxxx xxxx';
	}

	public subscribeLocalizationLoaded() {
		frameManager.subscribe(MessageTypes.LOCALIZATION_LOADED, this, (data: LocalizationPayload) => {
			this.assignResources(data.resources);
		});
	}

	public getLabelForField(fieldType: string) {
		return this['lbl-' + fieldType];
	}

	public getTooltipForField(fieldType: string) {
		return this['tooltip-' + fieldType];
	}

	public getPlaceholderForField(fieldType: string, isFocused: boolean, uiMode?: string) {
		let prefix = 'placeholder-';

		if (uiMode === 'whiteLabel') {
			if (!isFocused) prefix = `${prefix}focused-`;
			uiMode = null;
		}
		if (isFocused) {
			prefix = `${prefix}focused-`;
		}

		if (uiMode && uiMode.length > 0 && uiMode != 'null') {
			prefix = `${prefix}${uiMode}-`;
		}

		const result = this[`${prefix}${fieldType}`];

		return result;
	}

	public register(callback: () => void) {
		this._onChangeCallbacks.push(callback);
		callback();
	}

	public unregister(callback: () => void) {
		this._onChangeCallbacks = this._onChangeCallbacks.filter((cb) => cb !== callback);
	}

	public assignResources(resources: { [key: string]: any }): void {
		this._keys.forEach((p) => {
			if (resources[p.toLowerCase()] || resources[p]) {
				this[p] = resources[p.toLowerCase()] || resources[p];
			}
		});

		this['tooltip-' + FIELD_TYPES.NUMBER] = resources['pf_card_number_tooltip'];
		this['tooltip-' + FIELD_TYPES.EXP_DATE] = resources['pf_expiration_tooltip'];
		this['tooltip-' + FIELD_TYPES.CVV] = resources['pf_cvv_tooltip'];

		const prefixes = ['lbl', 'placeholder', 'tooltip', `placeholder-${UI_MODE_HORIZONTAL}`];

		prefixes.forEach((p) => {
			this[`${p}-${FIELD_TYPES.NUMBER}`] = resources['lblcardnumber'];
			this[`${p}-${FIELD_TYPES.CARDHOLDER_NAME}`] = resources['lblnameoncard'];
		});

		this.formatted._keys.forEach((p) => {
			if (resources[p.toLowerCase()]) {
				this.formatted[p] = resources[p.toLowerCase()];
			}
		});

		this.apiResources._keys.forEach((p) => {
			if (resources[p.toLowerCase()]) {
				this.apiResources[p] = resources[p.toLowerCase()];
			}
		});

		this._onChangeCallbacks.forEach((callback) => callback());
	}

	loadRemote(callback: (resources: { [key: string]: string }) => void) {
		const requestData: IGetResourcesRequest = {
			CultureName: config.culture,
			SystemTextCategories: ['Error', 'PaymentFormV3', 'FlexFields']
		};

		splititApiService
			.getResources(requestData)
			.then((result) => {
				this.remoteResources = {};
				result.translations.forEach((x) => {
					x.translationItems.forEach((ti) => {
						this.remoteResources[ti.key] = ti.value;
					});
				});

				this.assignResources(this.remoteResources);

				if (callback) {
					callback(this.remoteResources);
				}
			})
			.catch((err) => {
				console.error(err);
				logger.logCustomError(
					'Unable to fetch resources. Refer to network tab in developer tools, and provide the information from call to GetResources when contacting Splitit support team.'
				);
			});
	}

	public paymentOrd(cur: number, total: number): string {
		return utils.formatString(this.formatted.paymentOrd, { cur, total });
	}

	public numPayments(num: number): string {
		if (num == 1) {
			return this.onePayment;
		}

		return utils.formatString(this.formatted.numPayments, { num });
	}

	public amountPerMonth(currencySymbol: string, amount: number, decimalPlaces: number, useSlash = false) {
		const formattedString = useSlash ? this.formatted.amountPerMonthWithSlash : this.formatted.amountPerMonth;
		return utils.formatString(formattedString, {
			currencySymbol,
			amount: utils.formatDecimalToLocale(amount, decimalPlaces)
		});
	}

	public amountNow(currencySymbol: string, amount: number, decimalPlaces: number) {
		return utils.formatString(this.formatted.amountNow, {
			currencySymbol,
			amount: utils.formatDecimalToLocale(amount, decimalPlaces)
		});
	}

	public amountTotal(currencySymbol: string, amount: number, decimalPlaces: number) {
		return utils.formatString(this.formatted.amountTotal, {
			currencySymbol: currencySymbol,
			amount: utils.formatDecimalToLocale(amount, decimalPlaces)
		});
	}
}

const localizer = new Localization();
export { localizer };
