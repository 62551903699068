import { InstallmentPickerRenderer } from "./base";
import { state } from "../../state";
import { localizer } from "../../localization-provider";
import { config } from "../../config";

export class InstallmentPickerTextRenderer extends InstallmentPickerRenderer {

    constructor(amount: number, opts: Array<number>){
        super(amount, opts);

        state.setNumInstallments(opts[0]);
    }

    public render(container: HTMLElement) {
        container.classList.add("splitit-installment-picker-text");

        this.setSelectedValue(this.options[0]);
        
        container.innerText = `${localizer.numPayments(this.options[0])}, ${this.getPaymentCalculationText(this.options[0])}`;
    }

    protected getPickerType() : string{
        return "text";
    }
}