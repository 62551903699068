export const template = (props) => {
  const { options } = props;
  return `<div class="splitit-ip-d-desktop">
    <button type="button" class="splitit-ip-d-desktop-sel">
        <div class="splitit-ip-d-desktop-sel-text-right"></div>
        <div class="splitit-ip-d-desktop-sel-text-left"></div>
        <div style="clear:both;"></div>
    </button>        
    <ul class="splitit-ip-d-desktop-chooser" style="display: none;">
        ${options
          .map(
            (option) => `<li class="splitit-ip-d-desktop-chooser-item">
        <span>${option.numPayments},</span>
        <span><small>${option.amountPerMonth}</small></span>
        </li>
    `
          )
          .join("")}
       
    </ul>
</div>`;
};
