import { state } from "./state";
import { IErrorModel } from "./models/common-contracts";
import { ISplititApiResponseHeader } from "./services/splitit-api-models";
import { ErrorSource } from "./models/types";

export class ApiErrorWrapper {
  public errorList: Array<IErrorModel>;
  public traceId: string;
  public require3D: boolean;
  public possibleConcurrencyError: boolean;

  private constructor() {
    this.errorList = [];
  }

  public static parse(err: any, source: ErrorSource): ApiErrorWrapper {
    const instance = new ApiErrorWrapper();

    if (err?.ResponseHeader) {
      // API errors
      const responseHeader = err.ResponseHeader as ISplititApiResponseHeader;
      instance.traceId = responseHeader.TraceId;

      if (
        responseHeader.Errors.length == 1 &&
        responseHeader.Errors[0].ErrorCode == "641"
      ) {
        instance.require3D = true;
      } else if (
        responseHeader.Errors.length == 1 &&
        responseHeader.Errors[0].ErrorCode == "5004"
      ) {
        instance.possibleConcurrencyError = true;
      } else {
        instance.errorList = responseHeader.Errors.map((x) => {
          const result: IErrorModel = {
            code: x.ErrorCode,
            description: x.Message,
            showError: true,
            source: source,
            fieldTypes: [],
          };

          return result;
        });
      }
    } else if (err?.status && err?.type) {
      instance.errorList = [
        {
          description: "HTTP error has ocurred",
          code: "00H",
          showError: true,
          fieldTypes: [],
          source: source,
        },
      ];
    } else {
      instance.errorList = [
        {
          description: "Unknown error has ocurred",
          code: "00U",
          showError: true,
          fieldTypes: [],
          source: source,
        },
      ];

      console.error(instance.errorList, err);
    }

    return instance;
  }

  public updateState(): ApiErrorWrapper {
    state.setErrors(this.errorList);
    return this;
  }
}
