import { IBillingAddress, IConsumerData } from "../models/api-contracts";
import { InstallmentsUiType } from "./plan-store";

export interface ISplititApiResponseError {
  ErrorCode?: string;
  Message?: string;
  AdditionalInfo?: string;
}

export interface ISplititApiResponseHeader {
  Succeeded: boolean;
  Errors?: Array<ISplititApiResponseError>;
  TraceId?: string;
}

export interface ISplititApiHasResponseHeader {
  ResponseHeader: ISplititApiResponseHeader;
}

export interface IExtendedCurrencyApiModel {
  DecimalPlaces: number;
}

interface IPaymentWizardDataApiModel {
  CurrencyDisplay: IExtendedCurrencyApiModel;
  CancelExitURL: string;
  LearnMoreUrl: string;
  SuccessExitURL: string;
  TermsAndConditionsUrl: string;
  PrivacyPolicyUrl: string;
  ProcessorName: string;
  Is3ds2Supported: boolean;
  NumberOfInstallmentsSelectionsOption: InstallmentsUiType;
  RequestedNumberOfInstallments: string;
  InstallmentsScheduleDaysInterval: number | null;
}

export interface ICardDataApiModel {
  CardNumber: string;
  CardHolderFullName?: string;
  CardExpMonth: string;
  CardExpYear: string;
  CardCvv: string;
}

interface ICurrencyCodeWithAmount {
  CurrencyCode: string;
  Value: number;
}

interface ICurrencyApiModel {
  Description: string;
  Code: string;
  Symbol: string;
  Id: number;
}

interface IAmountWithCurrencyApiModel {
  Currency: ICurrencyApiModel;
  Value: number;
}

export interface IPlanDataApiModel {
  NumberOfInstallments?: number;
  RefOrderNumber?: string;
  FirstInstallmentAmount?: ICurrencyCodeWithAmount;
  FirstChargeDate?: Date;
  Strategy?: SplititApiPlanStrategy;
  Amount?: ICurrencyCodeWithAmount;
  ExtendedParams?: any;
}

export interface IPlanApprovalEvidenceApiModel {
  AreTermsAndConditionsApproved: boolean;
}

export interface ISplititApiGetInitiatedPlanResponse
  extends ISplititApiHasResponseHeader {
  InstallmentPlanNumber: string;
  PaymentWizardDataResponse: IPaymentWizardDataApiModel;
  PlanData: IPlanDataApiModel;
}

interface IPlanScheduleElement {
  RequiredCredit: number;
  InstallmentNumber: number;
  ChargeDate: string | Date;
  ChargeAmount: number;
}

interface IPlanSchedule {
  Elements: Array<IPlanScheduleElement>;
  NumberOfInstallments: number;
}

export interface ISplititApiGetSchedulesResponse
  extends ISplititApiHasResponseHeader {
  Schedules: Array<IPlanSchedule>;
}

export interface ISplititApiReferenceEntity {
  Code: string;
}

export enum SplititApiPlanStrategy {
  SecuredPlan = "SecuredPlan",
  NonSecuredPlan = "NonSecuredPlan",
  SecuredPlanCaptureExistingSecurityAuth = "SecuredPlanCaptureExistingSecurityAuth",
  SecuredPlan3 = "SecuredPlan3",
  SinglePayment = "SinglePayment",
  ExternalInstallmentProvider = "ExternalInstallmentProvider",
}

interface IActiveCardApiModel {
  Bin: string;
}

interface IInstallmentPlanApiModel {
  Strategy: SplititApiPlanStrategy | ISplititApiReferenceEntity;
  Amount: IAmountWithCurrencyApiModel;
  ActiveCard: IActiveCardApiModel;
}

export interface ISplititApiCreatePlanResponse
  extends ISplititApiHasResponseHeader {
  InstallmentPlan: IInstallmentPlanApiModel;
}

export interface ISplititApiInitiatePlanResponse
  extends ISplititApiHasResponseHeader {
  InstallmentPlan: IInstallmentPlanApiModel;
}

export type SystemTextCategory = "FlexFields" | "Error" | "PaymentFormV3";

export interface ISplititApiResourcesResponse {
  translations: Array<ISplititCategoryTranslation>;
}

export interface ISplititCategoryTranslation {
  category: SystemTextCategory;
  translationItems: Array<ISplititTranslationItemEntry>;
}

export interface ISplititTranslationItemEntry {
  key: string;
  value: string;
}

export interface ISplititApiCreateInstallmentPlanRequest {
  PlanApprovalEvidence?: IPlanApprovalEvidenceApiModel;
  PlanData: IPlanDataApiModel;
  CreditCardDetails?: ICardDataApiModel;
  InstallmentPlanNumber: string;
}

export interface ISplititApiInitiatePlanRequest {
  BillingAddress?: IBillingAddress;
  InstallmentPlanNumber: string;
  ConsumerData?: IConsumerData;
  PlanData?: IPlanDataApiModel;
  CreditCardDetails?: ICardDataApiModel;
}

export interface IGetResourcesRequest {
  CultureName: string;
  SystemTextCategories: Array<SystemTextCategory>;
}
