import currencyToSymbolMap from 'currency-symbol-map/map';
import { config } from './config';
import { FLEX_FIELDS_ENV } from './constants';
import { ErrorCodes, ErrorMapper } from './error-mapper';
import { frameManager, MessageTypes } from './frame-manager';
import { SplititStateSyncPayload } from './frame-manager-payloads';
import { IErrorModel, IFieldDescriptorInfo } from './models/common-contracts';
import { ApiEnvironmentType } from './models/config-contracts';
import { IFlexFieldsState, IValidationStatus } from './models/core-contracts';
import { FieldType } from './models/types';
import { InstallmentsUiType } from './services/plan-store';
import { ISplititApiReferenceEntity, SplititApiPlanStrategy } from './services/splitit-api-models';
import { utils } from './utils';

// eslint-disable-next-line @typescript-eslint/no-var-requires
import rfdc from 'rfdc';
const clone = rfdc();

export class DemoState {
	public installments: Array<number>;
	public pickerMode: InstallmentsUiType;
	public amount = 1000;
}

export interface IPartialSplititState {
	publicToken?: string;
	refOrderNumber?: string;
	ipn?: string;
	culture?: string;
	termsConditionsUrl?: string;
	privacyPolicyUrl?: string;
	termsAccepted?: boolean;
	numInstallments?: number;
	amount?: number;
	currencyCode?: string;
	isSecure?: boolean;
	isCommiting?: boolean;
	demoMode?: DemoState;
	apiEnvironment?: ApiEnvironmentType;
	firstInstallmentAmount?: number;
	firstChargeDate?: Date;
	currencyDecimalPlaces?: number;
	is3ds2Supported?: boolean;
	processorName?: string;
	errorRedirectUrl?: string;
	successRedirectUrl?: string;
}

export interface ISplititState {
	publicToken: string;
	ipn: string;
	refOrderNumber?: string;
	culture?: string;
	termsConditionsUrl: string;
	privacyPolicyUrl: string;
	termsAccepted: boolean;
	numInstallments: number;
	installmentOptions: Array<number>;
	amount: number;
	firstInstallmentAmount?: number;
	firstChargeDate?: Date;
	currencyCode: string;
	isSecure: boolean;
	fields: Array<IFieldDescriptorInfo>;
	errors: Array<IErrorModel>;
	isCommiting: boolean;
	demoMode?: DemoState;
	apiEnvironment: ApiEnvironmentType;
	currencyDecimalPlaces: number;
	is3ds2Supported: boolean;
	processorName?: string;
	errorRedirectUrl?: string;
	successRedirectUrl?: string;
}

export interface IStateActiveErrors {
	isChanged?: boolean;
	errors: Array<IErrorModel>;
	hasErrors: boolean;
	hasGeneralErrors: boolean;
}

class SplititState {
	private _state: ISplititState;

	private _onChangeCallbacks: Array<(old: ISplititState) => void>;
	private _useFrameManager: boolean;

	constructor() {
		this.reset();
	}

	public reset(useFrameManager = true) {
		this._useFrameManager = useFrameManager;
		this._onChangeCallbacks = [];
		this._state = {
			publicToken: null,
			refOrderNumber: null,
			culture: null,
			ipn: null,
			numInstallments: null,
			installmentOptions: [],
			amount: null,
			currencyCode: null,
			termsConditionsUrl: null,
			privacyPolicyUrl: null,
			termsAccepted: false,
			fields: [],
			errors: [],
			isCommiting: false,
			demoMode: null,
			isSecure: true,
			apiEnvironment: FLEX_FIELDS_ENV,
			currencyDecimalPlaces: 2,
			is3ds2Supported: false,
			processorName: null,
			errorRedirectUrl: null,
			successRedirectUrl: null
		};

		if (useFrameManager) {
			frameManager.subscribe<SplititStateSyncPayload>(
				MessageTypes.STATE_SYNC,
				this,
				(payload: SplititStateSyncPayload) => {
					this.set(payload.state, false, payload.changedProps);
				}
			);
		}
	}

	public getCurrencySymbol(): string {
		if (config.currencySymbol) {
			return config.currencySymbol;
		}

		return currencyToSymbolMap[this._state.currencyCode];
	}

	public getCurrencyDecimalPlaces(): number {
		return this._state.currencyDecimalPlaces;
	}

	public parseUnknown<T extends { publicToken: string }>(data: T) {
		const old = clone(this._state);

		if (data.publicToken) {
			this._state.publicToken = data.publicToken;
		}

		this.fireOnChange(old);
	}

	public onChange(callback: (old: ISplititState) => void): (old: ISplititState) => void {
		this._onChangeCallbacks.push(callback);
		//TODO: .bind doesn't change the original function but creates a new one with the context
		// It may be better to remove the .bind call
		callback.bind(this);
		return callback;
	}

	public removeListener(callback: (old: ISplititState) => void) {
		this._onChangeCallbacks = this._onChangeCallbacks.filter((cb) => cb !== callback);
	}

	public addField(field: IFieldDescriptorInfo) {
		console.log('adding field', field);
		const old = clone(this._state);
		this._state.fields.push(field);
		this.fireOnChange(old, true, ['fields']);
	}

	public updateField(field: IFieldDescriptorInfo) {
		const old = clone(this._state);
		this._state.fields.forEach((f) => {
			if (f.type == field.type) {
				Object.assign(f, field);
			}
		});

		this.fireOnChange(old, true, ['fields.update']);
	}

	public setApiEnvironment(apiEnvironment: ApiEnvironmentType) {
		const old = clone(this._state);
		this._state.apiEnvironment = apiEnvironment;
		this.fireOnChange(old, true, ['apiEnvironment']);
	}

	public setCulture(culture: string) {
		const old = clone(this._state);
		this._state.culture = culture;
		this.fireOnChange(old, true, ['culture']);
	}

	public setPublicToken(token: string) {
		if (!token || token === '') {
			if (config.isDebugMode()) {
				console.warn('[Splitit] Potential configuration error: trying to set empty public token.');
			}
		}

		const old = clone(this._state);
		this._state.publicToken = token;
		this.fireOnChange(old, true, ['publicToken']);
	}

	public setAmount(amount: number) {
		const old = clone(this._state);
		this._state.amount = amount;
		this.fireOnChange(old, true, ['amount']);
	}

	public setCurrencyCode(code: string) {
		const old = clone(this._state);
		this._state.currencyCode = code;
		this.fireOnChange(old, true, ['currencyCode']);
	}

	public setStrategy(strategy: string | SplititApiPlanStrategy | ISplititApiReferenceEntity) {
		const old = clone(this._state);
		if (
			strategy == SplititApiPlanStrategy.NonSecuredPlan ||
			(<ISplititApiReferenceEntity>strategy).Code == SplititApiPlanStrategy.NonSecuredPlan
		) {
			this._state.isSecure = false;
		} else {
			this._state.isSecure = true;
		}
		this.fireOnChange(old, true, ['isSecure']);
	}

	public setTermsConditionsUrl(url: string) {
		const old = clone(this._state);
		this._state.termsConditionsUrl = url;
		this.fireOnChange(old, true, ['termsConditionsUrl']);
	}

	public setPrivacyPolicyUrl(url: string) {
		const old = clone(this._state);
		this._state.privacyPolicyUrl = url;
		this.fireOnChange(old, true, ['privacyPolicyUrl']);
	}

	public setIPN(ipn: string) {
		const old = clone(this._state);
		this._state.ipn = ipn;
		this.fireOnChange(old, true, ['ipn']);
	}

	public setNumInstallments(num: number, options?: Array<number>) {
		const old = clone(this._state);
		this._state.numInstallments = num;
		const changedProps = ['numInstallments'];

		if (options) {
			this._state.installmentOptions = options;
			changedProps.push('installmentOptions');
		}

		this.fireOnChange(old, true, changedProps);
	}

	public setTermsAccepted(termsAccepted: boolean) {
		const old = clone(this._state);
		this._state.termsAccepted = termsAccepted;
		const propsChanged = ['termsAccepted'];

		if (this._state.errors && this._state.errors.filter((e) => e.code == ErrorCodes.TERMS_NOT_SELECTED).length > 0) {
			this._state.errors = this._state.errors.filter((e) => e.code != ErrorCodes.TERMS_NOT_SELECTED);
			propsChanged.push('errors');
		}

		this.fireOnChange(old, true, propsChanged);
	}

	public setIsCommiting(isCommiting: boolean) {
		const old = clone(this._state);
		this._state.isCommiting = isCommiting;
		this.fireOnChange(old, true, ['isCommiting']);
	}

	public setDemo(demoState: DemoState) {
		const old = clone(this._state);
		this._state.demoMode = demoState;
		this.fireOnChange(old, true, ['demoMode']);
	}

	public setErrors(errors: Array<IErrorModel>) {
		const old = clone(this._state);
		this._state.errors = errors;
		this.fireOnChange(old, true, ['errors']);
	}

	public getActiveErrors(old?: ISplititState): IStateActiveErrors {
		const newState = this._state;
		const newStateErrors = ErrorMapper.combine(newState.errors, newState.fields);

		const result = <IStateActiveErrors>{
			errors: newStateErrors,
			hasErrors: newStateErrors.length > 0,
			hasGeneralErrors: newStateErrors.filter((e) => e.fieldTypes == null || e.fieldTypes.length == 0).length > 0
		};

		if (old) {
			const oldStateErrors = ErrorMapper.combine(old.errors, old.fields);
			if (
				!utils.areArraysEqual(
					newStateErrors.map((e) => `${e.code}|${e.showError}|${e.description}`),
					oldStateErrors.map((e) => `${e.code}|${e.showError}|${e.description}`)
				)
			) {
				result.isChanged = true;
			} else {
				result.isChanged = false;
			}
		}

		return result;
	}

	public get(): ISplititState {
		return this._state;
	}

	public getPublicState(state?: ISplititState): IFlexFieldsState {
		const currentState = state ?? this.get();

		const validationStatus = <IValidationStatus>{
			invalidFields: currentState.fields.filter((f) => f.isValid == false),
			pendingValidation: currentState.fields.filter((f) => f.isValid == null)
		};

		const shouldShowNumInstallmentsOrTerms =
			validationStatus.invalidFields.length + validationStatus.pendingValidation.length == 0;

		if (!currentState.numInstallments) {
			validationStatus.invalidFields.push({
				type: 'installment-picker',
				showValidationError: shouldShowNumInstallmentsOrTerms,
				errors: new Array<string>(),
				hasContent: false
			});
		}

		if (!currentState.termsAccepted) {
			validationStatus.invalidFields.push({
				type: 'terms-checkbox',
				showValidationError: shouldShowNumInstallmentsOrTerms,
				errors: new Array<string>(),
				hasContent: false
			});
		}

		validationStatus.isValid =
			validationStatus.invalidFields.length == 0 && validationStatus.pendingValidation.length == 0;

		return {
			publicToken: currentState.publicToken,
			planNumber: currentState.ipn,
			refOrderNumber: currentState.refOrderNumber,
			isTermsAccepted: currentState.termsAccepted,
			errorRedirectUrl: currentState.errorRedirectUrl,
			successRedirectUrl: currentState.successRedirectUrl,
			validationStatus: validationStatus,
			selectedNumInstallments: currentState.numInstallments,
			loadStatus: null
		};
	}

	public getField(fieldType: FieldType): IFieldDescriptorInfo {
		const fields = this._state.fields.filter((f) => f.type == fieldType);
		if (fields.length == 0) {
			return null;
		}

		return fields[0];
	}

	public set(data: ISplititState, notifyOtherFrames = true, changedProps?: Array<string>) {
		const old = clone(this._state);

		if (changedProps) {
			changedProps.forEach((p) => {
				if (p == 'fields.update') {
					this._state.fields.forEach((thisField) => {
						const incomingField = data.fields.filter((f) => f.type == thisField.type)[0];
						if (incomingField) {
							Object.assign(thisField, incomingField);
						}
					});
				} else {
					this._state[p] = data[p];
				}
			});
		} else {
			this._state = data;
		}

		this.fireOnChange(old, notifyOtherFrames);
	}

	public setPartial<TSpliititState extends IPartialSplititState>(data: TSpliititState) {
		const old = clone(this._state);
		this._state.publicToken = data.publicToken ?? this._state.publicToken;
		this._state.ipn = data.ipn ?? this._state.ipn;
		this._state.culture = data.culture ?? this._state.culture;
		this._state.termsConditionsUrl = data.termsConditionsUrl ?? this._state.termsConditionsUrl;
		this._state.privacyPolicyUrl = data.privacyPolicyUrl ?? this._state.privacyPolicyUrl;
		this._state.termsAccepted = data.termsAccepted ?? this._state.termsAccepted;
		this._state.numInstallments = data.numInstallments ?? this._state.numInstallments;
		this._state.amount = data.amount ?? this._state.amount;
		this._state.currencyCode = data.currencyCode ?? this._state.currencyCode;
		this._state.isSecure = data.isSecure ?? this._state.isSecure;
		this._state.isCommiting = data.isCommiting ?? this._state.isCommiting;
		this._state.demoMode = data.demoMode ?? this._state.demoMode;
		this._state.apiEnvironment = data.apiEnvironment ?? this._state.apiEnvironment;
		this._state.firstChargeDate = data.firstChargeDate ?? this._state.firstChargeDate;
		this._state.firstInstallmentAmount = data.firstInstallmentAmount ?? this._state.firstInstallmentAmount;
		this._state.currencyDecimalPlaces = data.currencyDecimalPlaces ?? this._state.currencyDecimalPlaces;
		this._state.is3ds2Supported = data.is3ds2Supported != null ? data.is3ds2Supported! : this._state.is3ds2Supported;
		this._state.processorName = data.processorName ?? this._state.processorName;
		this._state.refOrderNumber = data.refOrderNumber ?? this._state.refOrderNumber;
		this._state.errorRedirectUrl = data.errorRedirectUrl ?? this._state.errorRedirectUrl;
		this._state.successRedirectUrl = data.successRedirectUrl ?? this._state.successRedirectUrl;

		this.fireOnChange(old, true, [
			'publicToken',
			'ipn',
			'culture',
			'termsConditionsUrl',
			'privacyPolicyUrl',
			'termsAccepted',
			'numInstallments',
			'amount',
			'currencyCode',
			'isSecure',
			'isCommiting',
			'demoMode',
			'apiEnvironment',
			'firstChargeDate',
			'firstInstallmentAmount',
			'is3ds2Supported',
			'processorName',
			'refOrderNumber',
			'errorRedirectUrl',
			'successRedirectUrl'
		]);
	}

	private fireOnChange(old: ISplititState, notifyOtherFrames = true, changedProps?: Array<string>) {
		this._onChangeCallbacks.forEach((c) => c(old));

		if (notifyOtherFrames && this._useFrameManager) {
			frameManager.notify<SplititStateSyncPayload>(MessageTypes.STATE_SYNC, {
				state: this.get(),
				changedProps: changedProps
			});
		}
	}
}

currencyToSymbolMap['AUD'] = 'A$';
currencyToSymbolMap['CAD'] = 'C$';

export const state = new SplititState();
