import { EventFactory, IAdditionalInfo, ISplititError } from "splitit-utils";
import { config } from "./config";
import { getStylesUrl, VERSION } from "./constants";
import { ErrorCodes } from "./error-mapper";
import { frameManager, FrameManagerRoles, MessageTypes } from "./frame-manager";
import {
  CollectorCommitCompletePayload,
  CollectorIntroducePayload,
  FieldInitPayload,
  LocalizationPayload,
  TriggerCollectPayload,
} from "./frame-manager-payloads";
import { localizer } from "./localization-provider";
import { logger } from "./logger";
import {
  IErrorCallbackObject,
  IEventCallbackObject,
  IModal3dsIframeResult,
  ISuccessCallbackObject,
} from "./models/callback-contracts";
import {
  IErrorModel,
  IFieldDescriptorInfo,
  IUpdateDetailsData,
} from "./models/common-contracts";
import { IFlexFields, IFlexFieldsState } from "./models/core-contracts";
import { FlexFieldsUiStatus } from "./models/types";
import { InstallmentPicker } from "./picker/installment-picker";
import {
  appendStyles,
  hide3dModal,
  IModal3dsIframeParams,
  render3dModalIframe,
} from "./render-utils";
import { CallbackFactory } from "./services/callback-factory";
import { planStore } from "./services/plan-store";
import { shopperDetailsStore } from "./services/shopper-details-store";
import { DemoState, state } from "./state";
import { splititTrackerDecorator } from "./tracking/splitit-tracker-decorator";
import { ErrorBox } from "./ui-components/error-box";
import { FieldWrapper } from "./ui-components/flex-field";
import { PaymentButton } from "./ui-components/payment-button";
import { TermsConditionsLink } from "./ui-components/terms-link";
import { utils } from "./utils";

export class SplititFlexFields implements IFlexFields {
  private _createdTimestamp?: Date;
  private _status: FlexFieldsUiStatus;
  private _ready: boolean;
  private _showWhenReady: boolean;
  private _commonContainer: HTMLElement;
  private _collectorId: string;
  private _readyCallback: () => void;
  private _onSuccessCallback: (data: ISuccessCallbackObject) => void;
  private _on3dsCompleteCallback: (data: IModal3dsIframeResult) => void;
  private _onEventCallback: (
    data: IEventCallbackObject,
    state: IFlexFieldsState
  ) => void;
  private _fields: FieldWrapper[];
  private _installmentPicker: InstallmentPicker;
  private _termsConditionsLink: TermsConditionsLink;
  private _errorBox: ErrorBox;
  private _paymentButton: PaymentButton;
  private _isFormVisible: boolean;
  private _current3dModal?: HTMLElement;
  private _sessionId: string;

  public version: string = VERSION;

  constructor(sessionId: string) {
    try {
      splititTrackerDecorator.init({
        merchantUrl:
          location.hostname + (location.port ? ":" + location.port : ""),
        sessionId: sessionId,
        calculateVisitorId: true,
      });

      this._sessionId = sessionId;

      this._createdTimestamp = new Date();
      this._status = "created";
      this._ready = false;
      this._showWhenReady = false;
      this._fields = [];

      if (config.validate()) {
        this._commonContainer = document.querySelector(config.container);
        appendStyles(this._commonContainer, getStylesUrl());

        frameManager.init(FrameManagerRoles.HOST);
        state.reset();
        shopperDetailsStore.init();
        planStore.init();

        this.hideInternal();

        frameManager.subscribe(
          MessageTypes.COLLECTOR_INIT,
          this,
          this.collectorInit
        );
        frameManager.subscribe(
          MessageTypes.COLLECTOR_COMMIT_COMPLETE,
          this,
          this.collectorCommitComplete
        );

        state.setCulture(config.culture);
        state.setApiEnvironment(config.apiEnvironment);

        if (config.publicToken !== null && config.publicToken !== undefined) {
          state.setPublicToken(config.publicToken);
        }

        localizer.loadRemote((resources: { [key: string]: string }) => {
          if (this._ready) {
            frameManager.notify<LocalizationPayload>(
              MessageTypes.LOCALIZATION_LOADED,
              {
                resources: resources,
              }
            );
          }
        });

        this._initFieldFrames();

        this._installmentPicker = new InstallmentPicker();
        this._installmentPicker.init();

        if (!config.termsConditions.isCustomImplementation) {
          this._termsConditionsLink = new TermsConditionsLink();
          this._termsConditionsLink.render();
        }

        this._errorBox = new ErrorBox();

        if (config.paymentButton) {
          this._paymentButton = new PaymentButton();
          this._paymentButton.render();

          if (config.paymentButton.onClick) {
            config.paymentButton.onClick.bind(this);
          }

          this._paymentButton.onClick(() => {
            if (config.paymentButton.onClick) {
              config.paymentButton.onClick(this._paymentButton);
            } else {
              this.checkout();
            }
          });
        }

        state.onChange((old) => {
          const errorState = state.getActiveErrors(old);
          if (errorState.isChanged && errorState.hasErrors) {
            if (errorState.hasGeneralErrors) {
              const ev = EventFactory.GeneralErrorShown(
                errorState.errors.map(
                  (s) => <ISplititError>{ code: s.code, message: s.description }
                )
              );
              splititTrackerDecorator.sendEvent(ev);
            }
          }
        });
      } else {
        this._status = "error";
        logger.logCustomError("Invalid config", config);
      }
    } catch (e) {
      logger.logException(e);
    }
  }

  demo(demoState: DemoState) {
    state.setAmount(demoState.amount);
    state.setDemo(demoState);
    return this;
  }

  destroy() {
    logger.trace("Calling destroy()");

    try {
      if (this._installmentPicker) {
        this._installmentPicker.destroy();
      }

      if (this._termsConditionsLink) {
        this._termsConditionsLink.destroy();
      }

      if (this._errorBox) {
        this._errorBox.destroy();
      }

      if (this._paymentButton) {
        this._paymentButton.destroy();
      }

      this._fields.forEach((f) => {
        f.destroy();
      });

      state.reset();
    } catch (e) {
      logger.logException(e);
    }
  }

  ready(callback: () => void): IFlexFields {
    if (this._ready) {
      try {
        callback.bind(this);
        callback();
      } catch (e) {
        logger.logException(e);
      }
    } else {
      this._readyCallback = callback;
      this._readyCallback.bind(this);
    }

    return this;
  }

  onSuccess(callback: (data: ISuccessCallbackObject) => void): IFlexFields {
    this._onSuccessCallback = callback;
    this._onSuccessCallback.bind(this);
    return this;
  }

  onError(callback: (data: IErrorCallbackObject) => void): IFlexFields {
    callback.bind(this);

    state.onChange((old) => {
      try {
        const errorState = state.getActiveErrors(old);
        if (errorState.isChanged) {
          callback(
            CallbackFactory.error(
              errorState.errors,
              errorState.errors.filter((e) => e.source == "3ds").length > 0
            )
          );
        }
      } catch (e) {
        logger.logException(e);
      }
    });
    return this;
  }

  onEvent(
    callback: (data: IEventCallbackObject, state: IFlexFieldsState) => void
  ): IFlexFields {
    callback.bind(this);
    this._onEventCallback = callback;

    state.onChange((old) => {
      try {
        const newState = state.get();
        const publicState = state.getPublicState(newState);

        if (old.numInstallments != newState.numInstallments) {
          callback(
            <IEventCallbackObject>{
              evType: "value",
              component: "picker",
              newValue: newState.numInstallments,
              oldValue: old.numInstallments,
            },
            publicState
          );
        }

        if (old.termsAccepted != newState.termsAccepted) {
          callback(
            <IEventCallbackObject>{
              evType: "value",
              component: "terms-conditions-checkbox",
              newValue: newState.termsAccepted,
              oldValue: old.termsAccepted,
            },
            publicState
          );
        }

        newState.fields.forEach((f) => {
          const prevData = old.fields.filter((x) => x.type == f.type);
          if (!prevData || prevData.length == 0) {
            if (f.isFocused === true || f.isFocused === false) {
              callback(
                <IEventCallbackObject>{
                  evType: "focus",
                  component: f.type,
                  newValue: f.isFocused,
                },
                publicState
              );
            }

            if (f.isValid === true || f.isValid === false) {
              callback(
                <IEventCallbackObject>{
                  evType: "validation",
                  component: f.type,
                  newValue: f.isValid,
                },
                publicState
              );
            }
          } else {
            if (
              prevData[0].isFocused != f.isFocused &&
              (f.isFocused === true || f.isFocused === false)
            ) {
              callback(
                <IEventCallbackObject>{
                  evType: "focus",
                  component: f.type,
                  newValue: f.isFocused,
                  oldValue: prevData[0].isFocused,
                },
                publicState
              );
            }
            if (
              prevData[0].isValid != f.isValid &&
              (f.isValid === true || f.isValid === false)
            ) {
              callback(
                <IEventCallbackObject>{
                  evType: "validation",
                  component: f.type,
                  newValue: f.isValid,
                  oldValue: prevData[0].isValid,
                },
                publicState
              );
            }
          }

          if (f.type == "number" && f.cardType !== undefined) {
            if (
              !prevData ||
              prevData.length == 0 ||
              prevData[0].cardType != f.cardType
            ) {
              callback(
                <IEventCallbackObject>{
                  evType: "card",
                  component: f.type,
                  newValue: f.cardType,
                  oldValue:
                    prevData && prevData.length > 0
                      ? prevData[0].cardType
                      : null,
                },
                publicState
              );
            }
          }
        });
      } catch (e) {
        logger.logException(e);
      }
    });
    return this;
  }

  reportExternalError(msg: string, data?: any): void {
    logger.logCustomError(msg, data);
  }

  reportExternalException(ex: any): void {
    logger.logException(ex);
  }

  onFieldUpdate(callback: (fields: Array<IFieldDescriptorInfo>) => void) {
    callback.bind(this);

    state.onChange((old) => {
      try {
        const newState = state.get();
        if (
          old.fields.length != newState.fields.length ||
          JSON.stringify(old.fields) != JSON.stringify(newState.fields)
        ) {
          callback(newState.fields);
        }
      } catch (e) {
        logger.logException(e);
      }
    });
    return this;
  }

  on3dComplete(callback: (data: IModal3dsIframeResult) => void) {
    this._on3dsCompleteCallback = callback;
    this._on3dsCompleteCallback.bind(this);
    return this;
  }

  toggle() {
    if (this._isFormVisible) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    if (!this._ready) {
      this._showWhenReady = true;
      logger.logWarning(
        "Cannot invoke .show() method until plugin has loaded. It's handled and shown when ready, but for better UX, please call .show() on ready() callback."
      );
      return;
    }

    if (this._isFormVisible) {
      return;
    }

    try {
      if (!state.get().publicToken) {
        logger.logWarning(
          "Potential missconfiguration: Usually, when calling .show() publicToken should be specified. Either specify public token in setup function or call setPublicToken() explicitely. More info: http://flex-fields.production.splitit.com."
        );

        const stateListener = state.onChange((old) => {
          if (!old.publicToken && state.get().publicToken) {
            state.removeListener(stateListener);
            this.show();
          }
        });
      } else {
        this._isFormVisible = true;

        // Call GetInitiatedPlan...
        planStore.load();

        splititTrackerDecorator.sendEvent(
          EventFactory.FormShown(null, { flow: "recommended-flow" })
        );
        this._commonContainer.style.display = "block";
      }
    } catch (e) {
      logger.logException(e);
    }
  }

  hide() {
    if (!this._isFormVisible) {
      return;
    }

    this.hideInternal();
  }

  public synchronizePlan(): void {
    this._installmentPicker.resetForPlanSync();
    planStore.load();
  }

  public setPublicToken(token: string): void {
    state.setPublicToken(token);

    if (config.isDebugMode()) {
      console.log(`[splitit] FlexFields trace: Setting token to ${token}.`);
    }
  }

  public getState(): IFlexFieldsState {
    const result = state.getPublicState();
    result.loadStatus = this._status;
    return result;
  }

  public setNumInstallments(num: number): void {
    state.setNumInstallments(num);
  }

  public setTermsAccepted(value: boolean): void {
    if (!config.termsConditions.isCustomImplementation) {
      console.error(
        "[Splitit] Setting terms & conditions to accepted can only be called if it is custom implementation. Please contact your sales engineer."
      );
      alert("Invalid operation. Check console log for details.");
    } else {
      state.setTermsAccepted(value);
    }
  }

  checkout(): void {
    try {
      const errorsToSet = new Array<IErrorModel>();
      if (!state.get().termsAccepted) {
        errorsToSet.push(<IErrorModel>{
          code: ErrorCodes.TERMS_NOT_SELECTED,
          description: localizer.termsNotAccepted,
          source: "client_validation",
          showError: true,
          fieldTypes: [],
        });
      }

      if (!state.get().numInstallments) {
        errorsToSet.push(<IErrorModel>{
          code: ErrorCodes.INSTALLMENTS_NOT_SELECTED,
          description: localizer.numInstallmentsNotSelected,
          source: "client_validation",
          showError: true,
          fieldTypes: [],
        });
      }

      if (errorsToSet.length > 0) {
        state.setErrors(errorsToSet);
        return;
      }

      splititTrackerDecorator.sendEvent(EventFactory.PayButtonClick());
      frameManager.notify<TriggerCollectPayload>(MessageTypes.TRIGGER_COLLECT, {
        collectorId: this._collectorId,
        commitId: utils.guid(),
      });
    } catch (e) {
      logger.logException(e);
    }
  }

  redirect3ds(): void {
    try {
      this._current3dModal = render3dModalIframe(<IModal3dsIframeParams>{
        publicToken: state.get().publicToken,
        logoUrl: config.custom3dsLogoUrl,
        onComplete: (result) => this.process3dIframeResult(result),
        onUserClosed: () => {
          if (this._onEventCallback) {
            const publicState = state.getPublicState();

            this._onEventCallback(
              <IEventCallbackObject>{
                evType: "change",
                component: "modal3ds",
                newValue: "closed",
                oldValue: "open",
              },
              publicState
            );
          }
        },
      });
    } catch (e) {
      logger.logException(e);
    }
  }

  private process3dIframeResult(result3d: IModal3dsIframeResult) {
    if (!this._onSuccessCallback) {
      logger.logCustomError(
        "3D flow not implemented correctly. When using 3D in iframe, having an onSuccess callback is required."
      );
      return;
    }

    if (this._on3dsCompleteCallback) {
      this._on3dsCompleteCallback(result3d);
    }

    if (result3d.isSuccess) {
      this._onSuccessCallback(CallbackFactory.success(true));
    } else {
      state.setErrors([
        {
          description: localizer.secure3d_failed,
          code: "641-F",
          source: "3ds",
          fieldTypes: [],
          showError: true,
        },
      ]);
    }

    hide3dModal(this._current3dModal);
  }

  public updateDetails(
    data: IUpdateDetailsData,
    onSuccessCallback?: () => void
  ): void {
    if (data.consumerData || data.billingAddress || data.refOrderNumber) {
      if (onSuccessCallback) {
        onSuccessCallback.bind(this);
      }
      shopperDetailsStore.update(data, onSuccessCallback);
    }
  }

  private collectorInit(data: CollectorIntroducePayload) {
    try {
      this._collectorId = data._originId;
    } catch (e) {
      logger.logException(e);
    }
  }

  private collectorCommitComplete(data: CollectorCommitCompletePayload) {
    try {
      const cleanData = Object.assign({}, data);
      delete cleanData._originId;
      delete cleanData._messageType;

      if (data.is3ds) {
        this.redirect3ds();
      } else {
        if (this._onSuccessCallback) {
          this._onSuccessCallback(CallbackFactory.success(false));
        }
      }
    } catch (e) {
      logger.logException(e);
    }
  }

  private addSandboxWarningIfNeeded() {
    if (state.get().apiEnvironment !== "production") {
      const sandboxDiv = document.createElement("div");
      sandboxDiv.className = "splitit-warning-sandbox";
      sandboxDiv.innerText = `Note: running with non-production (${
        state.get().apiEnvironment
      }) API.`;

      if (this._commonContainer.hasChildNodes()) {
        this._commonContainer.insertBefore(
          sandboxDiv,
          this._commonContainer.firstChild
        );
      } else {
        this._commonContainer.append(sandboxDiv);
      }
    }
  }

  private _initFieldFrames() {
    try {
      frameManager.subscribe<FieldInitPayload>(
        MessageTypes.FIELD_INIT,
        this,
        (data: FieldInitPayload) => {
          try {
            this._fields.forEach((f) => {
              if (f.type == data.fieldType) {
                f.init(data);
              }
            });

            if (this._fields.every((f) => f.isInitialized)) {
              frameManager.notify<CollectorIntroducePayload>(
                MessageTypes.INTRODUCE_COLLECTOR,
                {}
              );
              this._ready = true;
              this._status = "ready";

              this.addSandboxWarningIfNeeded();

              const loadCompleteEvent = EventFactory.LoadComplete();
              loadCompleteEvent.additionalInfo = <IAdditionalInfo>{
                config: config,
                readyAt: new Date(),
                createdAt: this._createdTimestamp,
              };
              splititTrackerDecorator.sendEvent(loadCompleteEvent);

              if (localizer.remoteResources) {
                frameManager.notify<LocalizationPayload>(
                  MessageTypes.LOCALIZATION_LOADED,
                  {
                    resources: localizer.remoteResources,
                  }
                );
              }

              logger.logWarning(
                `FlexFields v${this.version} status: ${
                  this.getState().loadStatus
                }`
              );

              this.updateDetails(<IUpdateDetailsData>{
                consumerData: config.consumerData,
                billingAddress: config.billingAddress,
              });

              if (this._showWhenReady) {
                this.show();
              }

              if (this._readyCallback) {
                this._readyCallback();
              }
            }
          } catch (e) {
            logger.logException(e);
          }
        }
      );

      this._fields = [];

      if (config.fields.number) {
        this._fields.push(
          new FieldWrapper(
            config.fields.number.selector,
            "number",
            config.fields.number,
            config.whiteLabel
          )
        );
      }

      if (config.fields.cardholderName) {
        this._fields.push(
          new FieldWrapper(
            config.fields.cardholderName.selector,
            "cardholder-name",
            config.fields.cardholderName,
            config.whiteLabel
          )
        );
      }

      if (config.fields.cvv) {
        this._fields.push(
          new FieldWrapper(
            config.fields.cvv.selector,
            "cvv",
            config.fields.cvv,
            config.whiteLabel
          )
        );
      }

      if (config.fields.expirationDate) {
        this._fields.push(
          new FieldWrapper(
            config.fields.expirationDate.selector,
            "expiration-date",
            config.fields.expirationDate,
            config.whiteLabel
          )
        );
      }

      if (config.fields.expirationMonth) {
        this._fields.push(
          new FieldWrapper(
            config.fields.expirationMonth.selector,
            "expiration-month",
            config.fields.expirationMonth,
            config.whiteLabel
          )
        );
      }

      if (config.fields.expirationYear) {
        this._fields.push(
          new FieldWrapper(
            config.fields.expirationYear.selector,
            "expiration-year",
            config.fields.expirationYear,
            config.whiteLabel
          )
        );
      }

      for (let i = 0; i < this._fields.length; i++) {
        this._fields[i].render(this._sessionId);
      }
    } catch (e) {
      logger.logException(e);
    }
  }

  private hideInternal() {
    this._isFormVisible = false;

    if (this._commonContainer) {
      this._commonContainer.style.display = "none";
    }
  }
}
