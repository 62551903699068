import { ApiEnvironmentType } from "./models/config-contracts";

export const FLEX_FIELDS_URL = process.env.__URL__;
export const FLEX_FIELDS_ENV = process.env.__ENV__ as ApiEnvironmentType;
export const DATA_TRACKING_ENDPOINT = process.env.__DATA_TRACKING_ENDPOINT__;
export const GA_UA_ID = process.env.__GA_UA_ID__;
export const VERSION = process.env.__VERSION__;
export const MAJOR_VERSION = process.env.__MAJOR_VERSION__;
export const FLEX_FIELDS_HELP_URL = FLEX_FIELDS_URL + "index.html";
export const TRANSLATION_URL = process.env.__TRANSLATION_URL__;
export const API_URL = process.env.__API_URL__;

export function getStylesUrl() {
  return FLEX_FIELDS_URL + "css/splitit.flex-fields.css";
}
