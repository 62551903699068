import { EventFactory, LearnMorePopupBuilder, POPUP_EVENTS } from 'splitit-utils';
import { config } from '../config';
import { FLEX_FIELDS_ENV } from '../constants';
import { localizer } from '../localization-provider';
import { planStore } from '../services/plan-store';
import { state } from '../state';
import { splititTrackerDecorator } from '../tracking/splitit-tracker-decorator';

export class HowSplititWorksLink {
	private _link: HTMLAnchorElement;

	constructor(additionalClass?: string) {
		this._link = document.createElement('a');

		if (additionalClass) {
			this._link.classList.add(additionalClass);
		}
	}

	public hide() {
		this._link.remove();
	}

	public destroy() {
		this._link.remove();
	}

	public addTo(container: HTMLElement, position?: number) {
		this._link.classList.add('splitit-ps-h-link');
		this._link.classList.add('splitit-ps-h-link-how-works');
		this._link.classList.add('float-right');
		this._link.attributes['href'] = 'javascript:void(0);';
		this._link.text = config.whiteLabel ? localizer.howSplititWorksWhiteLabel : localizer.howSplititWorks;
		localizer.register(() => {
			this._link.text = config.whiteLabel ? localizer.howSplititWorksWhiteLabel : localizer.howSplititWorks;
		});

		if (position !== null && position !== undefined) {
			container.insertBefore(this._link, container.children[position]);
		} else {
			container.appendChild(this._link);
		}

		this._link.onclick = (ev) => {
			splititTrackerDecorator.sendEvent(EventFactory.OpenLearnMore());

			const currentState = state.get();
			const LearnMoreLink = planStore.getLearnMoreLink();

			const searchParams = new URLSearchParams(LearnMoreLink.split('?')[1]);

			const availableInstallments = currentState.installmentOptions;
			const minNumInstallments = availableInstallments[0];
			const maxNumInstallments = availableInstallments[availableInstallments.length - 1];
			const midNumInstallments =
				maxNumInstallments === +currentState.numInstallments || minNumInstallments === +currentState.numInstallments
					? availableInstallments[Math.floor(availableInstallments.length / 2)]
					: null;

			const builder = new LearnMorePopupBuilder({
				docType: searchParams.get('documentType'),
				ui: searchParams.get('ui'),
				initiator: searchParams.get('initiator'),
				env: FLEX_FIELDS_ENV == 'local' ? 'sandbox' : FLEX_FIELDS_ENV,
				publicToken: currentState.publicToken,
				numInstallments: currentState.numInstallments,
				culture: currentState.culture,
				amount: currentState.amount,
				ipn: currentState.ipn,
				minNumInstallments,
				maxNumInstallments,
				midNumInstallments
			});

			const popup = builder.build();
			popup.addEventListener((popupEvent) => {
				if (popupEvent == POPUP_EVENTS.CLOSED) {
					splititTrackerDecorator.sendEvent(EventFactory.CloseDialog());
				}
			});
			popup.show();
		};
	}
}
