import { InstallmentPickerRenderer } from "./base";
import { localizer } from "../../localization-provider";
import { template } from "./dropdown.mobile-template";

export class InstallmentPickerDropdownRendererMobile extends InstallmentPickerRenderer {
  public render(container: HTMLElement) {
    container.classList.add("splitit-installment-picker-dropdown");

    const selectOptions = this.options.map((o) => {
      return {
        numPayments: o,
        numPaymentsStr: localizer.numPayments(o),
        amountPerMonth: this.getPaymentCalculationText(o),
      };
    });

    container.innerHTML = template({ options: selectOptions });

    const selectElement = container.querySelector(
      "select"
    ) as HTMLSelectElement;
    if (!selectElement) {
      throw new Error(
        "Could not find select element, please check the template"
      );
    }
    selectElement.onchange = () => {
      this.setSelectedValue(this.options[selectElement.selectedIndex]);
    };

    if (this.initialSelection) {
      selectElement.selectedIndex = this.options.findIndex(
        (val) => val == this.initialSelection
      );
    } else {
      selectElement.selectedIndex = Math.trunc(this.options.length / 2);
    }

    this.setSelectedValue(this.options[selectElement.selectedIndex]);
  }

  protected getPickerType(): string {
    return "dropdown-mobile";
  }
}
