import { EventFactory, GenericPopupBuilder, POPUP_EVENTS } from 'splitit-utils';
import { config } from '../config';
import { VERSION } from '../constants';
import { localizer } from '../localization-provider';
import { state } from '../state';
import { splititTrackerDecorator } from '../tracking/splitit-tracker-decorator';
import { utils } from '../utils';

export class TermsConditionsLink {
	private _container: HTMLElement;
	private _termsLink: HTMLAnchorElement;
	private _privacyLink: HTMLAnchorElement;
	private _checkbox: HTMLInputElement;

	constructor() {
		this._container = document.querySelector(config.termsConditions.selector) as HTMLElement;
		this._container.classList.add('splitit-terms-conditions');
		if (config.whiteLabel) {
			this._container.classList.add('splitit-flex-field-generic');
		}
		utils.recordFirstInteraction(this._container);

		this._termsLink = null;
		this._privacyLink = null;
	}

	public destroy() {
		this._container.innerHTML = '';
	}

	public render() {
		const id = 'l-' + utils.guid();

		this._checkbox = document.createElement('input');
		this._checkbox.type = 'checkbox';
		this._checkbox.id = id;
		this._checkbox.disabled = true;
		this._checkbox.onchange = () => {
			state.setTermsAccepted(this._checkbox.checked);

			const ev = this._checkbox.checked ? EventFactory.TermsAccepted() : EventFactory.TermsDeclined();

			splititTrackerDecorator.sendEvent(ev);
		};
		const credit_card_terms = document.createElement('p');
		credit_card_terms.classList.add('splitit-credit-card-terms');
		config.whiteLabel && this._container.appendChild(credit_card_terms);

		this._container.appendChild(this._checkbox);
		const lbl = document.createElement('label');
		lbl.htmlFor = id;
		localizer.register(() => {
			lbl.innerHTML = config.whiteLabel ? localizer.termsConditionsHtmlWhiteLabel : localizer.termsConditionsHtml;
			this.initializeLinks(lbl);
			credit_card_terms.innerText = localizer.credit_card_terms;
			this.updateFromState();
		});

		this._container.appendChild(lbl);

		this.initializeLinks(lbl);

		// Automatically uncheck T&C if num installments changed - removed per request by Yan on Feb 25.
		state.onChange((old) => {
			if (
				old.numInstallments != state.get().numInstallments ||
				(!old.termsConditionsUrl && state.get().termsConditionsUrl) ||
				(!old.privacyPolicyUrl && state.get().privacyPolicyUrl)
			) {
				this.updateFromState();
			}

			if (state.get().demoMode && old.demoMode != state.get().demoMode) {
				this.updateFromState();
			}
		});

		this.updateFromState();
	}

	private openPopup(type: 'tnc' | 'pp', url?: string, numInstallments?: number) {
		if (!url) {
			return;
		}

		const builder = new GenericPopupBuilder({
			url: url,
			urlParams: {
				numInstallments,
				popup: true
			},
			version: VERSION
		});

		const popup = builder.build();
		popup.addEventListener((callbackEv) => {
			if (callbackEv == POPUP_EVENTS.CLOSED) {
				if (type == 'tnc') {
					splititTrackerDecorator.sendEvent(EventFactory.CloseDialog(null, 'T&C popup'));
				} else {
					splititTrackerDecorator.sendEvent(EventFactory.CloseDialog(null, 'PP popup'));
				}
			}
		});
		popup.show();
	}

	private initializeLinks(lbl: HTMLLabelElement) {
		this._termsLink = lbl.querySelector('a.splitit-tc-t') as HTMLAnchorElement;
		this._termsLink.onclick = (ev) => {
			splititTrackerDecorator.sendEvent(EventFactory.OpenTermsConditions());
			this.openPopup('tnc', state.get().termsConditionsUrl, state.get().numInstallments);
			// Propagate only if not accepted
			return !state.get().termsAccepted;
		};

		this._privacyLink = lbl.querySelector('a.splitit-tc-p') as HTMLAnchorElement;
		this._privacyLink.onclick = (ev) => {
			splititTrackerDecorator.sendEvent(EventFactory.OpenPrivacyPolicy());
			this.openPopup('pp', state.get().privacyPolicyUrl, state.get().numInstallments);
			// Propagate only if not accepted
			return !state.get().termsAccepted;
		};
	}

	private updateFromState() {
		if (state.get().demoMode) {
			this._checkbox.disabled = false;
		} else if (!state.get().termsConditionsUrl || !state.get().privacyPolicyUrl) {
			this._checkbox.disabled = true;
			this._checkbox.checked = false;
		} else {
			this._checkbox.disabled = false;
		}
	}
}
