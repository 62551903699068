import { EventFactory } from "splitit-utils";
import { DEFAULT_FLAG } from "../constants-ts";
import {
  frameManager,
  FrameManagerRoles,
  MessageTypes,
} from "../frame-manager";
import {
  AutofillPayload,
  CollectCardDataPayload,
  CollectorIntroducePayload,
  FieldInitPayload,
  OverrideFieldStylePayload,
  TriggerCollectPayload,
} from "../frame-manager-payloads";
import { localizer } from "../localization-provider";
import { logger } from "../logger";
import { FieldType } from "../models/types";
import { state } from "../state";
import { splititTrackerDecorator } from "../tracking/splitit-tracker-decorator";
import { utils } from "../utils";

export class FlexField {
  protected _value: string;
  protected _clientError?: string;
  protected _isFocused: boolean;
  protected _isValid: boolean;
  protected _type: FieldType;
  protected _fieldSelector: string;
  protected _guid: string;
  protected _trackBlur: boolean;
  private _collectorId: string;
  private _isDisabled: boolean;
  protected _uiMode?: string;
  protected _everFocused: boolean;
  protected _everNonEmpty: boolean;
  protected _inputElement: HTMLInputElement;

  constructor(guid: string, uiMode?: string, initFrameManager = true) {
    this._trackBlur = true;
    this._guid = guid;
    this._value = null;
    this._isValid = null;
    this._isFocused = false;
    this._everFocused = false;
    this._everNonEmpty = false;
    this._isDisabled = false;
    this._uiMode = uiMode;

    if (initFrameManager) {
      frameManager.init(FrameManagerRoles.FIELD, guid);
      localizer.subscribeLocalizationLoaded();
      state.reset();
    }

    frameManager.subscribe(
      MessageTypes.INTRODUCE_COLLECTOR,
      this,
      this.introduceCollector
    );
    frameManager.subscribe(
      MessageTypes.FIELD_AUTOFILL,
      this,
      this.autofillNotification
    );
    frameManager.subscribe(
      MessageTypes.FIELD_STYLE_OVERRIDE,
      this,
      this.overrideStyle
    );
    frameManager.subscribe(
      MessageTypes.TRIGGER_COLLECT,
      this,
      (data: TriggerCollectPayload) => {
        // Make sure we have simulated that field was focused so red border will be shown.
        this._everFocused = true;
        // When data collection is requested, update field validity from state to avoid overwrite with null.
        this._isValid = state.getField(this._type).isValid;

        if (
          this._type == "expiration-month" ||
          this._type == "expiration-year"
        ) {
          // Mitigate problem that if year is updated maybe month becomes valid, or vice-versa.
          this._isValid = null;
          this._clientError = null;
        }

        this.sendToCollector({ commitId: data.commitId });
      }
    );
  }

  protected autofillNotification(data: AutofillPayload) {
    if (data.fieldType == this._type) {
      this.injectValue(data.value);
    }
  }

  protected injectValue(rawValue: string) {
    this._inputElement.value = rawValue;
    this._value = rawValue;
  }

  protected formatCollectPayload(
    payload: CollectCardDataPayload
  ): CollectCardDataPayload {
    return payload;
  }

  protected validate() {
    this._isValid = false;
  }

  public init(fieldSelector: string) {
    this._fieldSelector = fieldSelector;
    this._inputElement = document.querySelector(fieldSelector);

    if (this._uiMode && this._uiMode.length > 0) {
      this._inputElement.classList.add(this._uiMode);
    }

    localizer.register(() => {
      this._inputElement.setAttribute(
        "placeholder",
        localizer.getPlaceholderForField(this._type, false, this._uiMode)
      );
    });

    frameManager.notify<FieldInitPayload>(MessageTypes.FIELD_INIT, {
      fieldType: this._type,
    });

    state.onChange((old) => {
      try {
        if (old.isCommiting != state.get().isCommiting) {
          this._isDisabled = state.get().isCommiting;
          this._inputElement.disabled = this._isDisabled;
        }

        const stateInfo = state.getField(this._type);
        if (this._type != "number" && stateInfo && stateInfo.isValid != true) {
          // If someone fills exp. date before CC number, after CC number is validated as success,
          // we want to validate this field also
          const newNumberField = state.getField("number");

          if (newNumberField && newNumberField.isValid == true) {
            const oldNumberField = old.fields.filter(
              (f) => f.type == "number"
            )[0];
            if (oldNumberField.isValid != true) {
              // CC number field was validated
              this.sendToCollector({ requireServerValidation: true });
            }
          }
        }

        if (
          this._type == "number" &&
          stateInfo &&
          //(stateInfo.errors.length > 0 || !state.get().isSecure || state.get().errors.filter(e => utils.pickerErrorCodes.includes(e.errorCode)).length > 0) &&
          old.numInstallments != state.get().numInstallments
        ) {
          this.sendToCollector({ requireServerValidation: true });
        }
      } catch (e) {
        logger.logException(e);
      }
    });
  }

  public updateAutofillField(autoFillInput: HTMLInputElement) {
    const fieldType = <FieldType>autoFillInput.getAttribute("splitit-type");
    frameManager.notifySecure<AutofillPayload>(MessageTypes.FIELD_AUTOFILL, {
      fieldType: fieldType,
      value: autoFillInput.value,
    });
  }

  // Invoked from formfield.html
  public updateFocus(isFocused) {
    try {
      this._everFocused = this._everFocused || isFocused;

      this._inputElement.setAttribute(
        "placeholder",
        localizer.getPlaceholderForField(this._type, isFocused, this._uiMode)
      );

      if (this._isFocused != isFocused) {
        this._isFocused = isFocused;
      }

      if (!isFocused) {
        const errorsToSend = [];
        if (this._clientError && this._clientError.length > 0) {
          errorsToSend.push({
            message: this._clientError,
            isVisible: this.shouldShowValidationError(),
          });
        }
        splititTrackerDecorator.sendEvent(
          EventFactory.InputBlur(
            this._type,
            this.getExposableValue(),
            errorsToSend
          )
        );

        // Blur happened
        setTimeout(() => {
          // Give some time for any "change" event to fire in the meantime
          this.validate();
          this.sendToCollector({ requireServerValidation: true });
        }, 35);
      } else {
        this.sendToCollector();
        splititTrackerDecorator.sendEvent(
          EventFactory.InputFocus(this._type, this.getExposableValue())
        );
      }
    } catch (e) {
      logger.logException(e);
    }
  }

  // Invoked from formfield.html
  public updateChange(newValue: string) {
    this._value = newValue;
    if (this._value && this._value.length > 0) {
      this._everNonEmpty = true;
    }

    // Validation and everything handled by blur event
  }

  // Invoked from formfield.html
  public updateKeypress(newValue: string) {
    this._isValid = null;
    this._clientError = null;
    this._value = newValue;
  }

  protected getExposableValue() {
    if (this._value && this._value != "") {
      return "*".repeat(this._value.length);
    } else {
      return null;
    }
  }

  protected sendToCollector(p?: {
    requireServerValidation?: boolean;
    commitId?: string;
  }) {
    p = Object.assign({}, p);

    this.validate();

    let payload = new CollectCardDataPayload();
    payload.fieldType = this._type;
    payload.value = this._value;
    payload.isFocused = this._isFocused;
    payload.isValid = this._isValid;
    payload.showValidationError = this.shouldShowValidationError();
    payload.requireServerSideValidation = p.requireServerValidation;
    payload.clientError = this._clientError;
    payload.isDisabled = this._isDisabled;
    payload.commitId = p.commitId;

    payload = this.formatCollectPayload(payload);

    frameManager.notifySecure(
      MessageTypes.COLLECT_CARD_DATA,
      payload,
      this._collectorId
    );
  }

  protected shouldShowValidationError(): boolean {
    return !this._isFocused && this._everFocused && this._everNonEmpty;
  }

  private introduceCollector(data: CollectorIntroducePayload) {
    this._collectorId = data._originId;
  }

  private overrideStyle(data: OverrideFieldStylePayload) {
    if (data.style.fontFamily && data.style.fontFamily != "") {
      this._inputElement.style.fontFamily = data.style.fontFamily;
    }

    if (data.style.fontSize && data.style.fontSize != "") {
      this._inputElement.style.fontSize = data.style.fontSize;
    }

    if (data.style.textColor && data.style.textColor != "") {
      if (data.style.textColor == DEFAULT_FLAG) {
        this._inputElement.style.removeProperty("color");
      } else {
        this._inputElement.style.color = data.style.textColor;
      }
    }

    if (
      data.style.placeholderTextColor &&
      data.style.placeholderTextColor != ""
    ) {
      const keys = [
        "input::-webkit-input-placeholder",
        "input::-ms-input-placeholder",
        "input:-ms-input-placeholder",
        "input::-moz-placeholder",
        "input:-moz-placeholder",
        "input::placeholder",
      ];
      const escaped = data.style.placeholderTextColor.replace(
        // eslint-disable-next-line
        /[^a-zA-Z0-9\(\)#\.]/g,
        ""
      );

      const style = document.getElementById(
        "field-style-override"
      ) as HTMLStyleElement;
      const sheet = style.sheet as CSSStyleSheet;
      const rule = `color: ${escaped}`;

      keys.forEach((k) => {
        try {
          if (utils.isShitholeBrowser()) {
            sheet.addRule(k, rule);
          } else {
            sheet.insertRule(`${k}{${rule}}`);
          }
          // eslint-disable-next-line
        } catch (_) {}
      });
    }
  }
}
