import {
  ActiveData,
  EventFactory,
  ThreeDsTwoTokenizer
} from "splitit-utils";
import { ApiErrorWrapper } from "../api-error-wrapper";
import { ErrorMapper } from "../error-mapper";
import { CollectCardDataPayload } from "../frame-manager-payloads";
import { localizer } from "../localization-provider";
import { logger } from "../logger";
import { IErrorModel, IFieldDescriptorInfo } from "../models/common-contracts";
import { FieldType, PartialRecord } from "../models/types";
import { splititApiService } from "../services/splitit-api";
import { ISplititApiInitiatePlanRequest } from "../services/splitit-api-models";
import { state } from "../state";
import { splititTrackerDecorator } from "../tracking/splitit-tracker-decorator";
import { utils } from "../utils";

export class FieldValidationManager {
  private _originalField: IFieldDescriptorInfo;
  private _payload: CollectCardDataPayload;
  private _otherValues: PartialRecord<FieldType, string>;
  private _3ds2Tokenizer?: ThreeDsTwoTokenizer;

  constructor(
    originalField: IFieldDescriptorInfo,
    payload: CollectCardDataPayload,
    otherValues: PartialRecord<FieldType, string>,
    tokenizer3ds2: ThreeDsTwoTokenizer
  ) {
    this._originalField = originalField;
    this._payload = payload;
    this._otherValues = otherValues;
    this._3ds2Tokenizer = tokenizer3ds2;
  }

  isSeparateExpMonthConsideredInvalid() {
    if (
      this._originalField.type == "expiration-month" &&
      this._otherValues["expiration-year"] &&
      this._otherValues["expiration-year"].length == 2
    ) {
      const expirationMonth = +this._payload.value;
      const expirationYear = +this._otherValues["expiration-year"];

      if (isNaN(expirationMonth) || isNaN(expirationYear)) {
        return false;
      }

      const cyrrentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear() % 100;
      if (expirationYear < currentYear) {
        return false;
      }

      if (
        expirationYear * 100 + expirationMonth <
        currentYear * 100 + cyrrentMonth
      ) {
        return true;
      }
    }

    return false;
  }

  validate(
    callback: (
      result: IFieldDescriptorInfo,
      serverErrors?: IErrorModel[]
    ) => void
  ) {
    try {
      callback.bind(this);

      const result = {
        ...this._originalField,
        showValidationError: this._payload.showValidationError,
        isFocused: this._payload.isFocused,
        isValid: this._payload.isValid,
        hasContent: this._payload.value && this._payload.value.length > 0,
        cardType: this._payload.cardType,
        disabled: this._payload.isDisabled,
        errors: [],
      };

      if (this._payload.clientError) {
        result.errors.push(this._payload.clientError);
      }

      if (
        this._originalField.type == "expiration-month" &&
        this._payload.isValid != false &&
        this.isSeparateExpMonthConsideredInvalid()
      ) {
        result.isValid = false;
        result.errors.push(localizer.invalidExpirationDate);
      }

      let requireServerSideValidation =
        this._payload.requireServerSideValidation;
      if (result.isValid === false) {
        requireServerSideValidation = false;
      }

      if (this._payload.commitId) {
        requireServerSideValidation = false;
      }

      const numberField = state.getField("number");
      if (this._originalField.type != "number") {
        if (numberField.isValid === false) {
          requireServerSideValidation = false;
        }
        if (!this._otherValues.number || this._otherValues.number == "") {
          requireServerSideValidation = false;
        }
      }

      if (!state.get().ipn || !state.get().publicToken) {
        requireServerSideValidation = false;
      }

      if (!requireServerSideValidation) {
        callback(result);
      } else {
        // Perform server side validation...
        const cardData = utils.parseCardData(this._otherValues);

        const requestData: ISplititApiInitiatePlanRequest = {
          CreditCardDetails: cardData,
          InstallmentPlanNumber: state.get().ipn,
          PlanData: {
            NumberOfInstallments: state.get().numInstallments,
          },
        };

        splititApiService
          .initiatePlan(requestData)
          .then((serverResult) => {
            if (serverResult.InstallmentPlan.Strategy) {
              state.setStrategy(serverResult.InstallmentPlan.Strategy);
            }

            result.isValid = true;

            if (
              requestData.PlanData.NumberOfInstallments &&
              requestData.CreditCardDetails &&
              requestData.CreditCardDetails.CardNumber &&
              requestData.CreditCardDetails.CardNumber.length > 1 &&
              requestData.CreditCardDetails.CardExpMonth &&
              requestData.CreditCardDetails.CardExpMonth.length > 1 &&
              requestData.CreditCardDetails.CardExpYear &&
              requestData.CreditCardDetails.CardExpYear.length > 1 &&
              requestData.CreditCardDetails.CardCvv &&
              requestData.CreditCardDetails.CardCvv.length > 1
            ) {
              splititTrackerDecorator.sendEvent(
                EventFactory.ServerCardValidationSuccess()
              );
            }

            if (this._3ds2Tokenizer) {
              const data = {
                Amount: {
                  CurrencyCode:
                    serverResult.InstallmentPlan.Amount.Currency?.Code,
                  Value: serverResult.InstallmentPlan.Amount.Value,
                  Currency: {
                    ...(serverResult.InstallmentPlan.Amount.Currency || {}),
                  },
                },
                Bin: serverResult.InstallmentPlan.ActiveCard?.Bin,
                CardCvv: requestData.CreditCardDetails.CardCvv,
                CardExpMonth: requestData.CreditCardDetails.CardExpMonth,
                CardExpYear: requestData.CreditCardDetails.CardExpYear,
                CardNumber: requestData.CreditCardDetails.CardNumber,
              } as ActiveData;

              this._3ds2Tokenizer.GetFingerPrint(data);
            }

            callback(result, []);
          })
          .catch((err) => {
            logger.trace(err);
            result.isValid = null;

            const error = ApiErrorWrapper.parse(err, "server_validation");

            if (
              this._originalField.type == "expiration-month" &&
              !this.isSeparateExpMonthConsideredInvalid()
            ) {
              result.isValid = null;
            } else {
              if (
                error.errorList.filter((e) =>
                  ErrorMapper.getErrorCodesForField(
                    <FieldType>result.type
                  ).includes(e.code)
                ).length > 0
              ) {
                result.isValid = false;
              } else {
                result.isValid = true;
              }
            }

            callback(result, error.errorList);
          });
      }
    } catch (e) {
      logger.logException(e);
    }
  }
}
