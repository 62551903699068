export const template = (props) => {
  const { options } = props;
  return ` <div class="splitit-ip-s">

	<div class="splitit-ip-s-header">
		<span class="splitit-ip-s-header-amount"></span>
		<span class="splitit-ip-s-header-num-payments"></span>
		<span class="splitit-ip-s-header-amount-now"></span>
	</div>
	<div class="splitit-ip-s-line-click-area"></div>
    <div class="splitit-ip-s-line">
		<div class="splitit-ip-s-line-progress"></div>
		<div class="splitit-ip-s-line-handle"></div>
	</div>
	<div class="splitit-ip-s-values">
	${options
    .map((o) => {
      return `<div class="splitit-ip-s-values-item" style="width: ${o.width}%;">
		${o.numPayments}
	</div>`;
    })
    .join("")}
			
		<div style="clear: both;"></div>
	</div>
</div>`;
};
