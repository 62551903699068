import { InstallmentPickerRenderer } from "./base";
import { localizer } from "../../localization-provider";
import { config } from "../../config";
import { utils } from "../../utils";
import { state } from "../../state";
import { splititTrackerDecorator } from "../../tracking/splitit-tracker-decorator";
import { EventFactory } from "splitit-utils";
import { template } from "./dropdown.desktop-template";

export class InstallmentPickerDropdownRenderer extends InstallmentPickerRenderer {
  private _selectOptions: { numPayments: string; amountPerMonth: string }[];
  private _selectorTextRight: HTMLElement;
  private _selectorTextLeft: HTMLElement;

  protected setSelectedValue(newValue) {
    super.setSelectedValue(newValue);

    this._selectorTextLeft.innerText = localizer.numPayments(newValue);
    this._selectorTextRight.innerText =
      this.getPaymentCalculationText(newValue);
  }

  public render(container: HTMLElement) {
    container.classList.add("splitit-installment-picker-dropdown");

    this._selectOptions = this.options.map((o) => {
      return {
        numPayments: localizer.numPayments(o),
        amountPerMonth: this.getPaymentCalculationText(o),
      };
    });

    container.innerHTML = template({ options: this._selectOptions });

    const chooserElement = container.querySelector(
      ".splitit-ip-d-desktop-chooser"
    ) as HTMLElement;
    if (!chooserElement) {
      throw new Error(
        `Could not find an element with splitit-ip-d-desktop-chooser in its classes, please check the template`
      );
    }
    window.addEventListener("mouseup", () => {
      chooserElement.style.display = "none";
      splititTrackerDecorator.sendEvent(
        EventFactory.PickerDropdownOpen(null, null, { action: "close" })
      );
    });
    const selector = "splitit-ip-d-desktop-sel";
    const selectorButton = container.querySelector(
      `.${selector}`
    ) as HTMLElement;
    if (!selectorButton) {
      throw new Error(
        `Could not find an element with ${selector} in its classes, please check the template`
      );
    }
    selectorButton.onmouseup = (ev) => {
      ev.stopPropagation();
      ev.preventDefault();
      chooserElement.style.display = "block";
      splititTrackerDecorator.sendEvent(
        EventFactory.PickerDropdownOpen(null, null, { action: "open" })
      );
    };
    this._selectorTextLeft = container.querySelector(
      ".splitit-ip-d-desktop-sel-text-left"
    ) as HTMLElement;
    this._selectorTextRight = container.querySelector(
      ".splitit-ip-d-desktop-sel-text-right"
    ) as HTMLElement;

    chooserElement
      .querySelectorAll(".splitit-ip-d-desktop-chooser-item")
      .forEach((el: HTMLElement, idx) => {
        el.onclick = () => {
          this.setSelectedValue(this.options[idx]);
        };
      });

    this.setSelectedValue(
      this.initialSelection ?? this.options[Math.trunc(this.options.length / 2)]
    );
  }

  protected getPickerType(): string {
    return "dropdown-desktop";
  }
}
