import { template } from './ui-components/auto-align-classic-template';
interface RenderedElement {
	selector: string;
}
interface InputField {
	style: string;
	selector?: string;
}
interface Fields {
	cardholderName: InputField;
	number: InputField;
	cvv: InputField;
	expirationDate: InputField;
	style: string;
}

interface InstalmentPicker extends RenderedElement {
	ui: string;
	selector: string;
}

interface FlexfieldsOptions {
	container: string;
	termsConditions?: RenderedElement;
	fields: Fields;
	installmentPicker: InstalmentPicker;
	errorBox: RenderedElement;
	paymentButton: RenderedElement;
	whiteLabel: boolean;
}

export class AutoArrangeManager {
	public static apply(options: FlexfieldsOptions) {
		const container = document.querySelector(options.container) as HTMLElement;
		if (!container) {
			throw new Error(`Container ${options.container} not found`);
		}

		if (!container.hasAttribute('data-splitit-auto-align') && options.whiteLabel) {
			container.setAttribute('data-splitit-auto-align', 'standard');
		}
		if (container.hasAttribute('data-splitit-auto-align')) {
			const autoAlignConfig = container.getAttribute('data-splitit-auto-align');
			const includeCardholderName = autoAlignConfig == 'standard';

			container.innerHTML = template({
				addCardholderName: includeCardholderName,
				whiteLabel: options.whiteLabel,
				cardId: options.fields?.number?.selector?.replace('#', ''),
				cvvId: options.fields?.cvv?.selector?.replace('#', ''),
				expId: options.fields?.expirationDate?.selector?.replace('#', ''),
				nameId: options.fields?.cardholderName?.selector?.replace('#', ''),
				installId: options.installmentPicker?.selector?.replace('#', ''),
				termsId: options.termsConditions?.selector?.replace('#', ''),
				errId: options.errorBox?.selector?.replace('#', ''),
				payId: options.paymentButton?.selector?.replace('#', '')
			});

			const fieldsSection = {
				number: {
					selector: options.fields?.number?.selector || '#splitit-card-number',
					style: options.fields?.number?.style
				},
				cvv: {
					selector: options.fields?.cvv?.selector || '#splitit-cvv',
					style: options.fields?.cvv?.style
				},
				expirationDate: {
					selector: options.fields?.expirationDate?.selector || '#splitit-expiration-date',
					style: options.fields?.expirationDate?.style
				},
				style: options.fields?.style,
				cardholderName: undefined
			};

			if (includeCardholderName) {
				fieldsSection.cardholderName = {
					selector: options.fields?.cardholderName?.selector || '#splitit-cardholder-name',
					style: options.fields?.cardholderName?.style
				};
			}

			options = {
				...options,
				fields: fieldsSection,
				installmentPicker: {
					selector: options.installmentPicker?.selector || '#splitit-installment-picker',
					ui: options.installmentPicker?.ui
				},
				termsConditions: {
					selector: options.termsConditions?.selector || '#splitit-terms-conditions'
				},
				errorBox: {
					selector: options.errorBox?.selector || '#splitit-error-box'
				},
				paymentButton: {
					selector: options.paymentButton?.selector || '#splitit-btn-pay'
				}
			};
		}
	}
}
