import { InstallmentPickerRenderer } from "./base";
import { utils } from "../../utils";
import { state } from "../../state";
import { template } from "./slider-template";

export class InstallmentPickerSliderRenderer extends InstallmentPickerRenderer {
  private _sliderStops: number[];
  private _sliderValuesWrapper: HTMLElement;
  private get slider(): HTMLElement | null {
    return this._container.querySelector<HTMLElement>(".splitit-ip-s-line");
  }
  private _isMoving: boolean;
  private _handlePosition: number | null;
  private get sliderProgress(): HTMLElement | null {
    return this._container.querySelector<HTMLElement>(
      ".splitit-ip-s-line-progress"
    );
  }
  private _skipPoint: number;
  private get sliderHandle(): HTMLElement | null {
    return this._container.querySelector(".splitit-ip-s-line-handle");
  }

  private get amountSpan(): HTMLElement | null {
    return this._container.querySelector(".splitit-ip-s-header-amount");
  }

  private get nowSpan(): HTMLElement | null {
    return this._container.querySelector<HTMLElement>(
      ".splitit-ip-s-header-amount-now"
    );
  }

  private get numPaymentsSpan(): HTMLElement | null {
    return this._container.querySelector(".splitit-ip-s-header-num-payments");
  }

  private _tmpSelectedValue: number;

  private _container: HTMLElement;

  private setSliderValue(newValue) {
    this._tmpSelectedValue = newValue;

    const paymentCalc = this.getPaymentCalculation(newValue);
    const valueStr = utils.formatDecimalToLocale(
      paymentCalc.monthly,
      state.getCurrencyDecimalPlaces()
    );

    if (paymentCalc.amountNow > 0) {
      this.nowSpan!.innerText = `+ ${utils.formatCurrency(
        paymentCalc.amountNow,
        state.getCurrencySymbol(),
        state.getCurrencyDecimalPlaces()
      )}`;
    } else {
      this.nowSpan!.innerText = "";
    }

    this.amountSpan!.innerText = `${state.getCurrencySymbol()}${valueStr}`;
    this.numPaymentsSpan!.innerText =
      paymentCalc.amountNow > 0 ? `x${newValue - 1}` : `x${newValue}`;

    this._sliderValuesWrapper
      .querySelectorAll(".splitit-ip-s-values-item")
      .forEach((el, idx) => {
        if (this.options[idx] == newValue) {
          el.classList.add("selected");
        } else {
          el.classList.remove("selected");
        }
      });
  }

  public async render(container: HTMLElement) {
    this._container = container;
    container.classList.add("splitit-installment-picker-slider");

    const sliderOptions = this.options.map((o) => {
      const widthNum = 100 / this.options.length - 0.01;

      return {
        numPayments: o,
        width: widthNum.toFixed(2),
        widthNumber: widthNum,
      };
    });

    // eslint-disable-next-line @typescript-eslint/no-var-requires

    container.innerHTML = template({ options: sliderOptions });
    const clickableElements = [
      this.slider,
      ...Array.from(
        container.querySelectorAll<HTMLElement>(".splitit-ip-s-line-click-area")
      ),
    ];
    //container.querySelectorAll(".splitit-ip-s-line-click-area").forEach(p => clickableElements.push(p as HTMLElement));
    this._sliderValuesWrapper = container.querySelector(
      ".splitit-ip-s-values"
    ) as HTMLElement;
    clickableElements.push(this._sliderValuesWrapper);

    this.refresh();

    clickableElements
      .filter((el) => !!el)
      .forEach((el) =>
        el!.addEventListener("mousedown", (event) => {
          event.preventDefault();
          this._isMoving = true;
          this._handlePosition =
            event.pageX - this.slider!.getBoundingClientRect().left;
          this.updateHandle();
        })
      );

    window.addEventListener("mousemove", (event) => {
      if (this._isMoving) {
        this._handlePosition =
          event.pageX - this.slider!.getBoundingClientRect().left;
        this._handlePosition = Math.min(
          Math.max(this._handlePosition, 0),
          this.slider!.offsetWidth
        );
        this.updateHandle();
      }
    });

    window.addEventListener("mouseup", (event) => {
      if (this._isMoving) {
        this.setSelectedValue(this._tmpSelectedValue);
      }
      this._isMoving = false;
    });

    if (this.initialSelection) {
      this.setSelectedValue(this.initialSelection);
      const idx = sliderOptions.findIndex(
        (val) => val.numPayments == this.initialSelection
      );
      if (idx > -1) {
        this._handlePosition =
          (((idx + 0.5) * sliderOptions[0].widthNumber) / 100) *
          this.slider!.offsetWidth;
        this.updateHandle();
      }
    }
  }

  public refresh() {
    this.calculateSliderStops();
    this.updateHandle();
    this.setSelectedValue(this._tmpSelectedValue);
  }

  private updateHandle() {
    let idx = -1;
    const relativePosition =
      this._handlePosition == null
        ? 50
        : (this._handlePosition / this.slider!.offsetWidth) * 100;

    this._sliderStops.forEach((stop, i) => {
      if (relativePosition >= stop - this._skipPoint) {
        idx = i;
      }
    });

    const stop = this._sliderStops[idx];
    this.sliderHandle!.style.left = stop + "%";
    this.sliderProgress!.style.width = stop + "%";
    this.setSliderValue(this.options[idx]);
  }

  private calculateSliderStops() {
    this._isMoving = false;
    this._handlePosition = null;
    this._sliderStops = [];

    const fraction = 100 / (this.options.length - 1);

    this._sliderStops = this.options.map((o, idx) => fraction * idx);
    this._skipPoint = fraction / 2;
    this._sliderValuesWrapper.style.marginLeft = `-${fraction / 2}%`;
    this._sliderValuesWrapper.style.marginRight = `-${fraction / 2}%`;
  }

  protected getPickerType(): string {
    return "slider";
  }
}
