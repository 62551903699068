export const template = (props) => {
  const { localizer, installments } = props;
  return `<div class="splitit-payment-schedule">
    <div class="splitit-ps-h">
        <a class="splitit-ps-h-link splitit-ps-h-link-toggle-schedule" href="javascript:void(0);">${
          localizer.paymentSchedule
        }</a>
    </div>
    <div class="splitit-ps-tw">
        <table class="splitit-ps-table">
            <tbody>
                ${installments
                  .map((installment) => {
                    return `<tr>
                    <td class="text-left">
                        <div class="splitit-ps-row-content">
                            <span class="splitit-ps-row-content-header splitit-brand">${installment.paymentOrd}}</span> <br/>
                            ${installment.date}
                        </div>
                    </td>
                    <td></td>
                    <td class="text-center">
                        <div class="splitit-ps-row-content">
                            <span class="splitit-ps-row-content-header">${localizer.charge}</span> <br/>
                            ${installment.amount}
                        </div>
                    </td>
                    <td></td>
                    <td class="text-right">
                        <div class="splitit-ps-row-content splitit-brand-gray-light splitit-ps-row-content-fixed-79">
                            <span class="splitit-ps-row-content-header">${localizer.requiredCredit}</span> <br/>
                            ${installment.requiredCredit}
                        </div>
                    </td>
                </tr>`;
                  })
                  .join("")}
            </tbody>
        </table>
    </div>
</div>`;
};
