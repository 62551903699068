export type Subscribers<TOwner> = { [message: string]: Subscriber<TOwner>[] };

export type Subscriber<TOwner> = {
  owner: TOwner;
  callback: (data: any) => void;
};

export function subscribe<Towner, TDATA>(
  subscribers: Subscribers<Towner>,
  msg: string,
  owner: Towner,
  callback: (data: TDATA) => void
) {
  if (!subscribers[msg]) {
    subscribers[msg] = [];
  }

  subscribers[msg].push({ owner, callback });
}

export function unsubscribe<Towner, TData>(
  subscribers: Subscribers<Towner>,
  msg: string,
  owner: Towner,
  callback: (data: TData) => void
) {
  if (!subscribers[msg]) {
    return;
  }

  subscribers[msg] = subscribers[msg].filter(
    (subscriber) =>
      subscriber.owner !== owner && subscriber.callback !== callback
  );
}

export function publish<Towner, TData>(
  subscribers: Subscribers<Towner>,
  msg: string,
  data?: TData
) {
  const messageSubscribers: Subscriber<Towner>[] = subscribers[msg];
  if (!messageSubscribers) {
    return;
  }
  messageSubscribers.forEach((subscriber) => {
    subscriber.callback.call(subscriber.owner, data);
  });
}
